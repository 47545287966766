import { SerializedError } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

export function handleError(error: Error | SerializedError | unknown) {
    try {
        Sentry.captureException(error);
        console.error(`[desia-web-app] error captured`, error)
    } catch (e) {
        console.error(`[desia-web-app] failed to capture error`, { error, e })
        return
    }
}

export function handleErrorPromise(error: Sentry.ErrorEvent) {
    return new Promise<void>((resolve, reject) => {
        try {
            Sentry.captureException(new Error(error.message), {
                level: error.level || 'error',
                tags: error.tags,
            });
            console.error(`[desia-web-app] sentry error captured`, error)
            return resolve()
        } catch (e) {
            console.error(`[desia-web-app] failed to capture error`, { error, e })
            return reject(e)
        }
    })
}
