import { Button, secondaryStyle } from '../ui/button'
import { Card } from '../ui/card'
import { TypographyLabel } from '../ui/Typography'
import { DocgenSessionStatus } from '@/types/types'
import { Link } from 'react-router-dom'
import Divider from '../ui/divider'
import ReportThumbnailImage from '@/assets/img-report-thumbnail.png'
import { Truncate } from '@re-dev/react-truncate'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { EllipsisVertical, Sparkles } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { deleteSession } from '../DocGen/docGenThunk'
import { removeSession } from '../DocGen/docGenSlice'
import CheckmarkCircleFill from '@/assets/CheckmarkCircleFill'
import { isFulfilled } from '@reduxjs/toolkit'
import CloseCircleFill from '@/assets/CloseCircleFill'
import { Badge } from '../ui/badge'
import { getDocgenSessionUrl } from "@/utils/utils"

const getStatusText = (status: DocgenSessionStatus | null): string => {
  switch (status) {
    case DocgenSessionStatus.THEME_GENERATED:
      return 'Outline ready'
    case DocgenSessionStatus.THEME_ERROR:
      return 'Failed to generate outline'
    case DocgenSessionStatus.CREATING:
      return 'Generating draft...'
    case DocgenSessionStatus.CREATED:
      return 'Draft report'
    case DocgenSessionStatus.DRAFT_ERROR:
      return 'Failed to generate draft'
    default:
      return 'Generating outline...'
  }
}

export const SessionCard = ({ sessionId }: { sessionId: string }) => {
  const dispatch = useDispatch<AppDispatch>()

  const session = useSelector((state: RootState) =>
    state.docGen.sessions.data?.find((r) => r.id === sessionId)
  )

  const template = useSelector((state: RootState) =>
    state.docGen.templates?.find((r) => r.id === session?.template_id)
  )

  const handleDelete = async () => {
    if (!session?.id) return
    const result = await dispatch(deleteSession(session.id))

    if (isFulfilled(deleteSession)(result)) {
      dispatch(removeSession(session.id))
    }
  }

  if (!session) return null

  return (
    <Link
      to={getDocgenSessionUrl(session)}
      className="block no-underline text-inherit"
    >
      <Card
        className={`${secondaryStyle} p-4 shadow-none !rounded-md [&:not(:active)]:border-system-border-light [&:hover:not(:has(.child-hover:hover))]:!bg-system-hover hover:bg-system-secondary cursor-pointer`}
      >
        <div className="flex flex-col gap-6 h-full">
          <div className="flex flex-col gap-3">
            <div className="flex gap-2 items-center">
              <TypographyLabel className="text-system-primary shrink-0">
                {getStatusText(session.status)}
              </TypographyLabel>

              {session?.status === DocgenSessionStatus.CREATED &&
                template?.title && (
                  <Badge className="ml-auto">
                    <div className="flex gap-2 items-center">
                      <Sparkles className="size-4 shrink-0 stroke-[1.5px] stroke-badge-blue-graphic" />
                      <TypographyLabel className="line-clamp-1 break-all">
                        {template?.title || ''}
                      </TypographyLabel>
                    </div>
                  </Badge>
                )}

              {session?.status === DocgenSessionStatus.THEME_GENERATED && (
                <CheckmarkCircleFill className="size-4 shrink-0" />
              )}

              {(session?.status === DocgenSessionStatus.DRAFT_ERROR ||
                session?.status === DocgenSessionStatus.THEME_ERROR) && (
                <CloseCircleFill className="size-4 shrink-0" />
              )}
            </div>

            <Divider className="bg-system-border-light" />

            <div className="flex gap-4 items-center">
              <img className="h-10" src={ReportThumbnailImage} />

              <div className="flex flex-col gap-0.5 w-[calc(100%-100px)]">
                <Truncate className="font-body-strong w-fit">
                  {session.topic || session.title}
                </Truncate>
              </div>

              <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center" asChild>
                  <Button
                    variant="tertiary"
                    className="child-hover"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <EllipsisVertical className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  <DropdownMenuItem
                    variant="tertiary-destructive"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleDelete()
                    }}
                  >
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  )
}
