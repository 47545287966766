import "./UserControlledFeatureFlags.css";
import { useContext } from "react";
import { FeatureFlagContext } from "../contexts/FeatureFlagContext";
import { Checkbox } from "./ui/checkbox";

export function UserControlledFeatureFlags() {
    const { userFlags, toggleFlag } = useContext(FeatureFlagContext);
    return (
        <div id="user-controlled-feature-flags" className="user-controlled-feature-flags-container">
            <ul>
                {userFlags.map(ff => {
                    return (
                        <li key={ff.flag}>
                            <Checkbox
                                id={`checkbox-${ff.flag}`}
                                checked={ff.isEnabled}
                                onCheckedChange={() => {
                                    toggleFlag(ff.flag);
                                }}
                                label={ff.flag}
                            />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
