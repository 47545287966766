import { getIntegrationName } from "@/utils/utils"
import { Dialog, DialogBody, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog"
import { getIntegrationIcon } from "@/utils/components"
import { TypographyBody } from "../ui/Typography"
import { LockKeyhole } from "lucide-react"
import { Button } from "../ui/button"
import { CustomAlert } from "../CustomAlert"
import { useDispatch } from "react-redux"
import { AppDispatch } from "@/store/store"
import { generateAuthorizationURL } from "../Integration/integrationThunk"
import { IntegrationCode, UserIntegration } from "@/types/types"
import { isFulfilled } from "@reduxjs/toolkit"
import { setOpenedWindow } from "../Integration/integrationSlice"
import { LocalStorageKey } from "@/constants"

export const SettingsConnectedAppsDialog = ({ open, setOpen, integration }: { open: boolean, setOpen: (open: boolean) => void, integration: UserIntegration }) => {
    const dispatch = useDispatch<AppDispatch>()

    const handleConnect = async () => {
        const result = await dispatch(generateAuthorizationURL(integration.integration_id))

        if (isFulfilled(generateAuthorizationURL)(result)) {
            const url = new URL(result.payload.uri)
            localStorage.setItem(LocalStorageKey.CONNECTING_INTEGRATION, integration.integration_code_name)
            // timeout prevents new tab from being blocked on safari
            setTimeout(() => {
                const openedWindow = window.open(url, '_blank')
                dispatch(setOpenedWindow(openedWindow))
            })

            setOpen(false)
        }
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="min-w-[560px]">
                <DialogHeader>
                    <DialogTitle>
                        <div className="flex gap-2 items-center">
                            <div className="flex items-center justify-center size-6 shrink-0 rounded-sm border-[1.5px] border-system-border-light">
                                {getIntegrationIcon(integration.integration_code_name, true, 'w-4 shrink-0')}
                            </div>

                            {`Add ${getIntegrationName(integration.integration_code_name)} as a source`}
                        </div>
                    </DialogTitle>
                </DialogHeader>
                <DialogBody>
                    <div className="flex gap-2 items-center bg-system-surface-light p-2 rounded-lg mt-2">
                        <LockKeyhole className="size-5 shrink-0 stroke-[1.5px] stroke-system-body" />

                        <TypographyBody className="text-system-body">
                            Your data stays private and secure while powering smarter, more relevant assistance
                        </TypographyBody>
                    </div>

                    <div className="flex flex-col gap-6 mt-6">
                        <TypographyBody className="text-system-body">
                            {`To enable Desia AI to deliver insights that are up-to-date with your team's latest communications and decisions, we are requesting permission to access ${getIntegrationName(integration.integration_code_name)} data.`}
                        </TypographyBody>

                        <div className="flex flex-col gap-2">
                            <TypographyBody className="text-system-body whitespace-pre-wrap">
                                For this, Desia will ask for your permission to access:
                            </TypographyBody>

                            {integration.integration_code_name === IntegrationCode.OUTLOOK && (
                                <ul className="ml-4">
                                    <li>
                                        <TypographyBody className="text-system-body">
                                            email content
                                        </TypographyBody>
                                    </li>

                                    <li>
                                        <TypographyBody className="text-system-body">
                                            file attachments
                                        </TypographyBody>
                                    </li>
                                </ul>
                            )}

                            {integration.integration_code_name === IntegrationCode.TEAMS && (
                                <ul className="ml-4">
                                    <li>
                                        <TypographyBody className="text-system-body">
                                            channels and messages you have access to
                                        </TypographyBody>
                                    </li>

                                    <li>
                                        <TypographyBody className="text-system-body">
                                            files shared across channels and messages
                                        </TypographyBody>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className="mt-2">
                    <div className="flex flex-col gap-3 w-full">
                        <div className="w-full">
                            <CustomAlert
                                description={`You can always disconnect ${getIntegrationName(integration.integration_code_name)} if you change your mind`}
                            />
                        </div>

                        <div className="flex gap-2 justify-end">
                            <DialogClose asChild>
                                <Button variant='secondary'>
                                    Cancel
                                </Button>
                            </DialogClose>

                            <Button onClick={handleConnect}>
                                Proceed
                            </Button>
                        </div>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}