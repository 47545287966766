import { ArrowLeft, ArrowRight, EllipsisVertical, FileText, PencilLine, X } from "lucide-react"
import { Button } from "../ui/button"
import { TypographyBody, TypographyH4, TypographyLabel } from "../ui/Typography"
import { getFileIcon } from "@/utils/components"
import { useEffect, useState } from "react"
import { AppDispatch, RootState } from "@/store/store"
import { useDispatch, useSelector } from "react-redux"
import { DocGenCreateTemplateDialog } from "./DocGenCreateTemplateDialog"
import DocumentSelectorDialog from "../Assistant/DocumentSelectorDialog"
import Stack from "@/assets/Stack"
import { createTemplateFromDocument, fetchTemplates } from "./docGenThunk"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu"
import { DocgenTemplate } from "@/types/types"
import { DocGenTemplateDeleteDialog } from "./DocGenTemplateDeleteDialog"
import { CustomAlert } from "../CustomAlert"
import { DocGenLoadingTemplateDialog } from "./DocGenLoadingTemplateDialog"
import { isFulfilled } from "@reduxjs/toolkit"

export const DocGenTemplate = ({ template, onPrevClick, onNextClick, onSubmit }: { template: DocgenTemplate | null, onPrevClick: () => void, onNextClick: () => void, onSubmit: (template: DocgenTemplate) => void }) => {
    const documentStore = useSelector((state: RootState) => state.document)
    const docGenStore = useSelector((state: RootState) => state.docGen)

    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [showDocumentSelector, setShowDocumentSelector] = useState(false)
    const [selectedFileId, setSelectedFileId] = useState<string | null>(null)
    const [selectedTemplate, setSelectedTemplate] = useState<DocgenTemplate | null>(template)
    const [selectedTemplateDelete, setSelectedTemplateDelete] = useState<DocgenTemplate | null>(null)
    const [createNewTemplate, setCreateNewTemplate] = useState(false)
    const [createTemplateFromFile, setCreateTemplateFromFile] = useState(false)
    const [loadingTemplate, setLoadingTemplate] = useState(false)
    const [editingTemplate, setEditingTemplate] = useState(false)
    const [showFloatingButton, setShowFloatingButton] = useState(false)
    const [error, setError] = useState('')

    const templates = docGenStore.templates

    const dispatch = useDispatch<AppDispatch>()

    const selectedFile = documentStore.files.find((v) => v.document_id === selectedFileId)

    useEffect(() => {
        dispatch(fetchTemplates())
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const mainContainer = document.querySelector('.main-container-new')

            const scrolledDown = (mainContainer?.scrollHeight || 0) - (mainContainer?.scrollTop || 0) - 96 < (mainContainer?.clientHeight || 0)
            setShowFloatingButton(!scrolledDown)
        }

        handleScroll()

        window.addEventListener('scroll', handleScroll, { capture: true })

        return () => {
            window.removeEventListener('scroll', handleScroll, { capture: true })
        }
    }, [templates])

    const handleNextClick = async () => {
        if (!createTemplateFromFile && !selectedTemplate && !createNewTemplate) {
            setError('You need to select an option to continue.')
        } else {
            if (selectedTemplate) {
                setError('')
                onNextClick()
                onSubmit(selectedTemplate)
            } else if (createTemplateFromFile) {
                if (selectedFileId) {
                    setLoadingTemplate(true)
                    const result = await dispatch(createTemplateFromDocument(selectedFileId))
                    setLoadingTemplate(false)
                    if (isFulfilled(createTemplateFromDocument)(result)) {
                        const template = result.payload as DocgenTemplate
                        setSelectedTemplate(template)
                        setShowCreateDialog(true)
                        onSubmit(template)
                        setError('')
                    } else {
                        setError('We failed to create a template. Please try again')
                    }
                }
            } else {
                setShowCreateDialog(true)
            }
        }
    }

    return (
        <>
            <div className="flex flex-col gap-[54px] max-w-[1272px] mx-auto">
                <div className="flex flex-col gap-1 text-center mx-auto">
                    <TypographyH4>
                        Select template
                    </TypographyH4>

                    <TypographyBody className="text-system-body whitespace-pre-wrap">
                        {'Select the format that best fits your needs to shape\nthe content of your generated report'}
                    </TypographyBody>
                </div>

                <div className="flex flex-col gap-[56px] mx-auto">
                    <div className="flex flex-col gap-6">
                        <TypographyH4>
                            Create template
                        </TypographyH4>

                        <div className="flex gap-4 w-[1272px]">
                            <Button variant='selection' size='selection-l' className={`!w-[50%] justify-start ${createNewTemplate ? '!inner-border-2 !inner-border-system-primary !bg-system-hover !border-transparent' : ''}`}
                                onClick={() => {
                                    if (!createNewTemplate) {
                                        setSelectedFileId(null)
                                        setCreateTemplateFromFile(false)
                                        setSelectedTemplate(null)
                                    }
                                    setCreateNewTemplate(!createNewTemplate)
                                }}
                            >
                                <div className="flex gap-6 items-center">
                                    <PencilLine className="size-6 shrink-0 stroke-[1.5px]" />

                                    <div className="flex flex-col gap-1 text-left">
                                        <TypographyBody isStrong={true}>
                                            Create custom style
                                        </TypographyBody>

                                        <TypographyBody className="text-system-body">
                                            Define your preferred document outline from scratch
                                        </TypographyBody>
                                    </div>
                                </div>
                            </Button>

                            <Button
                                variant='selection'
                                size='selection-l'
                                className={`!w-[50%] cursor-pointer justify-start ${selectedFile ? '!inner-border-2 !inner-border-system-primary !bg-system-hover !border-transparent' : ''}`}
                                asChild
                                onClick={() => setShowDocumentSelector(true)}
                            >
                                <div className="flex gap-6 items-center">
                                    <FileText className="size-6 shrink-0 stroke-[1.5px]" />

                                    <div className={`flex flex-col ${selectedFile ? 'gap-2' : 'gap-1'} text-left w-[calc(100%-48px)]`}>
                                        <TypographyBody isStrong={true} className="flex-grow">
                                            Create from existing document
                                        </TypographyBody>

                                        {selectedFile && (
                                            <div className="flex gap-1 px-3 py-2 border border-system-border-light rounded-sm bg-system-secondary">
                                                <div className="flex gap-2 items-center w-full">
                                                    {getFileIcon(selectedFile.document_type_friendly, '!size-6')}

                                                    <TypographyBody className={`text-system-body overflow-hidden text-ellipsis whitespace-nowrap`}>
                                                        {selectedFile.document_name}
                                                    </TypographyBody>

                                                    <Button
                                                        variant='tertiary'
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setSelectedFileId(null)
                                                            setCreateTemplateFromFile(false)
                                                        }}
                                                        className="ml-auto"
                                                    >
                                                        <X className="size-6 shrink-0 stroke-[1.5px]" />
                                                    </Button>
                                                </div>
                                            </div>
                                        )}

                                        {!selectedFileId && (
                                            <TypographyBody className="text-system-body whitespace-pre-wrap">
                                                {`Your report will follow the outline but not the content of this file`}
                                            </TypographyBody>
                                        )}
                                    </div>

                                    {!selectedFileId && (
                                        <Button variant='secondary'>
                                            <div className="flex gap-2">
                                                <Stack />
                                                Select file
                                            </div>
                                        </Button>
                                    )}
                                </div>
                            </Button>
                        </div>
                    </div>

                    <div className="flex flex-col gap-6">
                        <TypographyH4>
                            Select existing template
                        </TypographyH4>

                        <div className="grid grid-cols-3 gap-4">
                            {templates.map((template) => {
                                return (
                                    <Button
                                        key={`template-card-${template.id}`}
                                        variant='selection'
                                        size='selection-m'
                                        className={`w-full min-h-[100px] max-h-[112px] cursor-pointer ${selectedTemplate?.id === template.id ? '!inner-border-2 !inner-border-system-primary !bg-system-hover !border-transparent' : ''}`}
                                        asChild
                                        onClick={() => {
                                            if (selectedTemplate) {
                                                setSelectedTemplate(null)
                                            } else {
                                                setSelectedFileId(null)
                                                setCreateTemplateFromFile(false)
                                                setCreateNewTemplate(false)
                                                setSelectedTemplate(template)
                                            }
                                        }}
                                    >
                                        <div className="flex gap-2 w-full h-full items-start">
                                            <div className="flex flex-col gap-1 items-start w-full h-full">
                                                <TypographyBody isStrong className="w-full text-left line-clamp-1 break-all text-ellipsis max-w-[320px]">
                                                    {template.title}
                                                </TypographyBody>

                                                <TypographyLabel className="text-system-body w-full text-left whitespace-pre-wrap line-clamp-2">
                                                    {template.description}
                                                </TypographyLabel>
                                            </div>

                                            {template.template_type === 'user' && (
                                                <div className="h-full">
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger className="flex items-center" asChild>
                                                            <Button variant='tertiary' className="h-fit -mr-4">
                                                                <EllipsisVertical className={`size-6 shrink-0 stroke-[1.5px]`} />
                                                            </Button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent align="start" className="w-[193px]">
                                                            <DropdownMenuItem
                                                                variant='tertiary'
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    setSelectedTemplate(template)
                                                                    setShowCreateDialog(true)
                                                                    setEditingTemplate(true)
                                                                }}>
                                                                Edit template
                                                            </DropdownMenuItem>

                                                            <DropdownMenuItem
                                                                variant='tertiary-destructive'
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    setSelectedTemplateDelete(template)
                                                                }}>
                                                                Remove
                                                            </DropdownMenuItem>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </div>
                                            )}
                                        </div>
                                    </Button>
                                )
                            })}
                        </div>
                    </div>
                </div>

                {error && (
                    <div className="w-fit mx-auto">
                        <CustomAlert
                            variant="error"
                            title={error}
                        />
                    </div>
                )}

                <div className="flex gap-2 mx-auto pb-[56px]">
                    <Button variant='secondary' onClick={onPrevClick}>
                        <div className="flex gap-2 items-center">
                            <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />

                            Back
                        </div>
                    </Button>

                    <Button onClick={handleNextClick}>
                        <div className="flex gap-2 items-center">
                            Continue

                            <ArrowRight className="size-6 shrink-0 stroke-[1.5px]" />
                        </div>
                    </Button>
                </div>
            </div>
            <DocGenCreateTemplateDialog
                open={showCreateDialog}
                setOpen={(v) => {
                    setShowCreateDialog(v)
                    if (!v) {
                        setSelectedTemplate(null)
                        setEditingTemplate(false)
                    }
                }}
                selectedTemplate={selectedTemplate}
                templates={templates}
                onSave={(v) => {
                    if (!editingTemplate) {
                        onNextClick()
                        if (selectedTemplate) {
                            onSubmit(selectedTemplate)
                        }
                    }

                    onSubmit(v)
                }}
            />

            <DocumentSelectorDialog
                sourceType={'ask'}
                open={showDocumentSelector}
                setOpen={(v) => {
                    setShowDocumentSelector(v)
                }}
                onSave={(v) => {
                    if (v.length > 0) {
                        if (!createTemplateFromFile) {
                            setCreateNewTemplate(false)
                            setSelectedTemplate(null)
                        }

                        setSelectedFileId(v[0])
                        setCreateTemplateFromFile(true)
                    }
                    setShowDocumentSelector(false)
                }}
                type='docgen'
            />

            {selectedTemplateDelete && (
                <DocGenTemplateDeleteDialog
                    template={selectedTemplateDelete}
                    open={Boolean(selectedTemplateDelete)}
                    setOpen={() => setSelectedTemplateDelete(null)}
                />
            )}

            <DocGenLoadingTemplateDialog
                open={loadingTemplate}
                setOpen={() => { }}
            />

            {showFloatingButton && (
                <div className="fixed flex flex-col gap-4 h-fit w-fit p-4 bg-system-surface rounded-lg border border-system-border-regular shadow-outline-dialog bottom-10 left-[50%] -translate-x-[50%]">
                    {error && (
                        <div className="w-fit mx-auto">
                            <CustomAlert
                                variant="error"
                                title={error}
                            />
                        </div>
                    )}

                    <div className="flex gap-2 mx-auto">
                        <Button variant='secondary' onClick={onPrevClick}>
                            <div className="flex gap-2 items-center">
                                <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />

                                Back
                            </div>
                        </Button>

                        <Button onClick={handleNextClick}>
                            <div className="flex gap-2 items-center">
                                Continue

                                <ArrowRight className="size-6 shrink-0 stroke-[1.5px]" />
                            </div>
                        </Button>
                    </div>
                </div>
            )}
        </>

    )
}