import { Citation, SourceDocument } from '../../types/types'
import { Skeleton } from '@/components/ui/skeleton'
import { embedCharts, embedCitationsV3 } from '../../utils/embedCitations'
import { ResponsiveContainer } from './ResponsiveContainer'
import { memo, useCallback, useRef } from 'react'
import { handleError } from '@/utils/handleError'
import { ResponseMarkdown } from './ResponseMarkdown'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { updateOpenedCitation } from '../DocGen/docGenSlice'
import { CitationFootnote } from '../DocGen/CitationFootnote'

type Props = {
  isLoading: boolean
  text: string
  isComplete: boolean
  documents: SourceDocument[]
  citations: Citation[]
  compact?: boolean
  scrollToCitation?: boolean
  legacyCitation?: boolean
  openedCitation?: Citation | null
  onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
}

interface AnswerBlockProps {
  text: string
  citations: Citation[]
  documents: SourceDocument[]
  isComplete: boolean
  compact?: boolean
  scrollToCitation?: boolean
  openedCitation?: Citation | null
  onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
}

interface CitedTextProps {
  text: string
  citations: Citation[]
  documents: SourceDocument[]
  isComplete: boolean
  compact?: boolean
  scrollToCitation?: boolean
  openedCitation?: Citation | null
  onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
}

export function LoadingBlocks() {
  return (
    <ResponsiveContainer>
      <Skeleton className="w-4/6 h-[20px] my-2" />
      <Skeleton className="w-full h-[20px] my-2" />
      <Skeleton className="w-full h-[20px] my-2" />
      <Skeleton className="w-5/6 h-[20px] my-2" />
    </ResponsiveContainer>
  )
}

const CitedText = memo(
  ({
    text,
    citations,
    documents,
    isComplete,
    compact,
    scrollToCitation,
    openedCitation,
    onCitationOpen,
  }: CitedTextProps) => {
    const documentStore = useSelector((state: RootState) => state.document)

    const embeddedCitations = embedCitationsV3({
      text,
      citations,
      isComplete,
    })
    const embeddedCharts = embedCharts({ text: embeddedCitations })

    const Cite = ({
      children,
      citation,
    }: {
      children: string
      citation: string
    }) => {
      const uuid = useRef(crypto.randomUUID().split('-')[0]).current

      try {
        const ref = useRef<HTMLSpanElement>(null)
        const parsedCitation: Citation = JSON.parse(citation)
        const citationWithHighlights = citations.find(
          (v) =>
            parsedCitation.end === v.end &&
            parsedCitation.start === v.start &&
            parsedCitation.text === v.text
        )

        const isSelected =
          parsedCitation.end === openedCitation?.end &&
          parsedCitation.start === openedCitation.start &&
          parsedCitation.text === openedCitation.text
        const classes = '!font-medium hover:cursor-pointer text-black z-[0]'
        const containerClasses = isSelected
          ? 'bg-[linear-gradient(0deg,_var(--citation-hover),_var(--citation-hover))] bg-no-repeat bg-[length:100%_90%]'
          : 'citation-background'

        const handleClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation()
          if (isSelected) {
            onCitationOpen?.(null, 0)
          } else {
            if (scrollToCitation) {
              ref.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
            if (citationWithHighlights) {
              if (openedCitation) {
                onCitationOpen?.(
                  citationWithHighlights,
                  ref.current?.offsetTop || 0
                )
              } else {
                requestAnimationFrame(() => {
                  onCitationOpen?.(
                    citationWithHighlights,
                    ref.current?.offsetTop || 0
                  )
                })
              }
            }
          }
        }

        return (
          <span className={containerClasses}>
            <span
              ref={ref}
              className={`relative ${compact ? 'font-medium' : 'font-medium'} ${classes}`}
              id={`cited-text-${uuid}`}
              onClick={handleClick}
            >
              {children}
            </span>
          </span>
        )
      } catch (e) {
        handleError(e)
        return children
      }
    }

    return (
      <ResponseMarkdown
        text={embeddedCharts}
        documents={documents}
        citations={citations}
        overrides={{
          Cite: {
            component: Cite,
          },
          Citation: {
            component: ({ id }: { id: number }) => {
              const citation = citations.find(
                  // @ts-expect-error without the parseInt, its treated as string
                (v) => v.citation_uuid === parseInt(id)
              )
              const document =
                documentStore.files.find((v) =>
                  citation?.document_ids.includes(v.document_id)
                ) || null

              if (!citation || !document) return null
              return (
                <CitationFootnote
                  id={id}
                  document={document}
                  selected={
                    openedCitation?.citation_uuid === citation.citation_uuid
                  }
                  onClick={() => {
                    onCitationOpen?.(citation, 0)
                  }}
                />
              )
            },
          },
        }}
        isTable={false}
        openedCitation={openedCitation || null}
      />
    )
  }
)

const AnswerBlock = memo(
  ({
    text,
    citations,
    documents,
    isComplete,
    compact,
    scrollToCitation,
    openedCitation,
    onCitationOpen,
  }: AnswerBlockProps) => {
    return (
      <ResponsiveContainer>
        <div
          className={`text-system-body leading-7 ${compact ? '!font-label' : '!font-body'}`}
        >
          <span className="">
            <CitedText
              text={text}
              citations={citations}
              documents={documents}
              isComplete={isComplete}
              compact={compact}
              openedCitation={openedCitation}
              onCitationOpen={onCitationOpen}
              scrollToCitation={scrollToCitation}
            />
          </span>
        </div>
      </ResponsiveContainer>
    )
  }
)

export const FinalAnswer = memo(
  ({
    isLoading,
    isComplete,
    text,
    citations,
    documents,
    compact,
    scrollToCitation,
    legacyCitation,
    openedCitation,
    onCitationOpen,
  }: Props) => {
    const reportOpenedCitation = useSelector(
      (state: RootState) => state.docGen.openedCitation
    )

    const dispatch = useDispatch<AppDispatch>()

    const onLegacyCitationOpen = useCallback((citation: Citation | null) => {
      dispatch(updateOpenedCitation(citation))
    }, [])

    if (isLoading) {
      return <LoadingBlocks />
    }
    return (
      <>
        <AnswerBlock
          text={text}
          citations={citations}
          documents={documents}
          isComplete={isComplete}
          compact={compact}
          openedCitation={
            legacyCitation ? reportOpenedCitation : openedCitation
          }
          onCitationOpen={
            legacyCitation ? onLegacyCitationOpen : onCitationOpen
          }
          scrollToCitation={scrollToCitation}
        />
      </>
    )
  }
)
