const VideoFile = ({ className }: { className?: string }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <path d="M17.8097 2.3335H8.01098C7.43348 2.3335 6.87963 2.57933 6.47127 3.01691C6.06291 3.4545 5.8335 4.04799 5.8335 4.66683V23.3335C5.8335 23.9523 6.06291 24.5458 6.47127 24.9834C6.87963 25.421 7.43348 25.6668 8.01098 25.6668H21.0759C21.6534 25.6668 22.2073 25.421 22.6156 24.9834C23.024 24.5458 23.2534 23.9523 23.2534 23.3335V8.16683L17.8097 2.3335Z" fill="white" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.2854 2.3335V7.00016C16.2854 7.619 16.5301 8.21249 16.9657 8.65008C17.4013 9.08766 17.992 9.3335 18.6081 9.3335H23.2534" fill="white" />
            <path d="M16.2854 2.3335V7.00016C16.2854 7.619 16.5301 8.21249 16.9657 8.65008C17.4013 9.08766 17.992 9.3335 18.6081 9.3335H23.2534" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 12.3532C11 11.7641 11.648 11.405 12.1475 11.7172L17.9824 15.364C18.4524 15.6578 18.4524 16.3422 17.9824 16.636L12.1475 20.2828C11.648 20.595 11 20.2359 11 19.6468V12.3532Z" fill="#5512FF" />
        </svg>
    )
}

export default VideoFile