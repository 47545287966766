import { ReactElement, useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { ErrorMessage } from "./ErrorMessage";

interface Props {
    children: ReactElement
}
export function IsAuthenticated({ children }: Props) {
    const { status } = useContext(UserContext);

    const error = status === 'error';
    const loading = status === 'loading';
    
    if (error) {
        return <ErrorMessage message="We failed to fetch user profile" />
    }

    if (loading) {
        return <></>
    }

    return children
}
