import Microsoft365Icon from "@/assets/Microsoft365Icon"
import { getIntegrationBadge, getIntegrationIcon } from "@/utils/components"
import { TypographyBody } from "../ui/Typography"
import { secondaryStyle } from "../ui/button"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { IntegrationCode } from "@/types/types"
import { fetchAdminConsent } from "./integrationThunk"
import { isFulfilled } from "@reduxjs/toolkit"

export const MicrosftIntegrationCard = () => {
    const integrations = useSelector((state: RootState) => state.integration.integrations)
    const integrationStatus = useSelector((state: RootState) => state.integration.integrationStatus)

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const microsoftIntegrations = integrations.data?.filter((v) => v.integration_code_name.includes('microsoft')) || []

    const isConnected = !microsoftIntegrations.some((v) => (!v.integration_has_setup || !v.integration_is_process) && (v.integration_code_name === IntegrationCode.ONEDRIVE || v.integration_code_name === IntegrationCode.SHAREPOINT))
    const isProcessing = Object.values(integrationStatus).find((v) => {
        const detail = v.data
        const processingCount = (detail?.synchronization_details.available_count || 0) + (detail?.synchronization_details.failed_count || 0)
        const integration = microsoftIntegrations.find((i) => i.integration_id === v.data?.integration_details.integration_id)
        const processing = integration?.integration_has_setup && detail?.synchronization_details.files_to_synchronize_count && processingCount !== detail?.synchronization_details.files_to_synchronize_count
        return integration && processing
    })

    const handleRedirect = async () => {
        if (isConnected) {
            navigate('/integrations/microsoft')
        } else {
            const result = await dispatch(fetchAdminConsent())
            if (isFulfilled(fetchAdminConsent)(result)) {
                window.open(result.payload.url, '_blank')
            }
        }
    }

    return (
        <div className={`${secondaryStyle} flex flex-col gap-2 p-3 size-[220px] border border-system-border-regular rounded-[16px] bg-system-secondary cursor-pointer`} onClick={handleRedirect}>
            {getIntegrationBadge(isProcessing ? 'updating' : isConnected ? 'connected' : 'off')}

            <div className="flex flex-col gap-0 mx-auto">
                <div className="flex gap-6 pt-6 pb-6">
                    <div className="size-[50px]">
                        <Microsoft365Icon />
                    </div>

                    <div className="grid gap-1 grid-cols-2">
                        <div className="flex items-center justify-center size-8 bg-system-hover rounded-lg">
                            {getIntegrationIcon(IntegrationCode.OUTLOOK, true, 'w-5')}
                        </div>

                        <div className="flex items-center justify-center size-8 bg-system-hover rounded-lg">
                            {getIntegrationIcon(IntegrationCode.ONEDRIVE, true, 'w-5')}
                        </div>

                        <div className="flex items-center justify-center size-8 bg-system-hover rounded-lg">
                            {getIntegrationIcon(IntegrationCode.SHAREPOINT, true, 'w-5')}
                        </div>

                        <div className="flex items-center justify-center size-8 bg-system-hover rounded-lg">
                            {getIntegrationIcon(IntegrationCode.TEAMS, true, 'w-5')}
                        </div>
                    </div>
                </div>
                <TypographyBody isStrong className="text-center">
                    Microsoft 365
                </TypographyBody>
            </div>
        </div>
    )
}