import { ASYNC_STATUS, Integration, IntegrationStatus } from "@/types/types"
import { TypographyBody, TypographyH3 } from "../ui/Typography"
import { AppDispatch, RootState } from "@/store/store"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useContext, useEffect } from "react"
import { fetchIntegrations, fetchIntegrationStatus } from "./integrationSlice"
import { checkIntegrationFlag } from "@/utils/utils"
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext"
import { UserContext } from "@/contexts/UserContext"
import { Plus } from "lucide-react"
import { MicrosftIntegrationCard } from "./MicrosoftIntegrationCard"
import { isFulfilled } from "@reduxjs/toolkit"

export interface IntergrationPageProps {
    integrations: Integration[]
    integrationStatus: {
        [id: number]: {
            data: IntegrationStatus | null,
            status: 'idle' | 'loading' | 'error' | 'success'
        }
    }
}

export const IntegrationPage = () => {
    return (
        <div className="flex flex-col gap-12 max-w-[600px] mx-auto min-h-[calc(100vh-60px-120px)]">
            <div className="text-center">
                <TypographyH3>
                    Integrations
                </TypographyH3>
            </div>

            <div className="flex">
                <div className="grid grid-cols-2 gap-6 mx-auto">
                    <MicrosftIntegrationCard />

                    <div
                        style={{
                            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23C8D3DFFF' stroke-width='3' stroke-dasharray='4%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                        }}
                        className="flex flex-col gap-2 p-3 items-center justify-center size-[220px] rounded-[16px] bg-system-surface-light"
                    >
                        <Plus className="size-6 shrink-0 stroke-[1.5px] stroke-system-body" />
                        <TypographyBody isStrong className="text-system-body">
                            More coming soon...
                        </TypographyBody>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const IntegrationPageContainer = () => {
    const integrations = useSelector((state: RootState) => state.integration.integrations)

    const ff = useContext(FeatureFlagContext)
    const { user } = useContext(UserContext)

    const integrationFlag = checkIntegrationFlag(ff, user)

    const dispatch = useDispatch<AppDispatch>();

    const fetchStatus = useCallback((integrations: Integration[]) => {
        integrations.forEach((integration) => {
            dispatch(fetchIntegrationStatus(integration.integration_id))
        })
    }, [dispatch])

    const refreshIntegrations = useCallback(async () => {
        const result = await dispatch(fetchIntegrations())
        if (isFulfilled(fetchIntegrations)(result)) {
            fetchStatus(result.payload)
        }

    }, [dispatch, fetchStatus])

    useEffect(() => {
        if (integrations.status === ASYNC_STATUS.idle) {
            refreshIntegrations()
        } else {
            fetchStatus(integrations.data || [])
        }
    }, [fetchStatus, integrations.data, refreshIntegrations, integrations.status])

    useEffect(() => {
        const intervalId = setInterval(() => fetchStatus(integrations.data || []), 10000)

        return () => clearInterval(intervalId)
    }, [integrations.data, fetchStatus])

    useEffect(() => {
        document.title = 'Desia AI - Integrations'
    }, [])

    if (!integrationFlag) return null

    return (
        <IntegrationPage />
    )
}