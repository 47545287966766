import {
  CircleX,
  LockKeyhole,
  MessageCircleMore,
  NotebookPen,
  SearchIcon,
  Sparkles,
  UsersRound,
} from 'lucide-react'
import { Dialog, DialogBody, DialogContent, DialogHeader } from '../ui/dialog'
import ArrowRight from '@/assets/ArrowRight'
import { lazy, Suspense, useEffect, useMemo, useState } from 'react'
import texture from '../../assets/bg-texture.png'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { Table, TableBody } from '../ui/table'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import {
  getDossierIcon,
  getFileIcon,
  getIntegrationIcon,
} from '@/utils/components'
import { compareDesc, formatDate } from 'date-fns'
import { ASYNC_STATUS, QueryStatus, ResponseDocument } from '@/types/types'
import { fetchDossiers } from '../Dossier/dossierSlice'
import { requestReports } from '../DocGen/docGenSlice'
import {
  getCreatedAt,
  getDirectoryFromDocument,
  getDocgenSessionUrl,
  getFileLinkV2,
  getTimestamp,
  getUpdatedAt,
  matchSearchQuery,
} from '@/utils/utils'
import { useNavigate } from 'react-router-dom'
import DesiaMonochrome from '@/assets/DesiaMonochrome'
import {
  getAllFileChildren,
  getFileChildrenLatestDate,
  transformResponseDocuments,
} from '@/utils/transformResponseDocuments'
import { listDocuments } from '../Document/documentThunk'
import { Button, secondaryStyle } from '../ui/button'
import { fetchSessions, fetchTemplates } from '../DocGen/docGenThunk'
import { Badge } from '../ui/badge'
import { DialogTitle } from '@radix-ui/react-dialog'

const SpotlightTableRow = lazy(() => import('./SpotlightTableRow'))

interface ReportSession {
  id: string
  title: string
  templateId?: string
  dossierId?: string
  updated_at?: string
  type: 'report' | 'session'
}

export const SpotlightSearchDialog = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const dossiers = useSelector((state: RootState) => state.dossier.dossiers)
  const conversations = useSelector((state: RootState) => state.assistant.list)
  const reports = useSelector((state: RootState) => state.docGen.reports)
  const documents = useSelector((state: RootState) => state.document.files)
  const documentStatus = useSelector(
    (state: RootState) => state.document.fetchStatus
  )
  const sessions = useSelector((state: RootState) => state.docGen.sessions)
  const templates = useSelector((state: RootState) => state.docGen.templates)
  const templateStatus = useSelector(
    (state: RootState) => state.docGen.templateListStatus
  )

  const [searchQuery, setSearchQuery] = useState('')

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const elements = useMemo(
    () => transformResponseDocuments(documents),
    [documents]
  )
  const mappedReports = useMemo(
    () =>
      reports.data?.map((v) => {
        return {
          id: v.id,
          title: v.title,
          dossierId: v.dossier_id,
          updated_at: v.updated_at,
          type: 'report',
        } as ReportSession
      }) || [],
    [reports.data]
  )

  const mappedSessions = useMemo(
    () =>
      sessions.data?.map((v) => {
        return {
          id: v.id,
          title: v.topic,
          templateId: v.template_id,
          type: 'session',
        } as ReportSession
      }) || [],
    [sessions.data]
  )

  const sortedReportSessions = useMemo(
    () =>
      [...mappedReports, ...mappedSessions].sort((a, b) => {
        const aDate = a.updated_at
        const bDate = b.updated_at

        if (aDate && bDate) {
          return compareDesc(aDate, bDate)
        }

        if (aDate) {
          return 1
        }

        return -1
      }),
    [mappedReports, mappedSessions]
  )

  const filteredDossiers = useMemo(
    () =>
      dossiers.data?.filter((dossier) => {
        return matchSearchQuery(dossier.subject, searchQuery) || !searchQuery
      }) || [],
    [searchQuery, dossiers.data]
  )

  const filteredConversations = useMemo(
    () =>
      conversations.filter((conversation) => {
        return matchSearchQuery(conversation.query, searchQuery) || !searchQuery
      }) || [],
    [searchQuery, conversations]
  )

  const filteredReports = useMemo(
    () =>
      sortedReportSessions.filter((report) => {
        return matchSearchQuery(report.title, searchQuery) || !searchQuery
      }) || [],
    [searchQuery, sortedReportSessions]
  )

  const filteredElements = useMemo(
    () =>
      elements.filter((element) => {
        return (
          matchSearchQuery(element.element_name, searchQuery) || !searchQuery
        )
      }) || [],
    [searchQuery, elements]
  )

  const sortedDossiers = useMemo(
    () =>
      [...filteredDossiers].sort((a, b) => {
        const aDate = a.updated_at
        const bDate = b.updated_at

        return compareDesc(aDate, bDate)
      }),
    [filteredDossiers]
  )

  const shownDossiers = sortedDossiers.slice(0, 2)
  const shownConversations = filteredConversations.slice(0, 2)
  const shownReports = filteredReports.slice(0, 2)
  const shownFiles = filteredElements

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    navigate(`/search?q=${searchQuery}`)
    resetSearch()
    setOpen(false)
  }

  const resetSearch = () => {
    setSearchQuery('')
  }

  useEffect(() => {
    if (dossiers.status === ASYNC_STATUS.idle) {
      dispatch(fetchDossiers())
    }
  }, [dispatch, dossiers.status])

  useEffect(() => {
    if (reports.data === null) {
      dispatch(
        requestReports({
          requestId: 'docgen:list_reports_20_0',
          params: {
            limit: 20,
            offset: 0,
          },
          timestamp: getTimestamp(),
        })
      )
    }
  }, [dispatch, reports.data])

  useEffect(() => {
    if (documentStatus === QueryStatus.INITIALISED) {
      dispatch(listDocuments())
    }
  }, [dispatch, documentStatus])

  useEffect(() => {
    if (sessions.status === QueryStatus.INITIALISED) {
      dispatch(fetchSessions())
    }
  }, [dispatch, sessions.status])

  useEffect(() => {
    if (templateStatus === QueryStatus.INITIALISED) {
      dispatch(fetchTemplates())
    }
  }, [dispatch, templateStatus])

  useEffect(() => {
    if (!open) {
      resetSearch()
    }
  }, [open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="p-0 m-0 rounded-[16px] !shadow-none !max-w-[calc(100vw-32px)] tablet:!max-w-[calc(100vw-64px)] laptop:!max-w-[calc(100vw-240px)] bg-system-surface border border-system-border-regular"
        style={{ backgroundImage: `url(${texture})` }}
        dynamicHeight
      >
        <DialogHeader>
          <DialogTitle><span className='hidden'></span></DialogTitle>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 py-4 mx-4 border-b border-system-border-light">
              <SearchIcon className="size-6 shrink-0 stroke-[1.5px]" />
              <input
                placeholder="Search across platform..."
                className="font-body placeholder:text-system-placeholder bg-transparent w-full focus:outline-none"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              {searchQuery && (
                <Button
                  variant="tertiary"
                  className="ml-auto"
                  onClick={resetSearch}
                >
                  <CircleX className="size-6 shrink-0 stroke-[1.5px] fill-system-placeholder !stroke-system-secondary" />
                </Button>
              )}
            </div>

            {searchQuery && (
              <div
                className={`flex gap-2 p-3 mx-4 border border-system-border-regular rounded-[12px] bg-system-surface ${secondaryStyle} cursor-pointer`}
                onClick={handleSearch}
              >
                <div className="flex gap-2 px-3 bg-system-hover rounded-lg w-full">
                  <TypographyBody className="text-system-body">
                    Advanced data search - Press{' '}
                    <span className="text-system-primary font-body-strong">
                      ↵ Enter
                    </span>
                  </TypographyBody>
                </div>

                <Button variant="tertiary" className="ml-auto">
                  <ArrowRight />
                </Button>
              </div>
            )}
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="relative flex flex-col gap-6 py-2 px-4 min-w-[700px] overflow-x-auto">
            {!searchQuery && (
              <div className="px-3 bg-system-hover rounded-lg">
                <TypographyBody className="text-system-body">
                  Recent activity
                </TypographyBody>
              </div>
            )}

            {shownDossiers.length > 0 && (
              <div className="flex flex-col gap-2">
                <TypographyBody isStrong>Dossiers</TypographyBody>

                <Table className="min-w-[668px]" scrollable={false}>
                  <TableBody>
                    {shownDossiers?.map((dossier, index) => {
                      return (
                        <Suspense key={`spotlight-search-dossier-${index}`}>
                          <SpotlightTableRow
                            title={dossier.subject}
                            query={searchQuery}
                            date={dossier.updated_at}
                            firstCellLeftIcon={
                              <div className="flex items-center justify-center size-[28px] shrink-0 rounded-sm border border-system-border-regular bg-system-surface-light">
                                {getDossierIcon(
                                  dossier.icon,
                                  'min-h-4 min-w-4 max-w-4 max-h-4'
                                )}
                              </div>
                            }
                            secondCell={<></>}
                            onClick={() => {
                              setOpen(false)
                              navigate(`/dossier/${dossier.id}`)
                            }}
                          />
                        </Suspense>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )}

            {shownConversations.length > 0 && (
              <div className="flex flex-col gap-2">
                <TypographyBody isStrong>Chats</TypographyBody>

                <Table className="min-w-[668px]" scrollable={false}>
                  <TableBody>
                    {shownConversations?.map((conversation, index) => {
                      const dossier = dossiers.data?.find(
                        (v) => v.id === conversation.dossierId
                      )

                      return (
                        <Suspense
                          key={`spotlight-search-conversation-${index}`}
                        >
                          <SpotlightTableRow
                            title={conversation.query}
                            query={searchQuery}
                            date={conversation.updated_at}
                            firstCellLeftIcon={
                              <MessageCircleMore className="size-6 shrink-0 stroke-[1.5px]" />
                            }
                            secondCell={
                              <>
                                {dossier && (
                                  <div
                                    className="flex gap-2 items-center"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setOpen(false)
                                      navigate(`/dossier/${dossier.id}`)
                                    }}
                                  >
                                    <div className="size-4 min-w-4 min-h-4 flex items-center justify-center rounded-[2px] border-[0.3px] border-system-border-light bg-system-surface-light w-fit h-fit shrink-0">
                                      {getDossierIcon(dossier.icon, '!size-2')}
                                    </div>

                                    <TypographyBody className="text-system-body hover:underline cursor-pointer decoration-system-body overflow-hidden whitespace-nowrap text-ellipsis">
                                      {dossier.subject}
                                    </TypographyBody>
                                  </div>
                                )}
                              </>
                            }
                            onClick={() => {
                              setOpen(false)
                              navigate(
                                `/assistant/conversation/${conversation.conversationId}`
                              )
                            }}
                          />
                        </Suspense>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )}

            {shownReports.length > 0 && (
              <div className="flex flex-col gap-2">
                <TypographyBody isStrong>Reports</TypographyBody>

                <Table className="min-w-[668px]" scrollable={false}>
                  <TableBody>
                    {shownReports?.map((report, index) => {
                      const dossier = dossiers.data?.find(
                        (v) => v.id === report.dossierId
                      )
                      const template = templates.find(
                        (v) => v.id === report.templateId
                      )

                      return (
                        <Suspense key={`spotlight-search-report-${index}`}>
                          <SpotlightTableRow
                            title={report.title || 'Untitled'}
                            query={searchQuery}
                            date={report.updated_at || ''}
                            firstCellLeftIcon={
                              <NotebookPen className="size-6 shrink-0 stroke-[1.5px]" />
                            }
                            firstCellRightIcon={
                              template?.title && (
                                <Badge>
                                  <div className="flex gap-2 items-center">
                                    <Sparkles className="size-4 shrink-0 stroke-[1.5px] stroke-badge-blue-graphic" />
                                    <TypographyLabel className="line-clamp-1 break-all">
                                      {template?.title || ''}
                                    </TypographyLabel>
                                  </div>
                                </Badge>
                              )
                            }
                            secondCell={
                              <>
                                {dossier && (
                                  <div
                                    className="flex gap-2 items-center"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setOpen(false)
                                      navigate(`/dossier/${dossier.id}`)
                                    }}
                                  >
                                    <div className="size-4 min-w-4 min-h-4 flex items-center justify-center rounded-[2px] border-[0.3px] border-system-border-light bg-system-surface-light w-fit h-fit shrink-0">
                                      {getDossierIcon(dossier.icon, '!size-2')}
                                    </div>

                                    <TypographyBody className="text-system-body hover:underline cursor-pointer decoration-system-body overflow-hidden whitespace-nowrap text-ellipsis">
                                      {dossier.subject}
                                    </TypographyBody>
                                  </div>
                                )}
                              </>
                            }
                            onClick={() => {
                              setOpen(false)
                              if (report.type === 'report') {
                                navigate(`/report/${report.id}`)
                              } else {
                                const session = sessions.data.find(
                                  (v) => v.id === report.id
                                )

                                if (session) {
                                  navigate(getDocgenSessionUrl(session))
                                }
                              }
                            }}
                          />
                        </Suspense>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )}

            {shownFiles.length > 0 && (
              <div className="flex flex-col gap-2">
                <TypographyBody isStrong>Files</TypographyBody>

                <Table className="min-w-[668px]" scrollable={false}>
                  <TableBody>
                    {shownFiles?.map((element, index) => {
                      const document = documents.find(
                        (v) => v.document_id === element.document_id
                      )
                      const allChildren = [
                        ...new Set(
                          getAllFileChildren(
                            elements,
                            element.internal_element_id
                          )
                        ),
                      ]
                      const allChildrenDocuments = allChildren
                        .map((c) =>
                          documents?.find(
                            (v) => v.document_id === c.document_id
                          )
                        )
                        .filter((v): v is ResponseDocument => Boolean(v))

                      // use document for files, first children doc for folders
                      const elementResource =
                        document || allChildrenDocuments[0]

                      const directory =
                        elementResource &&
                        getDirectoryFromDocument(elementResource, 'Shared')
                      const lastUpdatedDate =
                        getFileChildrenLatestDate(allChildrenDocuments)
                      const documentDate =
                        getUpdatedAt(elementResource) ||
                        getCreatedAt(elementResource)

                      const formattedDocumentDate =
                        documentDate && formatDate(documentDate, 'd MMM yyyy')
                      const formattedLastUpdatedDate =
                        lastUpdatedDate &&
                        formatDate(lastUpdatedDate, 'd MMM yyyy')

                      return (
                        <Suspense key={`spotlight-search-file-${index}`}>
                          <SpotlightTableRow
                            title={element.element_name}
                            query={searchQuery}
                            date={
                              formattedLastUpdatedDate ||
                              formattedDocumentDate ||
                              ''
                            }
                            firstCellLeftIcon={getFileIcon(
                              document?.document_type_friendly ||
                                element.element_type,
                              'size-6 min-h-6 min-w-6 shrink-0'
                            )}
                            secondCell={
                              <>
                                <div className="flex gap-2 items-center">
                                  {directory === 'Private' && (
                                    <LockKeyhole
                                      className={`size-4 shrink-0 stroke-[1.5px] stroke-system-body`}
                                    />
                                  )}

                                  {directory === 'Shared' && (
                                    <UsersRound
                                      className={`size-4 shrink-0 stroke-[1.5px] stroke-system-body`}
                                    />
                                  )}

                                  {directory === 'Desia library' && (
                                    <div
                                      className={`size-4 flex items-center justify-center rounded-[2px] border border-system-border-light shrink-0`}
                                    >
                                      <DesiaMonochrome className="size-[10px]" />
                                    </div>
                                  )}

                                  {![
                                    'Private',
                                    'Shared',
                                    'Desia library',
                                  ].includes(directory) && (
                                    <div
                                      className={`size-4 flex items-center justify-center rounded-[2px] border border-system-border-light shrink-0`}
                                    >
                                      {getIntegrationIcon(
                                        elementResource?.document_source_details
                                          ?.integration_code_name || '',
                                        true,
                                        'size-[10px] shrink-0'
                                      )}
                                    </div>
                                  )}

                                  {directory}
                                </div>
                              </>
                            }
                            onClick={() => {
                              if (element.element_type !== 'file') {
                                const openedFolders =
                                  localStorage.getItem('opened_folders') || []
                                const updatedOpenedFolders = new Set([
                                  ...openedFolders,
                                  ...element.parent_element_internal_ids,
                                  element.internal_element_id,
                                ])
                                localStorage.setItem(
                                  'opened_folders',
                                  JSON.stringify(
                                    Array.from(updatedOpenedFolders)
                                  )
                                )

                                navigate(
                                  `/library?open=${element.internal_element_id}`
                                )
                                setOpen(false)
                              }

                              if (document) {
                                const link = getFileLinkV2(document)
                                if (!link) {
                                  alert(
                                    'We failed to get the file link, please try again shortly'
                                  )
                                }
                                window.open(link, '_blank')
                              }
                            }}
                          />
                        </Suspense>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}
