const MessageCircleQuestion = ({ className }: { className?: string }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        className={className}
    >
        <path
            stroke="current"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M7.9 20A9 9 0 1 0 4 16.1L2 22l5.9-2Z"
        />
        <path
            stroke="current"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3M12 17h.01"
        />
    </svg>
)
export default MessageCircleQuestion
