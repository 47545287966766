import { ArrowRight, Loader2, Sparkles } from 'lucide-react'
import { Button } from '../ui/button'
import { TypographyBody, TypographyH4 } from '../ui/Typography'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Badge } from '../ui/badge'
import { DocGenThemeCard } from './DocGenThemeCard'
import {
  DocgenSession,
  DocgenThemeStatus,
  DocgenTemplate,
  DocgenTheme,
  QueryStatus,
  DocgenSessionStatus,
} from '@/types/types'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import {
  createDraft,
  fetchSession,
  fetchTemplate,
  fetchTheme,
  updateTheme,
} from './docGenThunk'
import { CustomAlert } from '../CustomAlert'
import { isFulfilled } from '@reduxjs/toolkit'
import { findThemePosition } from './utils'

export const DocGenOutline = ({
  session,
  template,
  topic,
  onNextClick,
}: {
  session: DocgenSession | null
  template: DocgenTemplate | null
  topic: string | null
  onNextClick: () => void
}) => {
  const docGenStore = useSelector((state: RootState) => state.docGen)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [theme, setTheme] = useState<DocgenTheme | null>(null)
  const [themeId, setThemeId] = useState<string | null>(null)
  const [currentTemplate, setCurrentTemplate] = useState<DocgenTemplate | null>(
    docGenStore.templates.find((v) => v.id === session?.template_id) || null
  )

  const dispatch = useDispatch<AppDispatch>()
  const interval = useRef<NodeJS.Timeout | null>(null)

  const sortedSections = useMemo(() => {
    return theme?.sections.sort((a, b) => {
      const aLevel: number[] =
        findThemePosition(currentTemplate?.sections || [], a.title, 0)
          ?.indexPath || []
      const bLevel: number[] =
        findThemePosition(currentTemplate?.sections || [], b.title, 0)
          ?.indexPath || []

      for (let i = 0; i < Math.max(aLevel.length, bLevel.length); i++) {
        const numA = aLevel[i] || 0
        const numB = bLevel[i] || 0

        if (numA !== numB) {
          return numA - numB
        }
      }

      return 0
    })
  }, [theme?.sections, currentTemplate?.sections])

  const fetchCurrentSession = useCallback(async () => {
    if (!session?.id) return
    const result = await dispatch(fetchSession(session.id))

    if (isFulfilled(fetchSession)(result)) {
      const payload = result.payload as DocgenSession
      if (payload.theme) {
        setThemeId(payload.theme.id || null)
        setError(false)
      } else {
        if (docGenStore.createThemeStatus !== QueryStatus.FETCHING) {
          setError(true)
        }
      }
    }
  }, [session?.id, docGenStore.createThemeStatus, dispatch])

  useEffect(() => {
    fetchCurrentSession()
  }, [session, docGenStore.createThemeStatus, fetchCurrentSession])

  const refetchTheme = async () => {
    if (!themeId) return
    const result = await dispatch(fetchTheme(themeId))

    if (isFulfilled(fetchTheme)(result)) {
      const payload = result.payload as DocgenTheme
      setTheme(payload)
      if (
        payload?.status === DocgenThemeStatus.CREATED ||
        payload.status === DocgenThemeStatus.ERROR
      ) {
        setLoading(false)

        if (payload.status === DocgenThemeStatus.ERROR) {
          setError(true)
        }

        if (interval.current) {
          clearInterval(interval.current)
        }

        if (
          theme?.status === DocgenThemeStatus.CREATED &&
          session?.status === DocgenSessionStatus.CREATING
        ) {
          onNextClick()
        }
      }
    }
  }

  const handleNextClick = useCallback(async () => {
    if (!theme || !session) return
    try {
      await dispatch(updateTheme(theme)).unwrap()
      await dispatch(createDraft(session.id)).unwrap()
      onNextClick()
    } catch (error) {
      console.error('Error handling next click:', error)
      setError(true)
    }
  }, [theme, session, dispatch, onNextClick])

  const fetchCurrentTemplate = async (id: string) => {
    const result = await dispatch(fetchTemplate(id))

    if (isFulfilled(fetchTemplate)(result)) {
      setCurrentTemplate(result.payload)
    }
  }

  useEffect(() => {
    refetchTheme()

    interval.current = setInterval(() => refetchTheme(), 5000)

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [themeId, session])

  useEffect(() => {
    if (session?.template_id) {
      fetchCurrentTemplate(session.template_id)
    }
  }, [session])

  return (
    <>
      <div
        className={`flex flex-col ${loading ? 'gap-[54px]' : 'gap-6'} w-[1000px] mx-auto`}
      >
        {loading ? (
          <div className="flex flex-col gap-1 text-center mx-auto">
            <TypographyH4>Generating outline...</TypographyH4>

            <TypographyBody className="text-system-body whitespace-pre-wrap">
              {
                'The outline is based on the template and has been\ncustomized to match the content in selected sources'
              }
            </TypographyBody>
          </div>
        ) : (
          <div className="flex flex-col gap-1 text-center mx-auto">
            <TypographyH4>Review outline</TypographyH4>

            <TypographyBody className="text-system-body whitespace-pre-wrap">
              {
                'The outline is based on the template and has been\ncustomized to match the content in selected sources'
              }
            </TypographyBody>
          </div>
        )}

        <div className="flex flex-col gap-8">
          {!loading && !error && (
            <>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <TypographyH4>{session?.topic || topic || ''}</TypographyH4>

                  {(currentTemplate || template) && (
                    <Badge>
                      <div className="flex gap-2 items-center">
                        <Sparkles className="size-4 shrink-0 stroke-[1.5px] stroke-badge-blue-graphic" />
                        {currentTemplate?.title || template?.title || ''}
                      </div>
                    </Badge>
                  )}
                </div>

                <TypographyBody className="text-system-body">
                  Sections may have been adjusted to reflect relevance to the
                  topic.
                </TypographyBody>
              </div>

              <div className="flex flex-col gap-6">
                {sortedSections?.map((section, index) => {
                  return (
                    <DocGenThemeCard
                      key={`docgen-theme-card-${index}`}
                      section={section}
                      template={currentTemplate}
                      onSectionUpdate={(v) => {
                        if (!theme) return
                        const copy = [...theme.sections]
                        copy[index] = v

                        setTheme({
                          ...theme,
                          sections: copy,
                        })
                      }}
                    />
                  )
                })}
              </div>
            </>
          )}

          {loading && !error && (
            <div className="flex gap-4 mx-auto">
              <Loader2 className="size-6 shrink-0 stroke-[1.5px] animate-spin" />

              <div className="flex flex-col gap-1">
                <div className="flex gap-2 items-center">
                  <TypographyBody isStrong>
                    {`Loading content outline of ${session?.topic || topic || 'topic'}`}
                  </TypographyBody>

                  {(currentTemplate || template) && (
                    <Badge>
                      <div className="flex gap-2 items-center">
                        <Sparkles className="size-4 shrink-0 stroke-[1.5px] stroke-badge-blue-graphic" />
                        {currentTemplate?.title || template?.title || ''}
                      </div>
                    </Badge>
                  )}
                </div>

                <TypographyBody className="text-system-body">
                  This will take 1-2 minutes
                </TypographyBody>
              </div>
            </div>
          )}

          {error && (
            <div className="w-fit mx-auto">
              <CustomAlert
                variant="error"
                description="We were unable to generate an outline"
              />
            </div>
          )}
        </div>

        {!loading && (
          <div className="flex gap-2 mx-auto pb-[56px]">
            <Button onClick={handleNextClick}>
              <div className="flex gap-2 items-center">
                Continue
                <ArrowRight className="size-6 shrink-0 stroke-[1.5px]" />
              </div>
            </Button>
          </div>
        )}
      </div>
    </>
  )
}
