import { Integration } from "@/types/types";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { fetchIntegrations } from "./integrationSlice";
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext";
import { UserContext } from "@/contexts/UserContext";
import { checkIntegrationFlag } from "@/utils/utils";
import { IntegrationGreenlisting } from "./IntegrationGreenlisting";

export interface IntegrationSetupProps {
    integration: Integration
}

export const IntegrationSetupPage = ({ integration }: IntegrationSetupProps) => {
    return (
        <div className="flex flex-col gap-2 mx-[88px]">
            <IntegrationGreenlisting integration={integration} />
        </div>
    )
}

export const IntegrationSetupPageContainer = () => {
    const integrations = useSelector((state: RootState) => state.integration.integrations)

    const ff = useContext(FeatureFlagContext)
    const { user } = useContext(UserContext)

    const integrationFlag = checkIntegrationFlag(ff, user)

    const dispatch = useDispatch<AppDispatch>()
    const { integration_code_name } = useParams();

    useEffect(() => {
        if (!integrations.data) {
            dispatch(fetchIntegrations())
        }
    }, [])

    useEffect(() => {
        document.title = 'Desia AI - Integration Setup'
    }, [])

    if (!integration_code_name) return null

    const selectedIntegration = integrations.data?.find((v) => v.integration_code_name === integration_code_name)

    if (!selectedIntegration) return null

    if (!integrationFlag) return null

    return (
        <IntegrationSetupPage integration={selectedIntegration} />
    )
}