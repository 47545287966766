import { Link, useNavigate } from 'react-router-dom'
import { TypographyBody } from '../ui/Typography'
import LeftToLine from '@/assets/LeftToLine'
import { useContext, useState } from 'react'
import { LayoutContext } from '@/contexts/LayoutContext'
import logo from '../../assets/logo.svg'
import Divider from '../ui/divider'
import { UserMenu } from './UserMenu'
import { checkIntegrationFlag, handleLogout } from '@/utils/utils'
import { Searchbar } from '../Searchbar'
import { UserContext } from '@/contexts/UserContext'
import { FeatureFlagContext } from '@/contexts/FeatureFlagContext'
import { SettingsDialog } from './SettingsDialog'
import { SettingsTab } from '@/types/types'

const baseStyles = 'flex items-center gap-2 transition-all'

export function Sidebar() {
  const [settingsTab, setSettingsTab] = useState<SettingsTab | null>(null)

  const layoutContext = useContext(LayoutContext)
  const ff = useContext(FeatureFlagContext)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const integrationsEnabled = checkIntegrationFlag(ff, user)

  const handleSearch = (query: string) => {
    navigate(`/search?q=${query}`)
  }

  return (
    <>
      <nav className="flex flex-col gap-8 items-start font-medium h-full">
        <div className="flex flex-col gap-10 w-full">
          <div
            className="flex gap-2 cursor-pointer"
            onClick={() => layoutContext.toggleShowSidebar(false)}
          >
            <LeftToLine className="w-6 h-6 shrink-0 stroke-system-primary" />

            <TypographyBody isStrong={true}>Hide</TypographyBody>
          </div>
          <div className="flex flex-col gap-8">
            <img
              className="w-[133px] h-[43px] cursor-pointer"
              src={logo}
              onClick={() => navigate('/home')}
            />

            <Searchbar type="sidebar" handleSearch={handleSearch} />
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <Link to="/dossiers" className={`${baseStyles} text-system-primary`}>
            <TypographyBody isStrong={true}>Dossiers</TypographyBody>
          </Link>
          <Link
            to="/assistant/ask"
            className={`${baseStyles} text-system-primary`}
          >
            <TypographyBody isStrong={true}>Ask Desia</TypographyBody>
          </Link>
          <Link to="/reports" className={`${baseStyles} text-system-primary`}>
            <TypographyBody isStrong={true}>Reports</TypographyBody>
          </Link>
          <Link to="/library" className={`${baseStyles} text-system-primary`}>
            <TypographyBody isStrong={true}>Library</TypographyBody>
          </Link>
        </div>

        <div className="flex flex-col gap-10 w-full">
          <Divider />

          <div className="w-fit">
            <UserMenu showMenu={false} />
          </div>

          <div className="flex flex-col gap-6">
            {integrationsEnabled && (
              <Link
                to="/integrations"
                className={`${baseStyles} text-system-primary`}
              >
                <TypographyBody isStrong={true}>Integrations</TypographyBody>
              </Link>
            )}

            <div
              onClick={() => setSettingsTab(SettingsTab.PROFILE)}
              className={`${baseStyles} text-system-primary`}
            >
              <TypographyBody isStrong={true}>Settings</TypographyBody>
            </div>

            <TypographyBody
              className="cursor-pointer"
              isStrong={true}
              onClick={() => handleLogout(navigate)}
            >
              Sign out
            </TypographyBody>
          </div>
        </div>
      </nav>

      <SettingsDialog
        open={Boolean(settingsTab)}
        setOpen={(v) => {
          if (v) {
            setSettingsTab(SettingsTab.PROFILE)
          } else {
            setSettingsTab(null)
          }
        }}
      />
    </>
  )
}
