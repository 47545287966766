import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ASYNC_STATUS, DossierDetail, IntegrationCode, QueryStatus, ResponseDocument, SourceType, UserIntegration } from "../../types/types";
import DocumentSelectorDialog from "./DocumentSelectorDialog";
import { getIntegrationName, plural } from "@/utils/utils";
import { OptionDropdownMenuItem } from "../ui/option-dropdown-menu";
import { FileSearch, Globe, LibraryBig, MessageCircleMore, X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { listDocuments } from '../../components/Document/documentThunk';
import { StateChip } from "../StateChip";
import { Checkbox } from "../ui/checkbox";
import { getIntegrationIcon } from "@/utils/components";
import { TypographyBody, TypographyLabel } from "../ui/Typography";
import { fetchUserIntegrations } from "../Integration/integrationThunk";
import { Button } from "../ui/button";

export function SourceSelector({ dossierDetail, sourceType }: { dossierDetail?: DossierDetail, sourceType: SourceType }) {
    const { settings, updateSettings } = useContext(UserContext);
    const documentStore = useSelector((state: RootState) => state.document);
    const userIntegrations = useSelector((state: RootState) => state.integration.userIntegrations);

    const dispatch = useDispatch<AppDispatch>()

    const [showDocumentSelector, setShowDocumentSelector] = useState(false)
    const [showCommunicationDropdown, setShowCommunicationDropdown] = useState(false)
    const [updatedDefaultDossierFiles, setUpdatedDefaultDossierFiles] = useState(false)

    const sources = settings.assistant.sources[sourceType];

    const selectedFiles = sources.files || []

    const selectedFileResources = (selectedFiles?.map((v) => {
        return documentStore.files?.find((f) => f.document_id === v.id)
    }) || [])
        .filter((v: ResponseDocument | undefined): v is ResponseDocument => Boolean(v))

    const dossierFileResources = (dossierDetail?.sources.map((v) => {
        return documentStore.files?.find((f) => f.document_id === v.resource_id)
    }) || []).filter((v): v is ResponseDocument => Boolean(v))

    const dossierFiles = dossierFileResources.map((v) => {
        return {
            title: v.document_name,
            id: v.document_id
        }
    })

    const isWebSelected = Boolean(settings.assistant.sources[sourceType].webSearch)
    const isInternalSearchSelected = Boolean(settings.assistant.sources[sourceType].internalSearch)
    const isCommunicationSelected = Boolean(settings.assistant.sources[sourceType].outlookSearch || settings.assistant.sources[sourceType].teamsSearch)
    const allCommunicationSelected = Boolean(settings.assistant.sources[sourceType].outlookSearch && settings.assistant.sources[sourceType].teamsSearch)

    const onlyDossierFilesSelected = selectedFileResources.length === dossierFileResources.length && selectedFiles.every((v: {
        title: string;
        id: string;
    }) => dossierFileResources?.find((r) => r.document_id === v.id))

    const personalIntegrations = userIntegrations.data?.filter((v) => v.user_level_integration && v.integration_enabled_by_user)

    const toggleLibraryConnector = () => {
        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    sources: {
                        ...settings.assistant.sources,
                        [sourceType]: {
                            ...settings.assistant.sources[sourceType],
                            internalSearch: !isInternalSearchSelected,
                            focusedAnalysis: Boolean(dossierDetail) && dossierFiles.length > 0,
                            files: isInternalSearchSelected ? [] : dossierFiles
                        }
                    },
                }
            }
        })
    }

    const toggleCommunicationConnector = () => {
        const isTeamsEnabled = personalIntegrations?.some((v) => v.integration_code_name === IntegrationCode.TEAMS)
        const isOutlookEnabled = personalIntegrations?.some((v) => v.integration_code_name === IntegrationCode.OUTLOOK)

        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    sources: {
                        ...settings.assistant.sources,
                        [sourceType]: {
                            ...settings.assistant.sources[sourceType],
                            teamsSearch: isTeamsEnabled ? !isCommunicationSelected : false,
                            outlookSearch: isOutlookEnabled ? !isCommunicationSelected : false,
                        }
                    },
                }
            }
        })
    }

    const getIntegrationDescriptionText = (integration: UserIntegration) => {
        switch (integration.integration_code_name) {
            case IntegrationCode.OUTLOOK:
                return 'Emails including attachment files'
            case IntegrationCode.TEAMS:
                return 'Messages and call transcripts'
        }
    }

    const setDossierDefaultFiles = useCallback(() => {
        if (!updatedDefaultDossierFiles && dossierDetail) {
            updateSettings({
                settings: {
                    ...settings,
                    assistant: {
                        ...settings.assistant,
                        sources: {
                            ...settings.assistant.sources,
                            [sourceType]: {
                                ...settings.assistant.sources[sourceType],
                                files: dossierFiles,
                                focusedAnalysis: dossierFiles.length > 0
                            }
                        },
                    }
                }
            })

            setUpdatedDefaultDossierFiles(true)
        }
    }, [dossierFiles, updatedDefaultDossierFiles, settings, updateSettings, sourceType, dossierDetail])

    useEffect(() => {
        if (documentStore.fetchStatus === QueryStatus.INITIALISED) {
            dispatch(listDocuments())
        }
    }, [documentStore.fetchStatus, dispatch])

    useEffect(() => {
        if (userIntegrations.status === ASYNC_STATUS.idle) {
            dispatch(fetchUserIntegrations())
        }
    }, [dispatch, userIntegrations.status])

    useEffect(() => {
        setDossierDefaultFiles()
    }, [setDossierDefaultFiles])

    return (
        <>
            <div className="flex gap-6 items-center">
                <div className="flex flex-wrap gap-3">
                    <StateChip
                        label={(selectedFiles.length > 0 && !onlyDossierFilesSelected) ? `${selectedFiles.length} ${plural('file', selectedFiles.length)}` : dossierDetail ? 'Dossier files' : "Library"}
                        active={isInternalSearchSelected}
                        leftIcon={<LibraryBig className="size-5 shrink-0 stroke-[1.5px]" />}
                        onClick={toggleLibraryConnector}
                        dropdownContent={<>
                            <OptionDropdownMenuItem
                                selected={isInternalSearchSelected && (selectedFiles.length === 0 || onlyDossierFilesSelected) && !settings.assistant.sources[sourceType].focusedAnalysis}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    updateSettings({
                                        settings: {
                                            ...settings,
                                            assistant: {
                                                ...settings.assistant,
                                                sources: {
                                                    ...settings.assistant.sources,
                                                    [sourceType]: {
                                                        ...settings.assistant.sources[sourceType],
                                                        internalSearch: true,
                                                        focusedAnalysis: Boolean(dossierDetail) && dossierFiles.length > 0,
                                                        files: dossierFiles
                                                    }
                                                },
                                            }
                                        }
                                    })
                                }}
                            >
                                {dossierDetail ? 'Dossier files' : 'All library files'}
                            </OptionDropdownMenuItem>

                            <OptionDropdownMenuItem
                                selected={selectedFiles.length > 0 && settings.assistant.sources[sourceType].focusedAnalysis}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowDocumentSelector(true)
                                }}
                            >
                                <div className="flex gap-2 w-full">
                                    Selected files

                                    <FileSearch className="size-6 shrink-0 stroke-[1.5px] ml-auto" />
                                </div>
                            </OptionDropdownMenuItem>
                        </>}
                    />

                    <StateChip
                        label="Web"
                        active={isWebSelected}
                        leftIcon={<Globe className="size-5 shrink-0 stroke-[1.5px]" />}
                        onClick={() => {
                            updateSettings({
                                settings: {
                                    ...settings,
                                    assistant: {
                                        ...settings.assistant,
                                        sources: {
                                            ...settings.assistant.sources,
                                            [sourceType]: {
                                                ...settings.assistant.sources[sourceType],
                                                webSearch: !isWebSelected,
                                            }
                                        },
                                    }
                                }
                            })
                        }}
                    />

                    {(personalIntegrations?.length || 0) > 0 && (
                        <StateChip
                            label={(isCommunicationSelected && !allCommunicationSelected) ? "1 source" : 'Communications'}
                            active={isCommunicationSelected}
                            leftIcon={<MessageCircleMore className="size-5 shrink-0 stroke-[1.5px]" />}
                            onClick={toggleCommunicationConnector}
                            openDropdown={showCommunicationDropdown}
                            onDropdownOpen={(v) => setShowCommunicationDropdown(v)}
                            dropdownContent={<>
                                <div className="relative" onClick={(e) => e.stopPropagation()}>
                                    <div className="flex flex-col gap-0 py-1">
                                        {personalIntegrations?.map((integration, index) => {
                                            return <OptionDropdownMenuItem selectable={false} key={`integration-dropdown-item-${index}`} className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent">
                                                <div className="flex gap-2 py-2 items-start px-2 min-w-[280px]">
                                                    <Checkbox
                                                        onClick={(e) => e.stopPropagation()}
                                                        checked={integration.integration_code_name === IntegrationCode.TEAMS ? settings.assistant.sources[sourceType].teamsSearch : settings.assistant.sources[sourceType].outlookSearch}
                                                        onCheckedChange={() => {
                                                            if (integration.integration_code_name === IntegrationCode.TEAMS) {
                                                                updateSettings({
                                                                    settings: {
                                                                        ...settings,
                                                                        assistant: {
                                                                            ...settings.assistant,
                                                                            sources: {
                                                                                ...settings.assistant.sources,
                                                                                [sourceType]: {
                                                                                    ...settings.assistant.sources[sourceType],
                                                                                    teamsSearch: !settings.assistant.sources[sourceType].teamsSearch,
                                                                                }
                                                                            },
                                                                        }
                                                                    }
                                                                })
                                                            } else if (integration.integration_code_name === IntegrationCode.OUTLOOK) {
                                                                updateSettings({
                                                                    settings: {
                                                                        ...settings,
                                                                        assistant: {
                                                                            ...settings.assistant,
                                                                            sources: {
                                                                                ...settings.assistant.sources,
                                                                                [sourceType]: {
                                                                                    ...settings.assistant.sources[sourceType],
                                                                                    outlookSearch: !settings.assistant.sources[sourceType].outlookSearch,
                                                                                }
                                                                            },
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    />

                                                    <div className="flex flex-col gap-1 items-center">
                                                        <div className="flex gap-2 items-center w-full">
                                                            <div className="flex items-center justify-center size-6 shrink-0 border-[1.5px] border-system-border-light rounded-sm">
                                                                {getIntegrationIcon(integration.integration_code_name, true, 'w-4 shrink-0')}
                                                            </div>

                                                            <TypographyBody>
                                                                {getIntegrationName(integration.integration_code_name)}
                                                            </TypographyBody>
                                                        </div>

                                                        <TypographyLabel className="text-system-body">
                                                            {getIntegrationDescriptionText(integration)}
                                                        </TypographyLabel>
                                                    </div>
                                                </div>
                                            </OptionDropdownMenuItem>
                                        })}
                                    </div>
                                    <div className="absolute right-0 top-1">
                                        <Button variant='tertiary' onClick={() => setShowCommunicationDropdown(false)}>
                                            <X className="size-6 shrink-0 stroke-[1.5px]" />
                                        </Button>
                                    </div>
                                </div>
                            </>}
                        />
                    )}


                    {/* <StateChip
                        label="Databases"
                        active={false}
                        leftIcon={<Database className="size-5 shrink-0 stroke-[1.5px]" />}
                        onClick={() => { }}
                    /> */}
                </div>
            </div>

            <DocumentSelectorDialog
                sourceType={sourceType}
                open={showDocumentSelector}
                dossierDetail={dossierDetail}
                setOpen={(v) => {
                    setShowDocumentSelector(v)
                }}
                type='settings'
            />
        </>
    )
}
