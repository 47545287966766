import { ReactNode } from "react"
import { TypographyBody } from "./ui/Typography"
import { OptionDropdownMenu, OptionDropdownMenuContent, OptionDropdownMenuTrigger } from "./ui/option-dropdown-menu"
import { ChevronDown } from "lucide-react"

export const StateChip = ({ label, active, leftIcon, dropdownContent, openDropdown, onDropdownOpen, onClick }: { label: string, active: boolean, leftIcon?: ReactNode, dropdownContent?: ReactNode, openDropdown?: boolean, onDropdownOpen?: (open: boolean) => void, onClick?: () => void }) => {
    return (
        <div onClick={onClick} className={`inline-flex items-center rounded-full font-label px-2 py-px transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-0 h-fit w-fit ${active ? 'bg-badge-blue-bg text-badge-blue-graphic [&_svg]:stroke-badge-blue-graphic' : 'bg-system-hover hover:bg-system-border-light [&_svg]:stroke-system-body'} ${onClick ? 'cursor-pointer' : ''}`}>
            <div className="flex gap-2 items-center">
                <div className={`size-1.5 shrink-0 rounded-full ${active ? 'bg-badge-blue-graphic' : 'border border-system-body'}`}></div>

                <div className="flex gap-1 items-center">
                    {leftIcon}

                    <TypographyBody className={active ? 'text-badge-blue-graphic' : 'text-system-body'}>
                        {label}
                    </TypographyBody>

                    {dropdownContent && (
                        <OptionDropdownMenu open={openDropdown} onOpenChange={onDropdownOpen}>
                            <OptionDropdownMenuTrigger>
                                <ChevronDown className="size-6 shrink-0 stroke-[1.5px]" />
                            </OptionDropdownMenuTrigger>

                            <OptionDropdownMenuContent className="w-fit px-2 py-2 min-w-[200px]" align="end">
                                {dropdownContent}
                            </OptionDropdownMenuContent>
                        </OptionDropdownMenu>

                    )}
                </div>
            </div>
        </div>
    )
}