import { WEB_SERVER_ENDPOINT } from '@/constants';
import { ASYNC_STATUS, Dossier, DossierDetail, RequestAddDossierReport, RequestAddDossierSource, RequestAddDossierThread, RequestCreateDossier, RequestDeleteDossierSource } from '@/types/types';
import { handleError } from '@/utils/handleError';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store/store';

export interface DossierState {
    dossiers: {
        data: Dossier[] | null,
        status: ASYNC_STATUS
    },
    dossierDetails: {
        [id: string]: {
            data: DossierDetail | null,
            status: ASYNC_STATUS
        }
    },
    createDossier: {
        status: ASYNC_STATUS
    },
    deleteDossier: {
        status: ASYNC_STATUS
    },
    addDossierThread: {
        status: ASYNC_STATUS
    },
    addDossierReport: {
        status: ASYNC_STATUS
    },
    addDossierSource: {
        status: ASYNC_STATUS
    },
    removeDossierSource: {
        status: ASYNC_STATUS
    },
    removeAllDossierSources: {
        status: ASYNC_STATUS
    },
}

const initialState: DossierState = {
    dossiers: {
        data: null,
        status: ASYNC_STATUS.idle
    },
    dossierDetails: {},
    createDossier: {
        status: ASYNC_STATUS.idle
    },
    deleteDossier: {
        status: ASYNC_STATUS.idle
    },
    addDossierThread: {
        status: ASYNC_STATUS.idle
    },
    addDossierReport: {
        status: ASYNC_STATUS.idle
    },
    addDossierSource: {
        status: ASYNC_STATUS.idle
    },
    removeDossierSource: {
        status: ASYNC_STATUS.idle
    },
    removeAllDossierSources: {
        status: ASYNC_STATUS.idle
    },
}

export const fetchDossiers = createAsyncThunk<
    Dossier[],
    void,
    { state: RootState }
>(
    'dossier/fetchDossiers',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/dossier/list`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    }
)

export const fetchDossierDetail = createAsyncThunk<DossierDetail, string>(
    'dossier/fetchDossierDetail',
    async (dossierId: string, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/dossier/${dossierId}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const createDossier = createAsyncThunk<Dossier, RequestCreateDossier>(
    'dossier/createDossier',
    async (payload: RequestCreateDossier, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/dossier`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(payload)
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const deleteDossier = createAsyncThunk<void, string>(
    'dossier/deleteDossier',
    async (dossierId: string, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/dossier/${dossierId}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (!response.ok) {
            return rejectWithValue(response.statusText)
        }
        return
    },
)

export const addDossierThread = createAsyncThunk<void, RequestAddDossierThread>(
    'dossier/addDossierThread',
    async (payload: RequestAddDossierThread, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/dossier/${payload.dossierId}/add-thread`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                threadId: payload.threadId
            })
        });
        if (!response.ok) {
            return rejectWithValue(response.statusText)
        }
        return
    },
)

export const addDossierReport = createAsyncThunk<void, RequestAddDossierReport>(
    'dossier/addDossierReport',
    async (payload: RequestAddDossierReport, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/dossier/${payload.dossierId}/add-report`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                reportId: payload.reportId
            })
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const addDossierSource = createAsyncThunk<void, RequestAddDossierSource>(
    'dossier/addDossierSource',
    async (payload: RequestAddDossierSource, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/dossier/${payload.dossierId}/add-sources`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                sources: payload.sources
            })
        });
        if (!response.ok) {
            return rejectWithValue({ message: response.statusText })
        }
        return
    },
)

export const removeAllDossierSources = createAsyncThunk<void, string>(
    'dossier/removeAllDossierSources',
    async (dossierId: string, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/dossier/${dossierId}/remove-sources`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({})
        });
        const data = await response.json()
        if (!response.ok) {
            return rejectWithValue(data)
        }
        return data
    },
)

export const removeDossierSource = createAsyncThunk<void, RequestDeleteDossierSource>(
    'dossier/removeDossierSource',
    async (payload: RequestDeleteDossierSource, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/dossier/${payload.dossierId}/source/${payload.sourceId}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (!response.ok) {
            return rejectWithValue({ message: response.statusText })
        }
        return
    },
)

export const dossierSlice = createSlice({
    name: 'dossier',
    initialState,
    reducers: {
        addDossier: (state, action: PayloadAction<Dossier>) => {
            state.dossiers.data = [...(state.dossiers.data || []), action.payload]
        },
        removeDossier: (state, action: PayloadAction<Dossier>) => {
            state.dossiers.data = state.dossiers.data?.filter((v) => v.id !== action.payload.id) || []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDossiers.pending, (state) => {
            state.dossiers.status = ASYNC_STATUS.loading
        })
        builder.addCase(fetchDossiers.fulfilled, (state, action) => {
            state.dossiers.status = ASYNC_STATUS.success
            state.dossiers.data = action.payload
        })
        builder.addCase(fetchDossiers.rejected, (state, action) => {
            state.dossiers.status = ASYNC_STATUS.error
            state.dossiers.data = null
            handleError(action.error)
        })
        builder.addCase(createDossier.pending, (state) => {
            state.createDossier.status = ASYNC_STATUS.loading
        })
        builder.addCase(createDossier.fulfilled, (state) => {
            state.createDossier.status = ASYNC_STATUS.success
        })
        builder.addCase(createDossier.rejected, (state, action) => {
            state.createDossier.status = ASYNC_STATUS.error
            handleError(action.error)
        })
        builder.addCase(deleteDossier.pending, (state) => {
            state.deleteDossier.status = ASYNC_STATUS.loading
        })
        builder.addCase(deleteDossier.fulfilled, (state) => {
            state.deleteDossier.status = ASYNC_STATUS.success
        })
        builder.addCase(deleteDossier.rejected, (state, action) => {
            state.deleteDossier.status = ASYNC_STATUS.error
            handleError(action.error)
        })
        builder.addCase(fetchDossierDetail.pending, (state, action) => {
            state.dossierDetails[action.meta.arg] = {
                ...state.dossierDetails[action.meta.arg],
                status: ASYNC_STATUS.loading
            }
        })
        builder.addCase(fetchDossierDetail.fulfilled, (state, action) => {
            state.dossierDetails[action.meta.arg] = {
                ...state.dossierDetails[action.meta.arg],
                data: action.payload,
                status: ASYNC_STATUS.success
            }
        })
        builder.addCase(fetchDossierDetail.rejected, (state, action) => {
            state.dossierDetails[action.meta.arg] = {
                ...state.dossierDetails[action.meta.arg],
                data: null,
                status: ASYNC_STATUS.error
            }
            handleError(action.error)
        })
        builder.addCase(addDossierThread.pending, (state) => {
            state.addDossierThread.status = ASYNC_STATUS.loading
        })
        builder.addCase(addDossierThread.fulfilled, (state) => {
            state.addDossierThread.status = ASYNC_STATUS.success
        })
        builder.addCase(addDossierThread.rejected, (state, action) => {
            state.addDossierThread.status = ASYNC_STATUS.error
            handleError(action.error)
        })
        builder.addCase(addDossierReport.pending, (state) => {
            state.addDossierReport.status = ASYNC_STATUS.loading
        })
        builder.addCase(addDossierReport.fulfilled, (state) => {
            state.addDossierReport.status = ASYNC_STATUS.success
        })
        builder.addCase(addDossierReport.rejected, (state, action) => {
            state.addDossierReport.status = ASYNC_STATUS.error
            handleError(action.error)
        })
        builder.addCase(addDossierSource.pending, (state) => {
            state.addDossierSource.status = ASYNC_STATUS.loading
        })
        builder.addCase(addDossierSource.fulfilled, (state) => {
            state.addDossierSource.status = ASYNC_STATUS.success
        })
        builder.addCase(addDossierSource.rejected, (state, action) => {
            state.addDossierSource.status = ASYNC_STATUS.error
            handleError(action.error)
        })
        builder.addCase(removeDossierSource.pending, (state) => {
            state.removeDossierSource.status = ASYNC_STATUS.loading
        })
        builder.addCase(removeDossierSource.fulfilled, (state) => {
            state.removeDossierSource.status = ASYNC_STATUS.success
        })
        builder.addCase(removeDossierSource.rejected, (state, action) => {
            state.removeDossierSource.status = ASYNC_STATUS.error
            handleError(action.error)
        })
        builder.addCase(removeAllDossierSources.pending, (state) => {
            state.removeAllDossierSources.status = ASYNC_STATUS.loading
        })
        builder.addCase(removeAllDossierSources.fulfilled, (state) => {
            state.removeAllDossierSources.status = ASYNC_STATUS.success
        })
        builder.addCase(removeAllDossierSources.rejected, (state, action) => {
            state.removeAllDossierSources.status = ASYNC_STATUS.error
            handleError(action.error)
        })
    },
})

export const dossierActions = dossierSlice.actions;
export default dossierSlice.reducer
