import { ReactNode } from "react"

export function TypographyH1({ className, children }: { className?: string, children: ReactNode }) {
  const style = `${className} font-h1`
    return (
      <h1 className={style}>
        {children}
      </h1>
    )
  }

  export function TypographyH2({ className, children }: { className?: string, children: ReactNode }) {
    const style = `${className} font-h2`
    return (
      <h2 className={style}>
        {children}
      </h2>
    )
  }

export function TypographyH3({ className, children }: { className?: string, children: ReactNode }) {
  const style = `${className} font-h3`
  return (
    <h3 className={style}>
      {children}
    </h3>
  )
}

  export function TypographyH4({ children, className }: { children: ReactNode, className?: string }) {
    const style = `${className || ""} font-h4`;
    return (
      <h4 className={style}>
        {children}
      </h4>
    )
  }

export function TypographyP({ className, children }: { className?: string, children: ReactNode }) {
  const style = `${className} font-body`
  return (
    <p className={style}>
      {children}
    </p>
  )
}

export function TypographyBody({
  children,
  className,
  isStrong,
  onClick
}: {
  children: ReactNode,
  className?: string,
  isStrong?: boolean,
  onClick?: () => void
}) {
  const style = `${className || ""} ${isStrong ? "font-body-strong" : "font-body"}`;
  return (
    <p className={style} onClick={() => {
      if (onClick) {
        onClick();
      }
    }}>
      {children}
    </p>
  )
}

export function TypographyBlockquote({ children }: { children: ReactNode }) {
  return (
    <blockquote className="mt-6 border-l-2 pl-6 italic">
      {children}
    </blockquote>
  )
}

export function TypographyLead({ children }: { children: ReactNode }) {
  return (
    <p className="text-xl text-muted-foreground">
      {children}
    </p>
  )
}

export function TypographyLarge({ children }: { children: ReactNode }) {
  return <div className="text-lg font-semibold">{children}</div>
}

export function TypographySmall({ children }: { children: ReactNode }) {
  return (
    <small className="text-sm font-medium leading-none">{children}</small>
  )
}

export function TypographyMuted({ children }: { children: ReactNode }) {
  return (
    <p className="text-sm text-muted-foreground">{children}</p>
  )
}

export function TypographyLabel({ className, children }: { className?: string, children: ReactNode }) {
  const style = `${className} font-label`
  return (
    <p className={style}>
      {children}
    </p>
  )
}