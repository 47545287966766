import { useCallback, useContext, useEffect } from "react"
import { fetchIntegrations, fetchIntegrationStatus } from "./integrationSlice"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext"
import { UserContext } from "@/contexts/UserContext"
import { checkIntegrationFlag, getIntegrationName } from "@/utils/utils"
import { Integration, IntegrationCode } from "@/types/types"
import { Button } from "../ui/button"
import { ArrowLeft } from "lucide-react"
import { TypographyH3 } from "../ui/Typography"
import { getIntegrationBadge } from "@/utils/components"
import { IntegrationCard } from "./IntegrationCard"
import { RemoveIntegrationCard } from "./RemoveIntegrationCard"
import { Link } from "react-router-dom"
import { isFulfilled } from "@reduxjs/toolkit"
import { CustomAlert } from "../CustomAlert"

export const MicrosoftIntegrationPage = () => {
    const integrations = useSelector((state: RootState) => state.integration.integrations)
    const integrationStatus = useSelector((state: RootState) => state.integration.integrationStatus)

    const microsoftIntegrations = integrations.data?.filter((v) => v.integration_code_name.includes('microsoft'))?.reverse() || []

    const ff = useContext(FeatureFlagContext)
    const { user } = useContext(UserContext)

    const integrationFlag = checkIntegrationFlag(ff, user)

    const dispatch = useDispatch<AppDispatch>();

    const isConnected = !microsoftIntegrations.some((v) => (!v.integration_has_setup || !v.integration_is_process) && (v.integration_code_name === IntegrationCode.ONEDRIVE || v.integration_code_name === IntegrationCode.SHAREPOINT))
    const isProcessing = Object.values(integrationStatus).find((v) => {
        const detail = v.data
        const processingCount = (detail?.synchronization_details.available_count || 0) + (detail?.synchronization_details.failed_count || 0)
        const integration = microsoftIntegrations.find((i) => i.integration_id === v.data?.integration_details.integration_id)
        const processing = integration?.integration_has_setup && detail?.synchronization_details.files_to_synchronize_count && processingCount !== detail?.synchronization_details.files_to_synchronize_count
        return integration && processing
    })
    const errorIntegrations = Object.values(integrationStatus).filter((v) => (v.data?.synchronization_details.failed_count || 0) > 0)

    const fetchStatus = useCallback((integrations: Integration[]) => {
        integrations.forEach((integration) => {
            dispatch(fetchIntegrationStatus(integration.integration_id))
        })
    }, [dispatch])

    const refreshIntegrations = useCallback(async () => {
        const result = await dispatch(fetchIntegrations())
        if (isFulfilled(fetchIntegrations)(result)) {
            fetchStatus(result.payload)
        }

    }, [dispatch, fetchStatus])

    useEffect(() => {
        refreshIntegrations()
    }, [dispatch, refreshIntegrations])

    useEffect(() => {
        const intervalId = setInterval(() => fetchStatus(integrations.data || []), 10000)

        return () => clearInterval(intervalId)
    }, [integrations.data, fetchStatus])

    useEffect(() => {
        document.title = 'Desia AI - Integrations'
    }, [])

    if (!integrationFlag) return null

    return (
        <div className="flex flex-col gap-0 mx-[88px]">
            <Link to={`/integrations`}>
                <Button variant='tertiary'>
                    <div className="flex gap-2 items-center">
                        <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />
                        Integrations
                    </div>
                </Button>
            </Link>
            <div className="flex flex-col gap-10 mx-auto w-full">
                <div className="flex flex-col gap-4 items-center">
                    <TypographyH3>
                        Microsoft 365 integration
                    </TypographyH3>

                    {getIntegrationBadge(isProcessing ? 'updating' : isConnected ? 'connected' : 'off')}
                </div>

                {errorIntegrations.map((detail, index) => {
                    return (
                        <div key={`integration-error-${index}`} className="w-[550px] mx-auto">
                            <CustomAlert
                                variant="error"
                                title={`We could not process some of the ${getIntegrationName(detail.data?.integration_details.integration_code_name || '')} files `}
                                description={`${detail.data?.synchronization_details.available_count || 0}/${detail.data?.synchronization_details.files_to_synchronize_count || 0} files were processed. Our team has been notified.`}
                            />
                        </div>
                    )
                })}

                <div className="flex flex-col gap-20 w-full">
                    <div className="grid grid-cols-2 gap-6">
                        {microsoftIntegrations?.map((integration, index) => {
                            return <IntegrationCard key={`integration-card-${index}`} integration={integration} detail={integrationStatus[integration.integration_id]?.data} />
                        })}
                    </div>

                    <div className="grid grid-cols-2 gap-6">
                        <RemoveIntegrationCard />
                    </div>
                </div>
            </div>
        </div>
    )
}