import { ChatSummary, Dossier, DossierDetail } from "@/types/types"
import { ChatList } from "../ChatList";
import { ReportList } from "../Reports/ReportList";
import { NotebookPen, NotebookText } from "lucide-react";
import { TypographyBody, TypographyH4 } from "../ui/Typography";
import Divider from "../ui/divider";
import { Button } from "../ui/button";
import { getTimestamp } from "@/utils/utils";

export const DossierContent = ({ dossier, dossierDetail, createReportHandler }: { dossier: Dossier, dossierDetail: DossierDetail | null, createReportHandler: () => void }) => {
    const mappedChats: ChatSummary[] = dossierDetail?.conversations.map((v) => {
        return {
            conversationId: v.id,
            query: v.title,
            created_at: getTimestamp(v.created_at),
            updated_at: getTimestamp(v.updated_at),
            dossierId: v.dossier_id
        }
    })?.reverse() || []

    const reports = dossierDetail?.reports || []

    return (
        <div className="flex flex-col tablet:flex-row gap-10 tablet:gap-6 w-full">
            <div className="tablet:min-w-[340px] laptop:min-w-[317px]">
                <ChatList
                    threads={mappedChats}
                    loading={false}
                    error={null}
                    type='dossier'
                    dossier={dossier}
                />
            </div>

            <div className="w-full">
                <div className="flex flex-col gap-4">
                    <div className="flex gap-2 px-3 items-center">
                        <NotebookText className="h-6 w-6 shrink-0 stroke-[1.5px]" />

                        <TypographyH4>
                            Reports
                        </TypographyH4>
                    </div>

                    <Divider />

                    {reports.length > 0 && (
                        <ReportList
                            reports={reports}
                            loading={false}
                            error={null}
                            type='dossier'
                        />
                    )}

                    {reports.length === 0 && (
                        <div className="flex flex-col gap-4 mx-auto mt-2">
                            <TypographyBody className="text-system-body">
                                There are no reports yet.
                            </TypographyBody>

                            <Button variant='secondary' className="w-fit mx-auto" onClick={createReportHandler}>
                                <div className="flex gap-2">
                                    <NotebookPen className="size-6 shrink-0 !stroke-link" />

                                    Create report
                                </div>
                            </Button>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}