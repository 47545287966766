const SuggestionCardBriefcase = ({ className }: { className?: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <path d="M12 12H12.01" stroke="#3E526E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 6V4C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V6" stroke="#3E526E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 13C19.0328 14.959 15.5555 16.0033 12 16.0033C8.44445 16.0033 4.96721 14.959 2 13" stroke="#3E526E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 6H4C2.89543 6 2 6.89543 2 8V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V8C22 6.89543 21.1046 6 20 6Z" stroke="#3E526E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default SuggestionCardBriefcase