import { SourceConnector, SourceSettings } from "@/types/types";
import { checkUserFlag } from "./utils";

export function getAskTools(s: SourceSettings) {
    const toolsEnabled = checkUserFlag("assistant: tools");
    if (!toolsEnabled) {
        return {};
    }

    const tools = [
        { "name": "python_interpreter" },
        { "name": "calculator" },
    ];

    if (s.webSearch) {
        tools.push({ "name": "web_search" });
    }
    if (s.internalSearch) {
        tools.push({ "name": "internal_search" });
    }
    if (s.teamsSearch) {
        tools.push({ "name": "teams_search" });
    }
    if (s.outlookSearch) {
        tools.push({ "name": "outlook_search" });
    }

    return {
        tools,
    }
}

export function getAskConnectors(s: SourceSettings) {
    const connectorsV2: SourceConnector[] = []

    if (s.webSearch) {
        connectorsV2.push({ id: "web-search" });
    }
    if (s.internalSearch) {
        connectorsV2.push({ id: "internal-search" });
    }
    if (s.teamsSearch) {
        connectorsV2.push({ id: "teams-search" });
    }
    if (s.outlookSearch) {
        connectorsV2.push({ id: "outlook-search" });
    }

    return connectorsV2
}
