import { ASYNC_STATUS, Integration } from "@/types/types";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { checkIntegrationFlag } from "@/utils/utils";
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext";
import { UserContext } from "@/contexts/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { IntegrationGreenlisting } from "./IntegrationGreenlisting";
import { fetchIntegrations } from "./integrationSlice";

export interface IntegrationDetailProps {
    integration: Integration
}

export const IntegrationDetailPage = ({ integration }: IntegrationDetailProps) => {
    return (
        <div className="flex flex-col gap-2 mx-[88px]">
            <IntegrationGreenlisting integration={integration} isManage />
        </div>
    )
}

export const IntegrationDetailPageContainer = () => {
    const { integration_code_name } = useParams();

    const integrations = useSelector((state: RootState) => state.integration.integrations)

    const ff = useContext(FeatureFlagContext)
    const { user } = useContext(UserContext)

    const dispatch = useDispatch<AppDispatch>()

    const integrationFlag = checkIntegrationFlag(ff, user)

    const selectedIntegration = integrations.data?.find((v) => v.integration_code_name === integration_code_name)

    useEffect(() => {
        document.title = 'Desia AI - Integration'
    }, [])

    useEffect(() => {
        if (integrations.status === ASYNC_STATUS.idle) {
            dispatch(fetchIntegrations())
        }
    }, [integrations.status, dispatch])

    if (!selectedIntegration) return null

    if (!integrationFlag) return null

    return (
        <IntegrationDetailPage integration={selectedIntegration} />
    )
}