import { useEffect, useRef, useState } from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import {
  convertTemplateTitleToName,
  convertThemesToString,
  convertToBullets,
  convertToHyphens,
} from '@/utils/utils'
import { TypographyBody, TypographyH4 } from '../ui/Typography'
import { Button } from '../ui/button'
import {
  ChevronDown,
  ChevronUp,
  EllipsisVertical,
  Sparkles,
  X,
} from 'lucide-react'
import { Badge } from '../ui/badge'
import Checkmark from '@/assets/Checkmark'
import { DocgenTemplate, DocgenThemeSection } from '@/types/types'
import { findThemePosition } from './utils'

export const DocGenThemeCard = ({
  section,
  template,
  onSectionUpdate,
}: {
  section: DocgenThemeSection
  template: DocgenTemplate | null
  onSectionUpdate: (section: DocgenThemeSection) => void
}) => {
  const [expanded, setExpanded] = useState(false)
  const [title, setTitle] = useState(section.title)
  const [editingTitle, setEditingTitle] = useState(false)
  const [editingThemes, setEditingThemes] = useState(false)
  const [themes, setThemes] = useState(
    convertThemesToString(section.description)
  )

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const contentExpands = themes.split('\n').length > 3

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px'
      const scrollHeight = textareaRef.current.scrollHeight
      textareaRef.current.style.height = Math.max(42, scrollHeight) + 'px'
    }
  }, [themes, editingThemes])

  const position = findThemePosition(template?.sections || [], section.title, 0)

  return (
    <div
      style={{ marginLeft: `${16 * (position?.level || 0)}px` }}
      className="relative flex flex-col gap-4 py-4 px-4 bg-system-secondary rounded-lg border border-system-border-light"
    >
      <div className="flex gap-3">
        {position && (
          <TypographyH4
            className={`text-system-border-regular ${position.level === 0 ? 'min-w-3' : 'min-w-6'}`}
          >
            {`${position.level === 0 ? `${(position.indexPath.at(0) || 0) + 1}` : ''}${position.level === 1 ? `${(position.indexPath.at(0) || 0) + 1}.${(position.indexPath.at(1) || 0) + 1}` : ''}${position.level === 2 ? `${(position.indexPath.at(0) || 0) + 1}.${(position.indexPath.at(1) || 0) + 1}.${(position.indexPath.at(1) || 0) + 1}` : ''}`}
          </TypographyH4>
        )}

        <TypographyH4 className="w-full">{title}</TypographyH4>

        <DropdownMenu>
          <DropdownMenuTrigger className="flex items-center" asChild>
            <Button variant="tertiary">
              <EllipsisVertical className={`size-6 shrink-0 stroke-[1.5px]`} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-[193px]">
            {/* <DropdownMenuItem
                            variant='tertiary'
                            onClick={(e) => {
                                e.stopPropagation()
                                setEditingTitle(true)
                            }}>
                            Rename
                        </DropdownMenuItem> */}

            <DropdownMenuItem
              variant="tertiary"
              onClick={(e) => {
                e.stopPropagation()
                setEditingThemes(true)
              }}
            >
              Edit themes
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {editingTitle && (
        <div className="absolute top-2 left-4 flex gap-3">
          {position && (
            <TypographyH4
              className={`text-system-border-regular ${position.level === 0 ? 'min-w-3' : 'min-w-6'} opacity-0`}
            >
              {`${position.level === 0 ? `${(position.indexPath.at(0) || 0) + 1}` : ''}${position.level === 1 ? `${(position.indexPath.at(0) || 0) + 1}.${(position.indexPath.at(1) || 0) + 1}` : ''}${position.level === 2 ? `${(position.indexPath.at(0) || 0) + 1}.${(position.indexPath.at(1) || 0) + 1}.${(position.indexPath.at(1) || 0) + 1}` : ''}`}
            </TypographyH4>
          )}

          <div className="flex gap-3 w-[calc(100%-32px)] px-2 py-2 bg-system-surface border border-system-border-regular rounded-lg">
            <div className="flex gap-3 w-full">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex gap-6 w-full">
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="font-h4 text-system-primary focus:outline-none w-full placeholder:text-system-placeholder"
                    placeholder="Section title"
                    autoFocus
                  />
                </div>
              </div>

              <div className="flex gap-2 items-center">
                <Button
                  variant="tertiary"
                  className="h-fit"
                  onClick={() => {
                    setTitle(section.title)
                    setEditingTitle(false)
                  }}
                >
                  <X className="size-6 shrink-0 stroke-[1.5px]" />
                </Button>

                <Button
                  variant="tertiary"
                  className="h-fit"
                  onClick={() => {
                    setEditingTitle(false)
                    onSectionUpdate({
                      ...section,
                      title: title,
                      name: convertTemplateTitleToName(title),
                    })
                  }}
                >
                  <Checkmark className="size-6 shrink-0 stroke-[1.5px]" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {!editingThemes && (
        <div className="relative flex flex-col gap-3 p-2 pb-4 bg-system-surface-light rounded-[12px]">
          <Badge className="bg-system-secondary border border-system-border-light w-fit">
            <div className="flex gap-2 items-center text-system-body">
              <Sparkles className="size-4 shrink-0 stroke-[1.5px] stroke-system-body" />
              Themes
            </div>
          </Badge>

          <div
            className={`${expanded ? 'h-fit pb-4' : 'max-h-[100px]'} font-body-strong text-system-primary whitespace-pre-wrap px-2 overflow-hidden`}
          >
            {convertToBullets(themes)
              .split('\n')
              .map((theme, index) => {
                return (
                  <TypographyBody key={`themes-${theme}-${index}`}>
                    {theme}
                  </TypographyBody>
                )
              })}
          </div>

          {!expanded && contentExpands && (
            <div className="absolute bottom-2 left-0 w-full h-[80px] bg-gradient-to-t from-system-surface-light to-transparent"></div>
          )}

          {contentExpands &&
            (!expanded ? (
              <Button
                className="absolute bottom-2 right-2"
                variant="tertiary"
                onClick={() => setExpanded(true)}
              >
                <div className="flex gap-2 items-center">
                  Show more
                  <ChevronDown className="size-6 shrink-0 stroke-[1.5px]" />
                </div>
              </Button>
            ) : (
              <Button
                className="absolute bottom-2 right-2"
                variant="tertiary"
                onClick={() => setExpanded(false)}
              >
                <div className="flex gap-2 items-center">
                  Show less
                  <ChevronUp className="size-6 shrink-0 stroke-[1.5px]" />
                </div>
              </Button>
            ))}
        </div>
      )}

      {editingThemes && (
        <div className="flex flex-col gap-2">
          <TypographyBody isStrong>Themes</TypographyBody>
          <div className="relative flex flex-col gap-0 bg-system-secondary rounded-sm border border-system-border-regular">
            <div className={`whitespace-pre-wrap overflow-hidden`}>
              <textarea
                ref={textareaRef}
                className="px-3 py-2 -mb-2 text-system-primary font-body w-full focus:outline-none"
                value={convertToBullets(themes)}
                onChange={(e) => setThemes(convertToHyphens(e.target.value))}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    setThemes(themes + '\n- ')
                  }
                }}
              />
            </div>

            <div className="flex gap-2 items-center ml-auto px-3 py-2">
              <Button
                variant="tertiary"
                className="h-fit"
                onClick={() => {
                  setThemes(convertThemesToString(section.description))
                  setEditingThemes(false)
                }}
              >
                <X className="size-6 shrink-0 stroke-[1.5px]" />
              </Button>

              <Button
                variant="tertiary"
                className="h-fit"
                onClick={() => {
                  setEditingThemes(false)
                  const lines = themes.split('\n')
                  const cleanedLines = lines.map((v) =>
                    v.startsWith('- ') ? v.slice(2) : v
                  )

                  onSectionUpdate({
                    ...section,
                    description: cleanedLines,
                  })
                }}
              >
                <Checkmark className="size-6 shrink-0 stroke-[1.5px]" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
