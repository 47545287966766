import { ChartConfig } from "../ui/chart"
import { ResponseChartData } from "@/types/types"
import { memo, useMemo } from "react"
import { format, isValid } from "date-fns"
import { getLocale } from "@/utils/utils"
import { CustomAlert } from "../CustomAlert"
import { ResponseLineChart } from "./ResponseLineChart"
import { ResponseBarChart } from "./ResponseBarChart"
import { ResponseAreaChart } from "./ResponseAreaChart"
import { ResponsePieChart } from "./ResponsePieChart"
import { ApexOptions } from "apexcharts"
import { renderToStaticMarkup } from 'react-dom/server';
import { TypographyBody, TypographyLabel } from "../ui/Typography"
import { Color } from "@/constants"
import { ResponseStackedBarChart } from "./ResponseStackedBarChart"

const lowContrastColorIndex: ReadonlySet<number> = new Set([0, 2]);
const SHORT_WORDS_LENGTH = 3 as const
const MEDIUM_WORDS_LENGTH = 7 as const
const X_LABEL_CATEGORY_TRUNCATION_THRESHOLD = 5 as const

export const isLowContrast = (configColorIndex: number): boolean =>
  lowContrastColorIndex.has(configColorIndex);

export const formatChartDate = (dateString: string | string[]) => {
    const joinedDate = Array.isArray(dateString) ? dateString.join(' ') : dateString
    const date = new Date(joinedDate)

    const valid = isValid(date)

    if (valid && joinedDate.includes('-')) {
        const currentDate = new Date()
        const formattedDate = currentDate.getFullYear() === date.getFullYear() ? format(date, 'd MMM') : format(date, 'd MMM yyyy')
        return formattedDate
    }

    return dateString
}

export const formatChartNumber = (label: string) => {
    const number = parseInt(label)

    if (number) {
        return number.toLocaleString(getLocale())
    }

    return label
}

export const formatTruncateDataLabel = ({
  label, 
  maxChar = 5,
  categoryAmount
}:{
  label: string,
  maxChar?: number
  categoryAmount?: number
}) => {
  if(categoryAmount && categoryAmount <= X_LABEL_CATEGORY_TRUNCATION_THRESHOLD) return label

  return label.length > maxChar? label.slice(0, maxChar - 1) + '...' : label
}

export const pxToCh = (width: number, fontSize: number) => {
  const CHAR_WIDTH_FACTOR = 0.65 as const
  const averageCharWidth = fontSize * CHAR_WIDTH_FACTOR
  const charCount = width / averageCharWidth

  return Math.ceil(charCount)
};

const getChartColor = (index: number): string => {
    const colors = [
        Color["system-chart-1"],
        Color["system-chart-2"],
        Color["system-chart-3"],
        Color["system-chart-4"],
        Color["system-chart-5"],
        Color["system-chart-6"],
        Color["system-chart-7"],
        Color["system-chart-8"],
        Color["system-chart-9"],
        Color["system-chart-10"],
    ]

    return colors[index % colors.length]
}

export const ResponseChart = memo(({ id, data, compact }: { id: string, data: string, compact?: boolean }) => {
    try {
        const parsedData: ResponseChartData = JSON.parse(data)
       
        const categories = useMemo(() => {
          return parsedData.data.map((v) => {
            const words = v.label.split(" ");
            const formattedWords: string[] = [];
            let i = 0;
            while (i < words.length) {
              if (words[i].length > SHORT_WORDS_LENGTH) {
                formattedWords.push(words[i]);
                i++;
              } 
              else {
                if (i + 1 < words.length && words[i + 1].length <= MEDIUM_WORDS_LENGTH) {
                  formattedWords.push(`${words[i]} ${words[i + 1]}`);
                  i += 2;
                } 
                else {
                  formattedWords.push(words[i]);
                  i++;
                }
              }
            }

            return formattedWords;
          });
        }, [parsedData]);

        const series: ApexAxisChartSeries = parsedData.values.map((v, idx) => {
            return {
                name: v,
                data: parsedData.data.map((v) => v.value[idx])
            }
        })

        const options: ApexOptions = {
            chart: {
                id: parsedData.title,
                zoom: {
                    enabled: false
                },
                fontFamily: "Inter, sans-serif",
                height: '400px',
                parentHeightOffset: 0,
                toolbar: {
                    show: false,
                    export: {
                        csv: {
                            filename: parsedData.title
                        }
                    }
                },
                events: {
                    mounted: (chart) => {
                        chart.windowResizeHandler();
                    }
                },
                animations: {
                    enabled: false
                },
                selection: {
                    enabled: false
                },
            },
            stroke: {
                width: 2,
                curve: 'straight'
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: categories,
                labels: {
                    formatter: (val: string) => {
                        return formatChartDate(val)
                    },
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        colors: Color["system-body"],
                    },
                    trim: true,
                    rotate: 0,
                    show: true,
                    hideOverlappingLabels: false
                },
                axisBorder: {
                    color: Color["system-border-light"]
                },
                axisTicks: {
                    color: Color["system-border-light"]
                },
                crosshairs: {
                    stroke: {
                        color: Color["system-border-light"],
                        dashArray: 8
                    }
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                labels: {
                    formatter: (val: number) => {
                        return formatChartNumber(JSON.stringify(val));
                    },
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 400,
                        colors: Color["system-body"]
                    },
                    
          
                },
                axisBorder: {
                    color: Color["system-border-light"]
                },
                tickAmount: 3
            },
            grid: {
                borderColor: Color["system-border-light"],
            },
            markers: {
                size: 0,
                strokeWidth: 0,
                hover: {
                    size: 4,
                },
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    columnWidth: '80%',
                    borderRadiusApplication: 'end'
                }
            },
            colors: [
                Color["system-chart-1"],
                Color["system-chart-2"],
                Color["system-chart-3"],
                Color["system-chart-4"],
                Color["system-chart-5"],
                Color["system-chart-6"],
                Color["system-chart-7"],
                Color["system-chart-8"],
                Color["system-chart-9"],
                Color["system-chart-10"],
            ],
            fill: {
                opacity: 1
            },
            tooltip: {
                shared: true,
                intersect: false,
                custom: ({ dataPointIndex }) => {
                    const ChartTooltip = () => {
                        return (
                            <div className="flex flex-col gap-2 px-3 py-2 rounded-sm border border-system-border-regular shadow-soft z-[50]">
                                <TypographyBody isStrong={true} className="text-system-primary">
                                    {formatChartDate(categories[dataPointIndex].join(' '))}
                                </TypographyBody>

                                <div className="flex flex-col gap-2">
                                    {series.map((v, idx) => {
                                        return (
                                            <div className="flex gap-3 items-center">
                                                <div className="size-4 rounded-[2px]" style={{ backgroundColor: getChartColor(idx) }}></div>

                                                <TypographyLabel className="text-system-body">
                                                    {v?.name}
                                                </TypographyLabel>

                                                <TypographyLabel className="!font-label-strong text-system-primary ml-auto">
                                                    {formatChartNumber(JSON.stringify(v?.data[dataPointIndex] || ''))}
                                                </TypographyLabel>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }


                    return renderToStaticMarkup(<ChartTooltip />)
                }
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                hover: {
                    filter: {
                        type: 'none',
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                    }
                },
            }
        }

        if (parsedData.chartType.toLowerCase() === 'stackedbar') {
            return <ResponseStackedBarChart
                id={id}
                parsedData={parsedData}
                series={series}
                options={options}
                compact={compact}
            />
        }

        if (parsedData.chartType.toLowerCase() === 'bar') {
            return <ResponseBarChart
                id={id}
                parsedData={parsedData}
                series={series}
                options={options}
                compact={compact}
            />
        }

        if (parsedData.chartType.toLowerCase() === 'line') {
            return <ResponseLineChart
                id={id}
                parsedData={parsedData}
                series={series}
                options={options}
                compact={compact}
            />
        }

        if (parsedData.chartType.toLowerCase() === 'area') {
            return <ResponseAreaChart
                id={id}
                parsedData={parsedData}
                series={series}
                options={options}
                compact={compact}
            />
        }

        const parsedPieDataValues = parsedData.data.map((v, idx) => {
            const data: { [label: string]: string | number } = {
                [parsedData.label]: v.label,
                fill: getChartColor(idx)
            }

            v.value.forEach((v, idx) => {
                data[parsedData.values[idx]] = v
            })

            return data
        })

        const pieParsedChartConfig: {
            [label: string]: {
                label: string
                color: string
            }
        } = parsedData.data.reduce((a, b, idx) => {
            const newData = { ...a, [b.label]: { label: b.label, color: getChartColor(idx) } }

            return newData
        }, parsedData.values.reduce((a, b) => {
            const newData = { ...a, [b]: { label: b } }

            return newData
        }, {})) satisfies ChartConfig

        if (parsedData.chartType === 'Pie') {
            return <ResponsePieChart
                id={id}
                parsedChartConfig={pieParsedChartConfig}
                parsedData={parsedData}
                parsedDataValues={parsedPieDataValues}
            />
        }

        throw new Error('chart type is invalid')
    } catch {
        return (
            <ResponseChartError />
        )
    }
})

// container is here to prevent chart rerender
export const ResponseChartContainer = memo(({ id, data, compact }: { id: string, data: string, compact?: boolean }) => {
    const chart = useMemo(() => {
        return <ResponseChart id={id} data={data} compact={compact} />
    }, [])

    return chart
})

export const ResponseChartError = () => {
    return (
        <div className="flex flex-col gap-4 p-4 mt-10">
            <CustomAlert
                variant='error'
                title="We could not load the chart at this time"
                description="We have notified the team and will be fixing the issue soon."
            />
        </div>
    )
}
