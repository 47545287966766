const PowerPointFile = ({ className }: { className?: string }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0312 2.3335H9.1875C8.60734 2.3335 8.05094 2.57933 7.6407 3.01691C7.23047 3.4545 7 4.04799 7 4.66683V23.3335C7 23.9523 7.23047 24.5458 7.6407 24.9834C8.05094 25.421 8.60734 25.6668 9.1875 25.6668H22.3125C22.8927 25.6668 23.4491 25.421 23.8593 24.9834C24.2695 24.5458 24.5 23.9523 24.5 23.3335V8.16683L19.0312 2.3335Z" fill="white" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.5 2.3335V7.00016C17.5 7.619 17.7458 8.21249 18.1834 8.65008C18.621 9.08766 19.2145 9.3335 19.8333 9.3335H24.5" fill="white" />
            <path d="M17.5 2.3335V7.00016C17.5 7.619 17.7458 8.21249 18.1834 8.65008C18.621 9.08766 19.2145 9.3335 19.8333 9.3335H24.5" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="2.33325" y="9.3335" width="14" height="14" rx="2" fill="#E05210" />
            <path d="M6.58325 19.986V12.6812H9.46522C10.0193 12.6812 10.4913 12.787 10.8812 12.9986C11.2712 13.2078 11.5684 13.4991 11.7729 13.8725C11.9798 14.2434 12.0833 14.6714 12.0833 15.1565C12.0833 15.6416 11.9786 16.0696 11.7694 16.4406C11.5601 16.8115 11.2569 17.1004 10.8598 17.3073C10.4651 17.5142 9.98716 17.6176 9.42599 17.6176H7.58909V16.3799H9.17631C9.47355 16.3799 9.71847 16.3288 9.91107 16.2265C10.1061 16.1219 10.2511 15.9781 10.3462 15.795C10.4437 15.6095 10.4925 15.3967 10.4925 15.1565C10.4925 14.914 10.4437 14.7023 10.3462 14.5216C10.2511 14.3385 10.1061 14.197 9.91107 14.0972C9.71609 13.9949 9.46879 13.9438 9.16918 13.9438H8.12767V19.986H6.58325Z" fill="white" />
        </svg>
    )
}

export default PowerPointFile