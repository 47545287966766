import { ResponseDocument } from "../../types/types";

export const sortDocumentsFn = (a: ResponseDocument, b: ResponseDocument) => {
  const aProcessing = a.document_is_ready_to_use;
  const bProcessing = b.document_is_ready_to_use;

  // Prioritize resources that are ready to use
  if (aProcessing !== bProcessing) {
    return bProcessing ? -1 : 1;
  }

  // If both have the same processing status, sort by date (newest first)
  const aDate = new Date(a.created_at_desia || a?.document_source_details?.integration_created_at || a.document_created_at);
  const bDate = new Date(b.created_at_desia || b?.document_source_details?.integration_created_at || b.document_created_at);

  return bDate.getTime() - aDate.getTime();
}