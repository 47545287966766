import { Button } from "../ui/button";
import { Switch } from "../ui/switch";
import { useContext, useEffect, useRef, useState } from "react";
import { AssistantAskMode, DossierDetail, QueryStatus, SourceType, StreamStatus } from "../../types/types";
import { SourceSelector } from "./SourceSelector";
import { TypographyBody } from "../ui/Typography";
import LightbulbHead from "@/assets/LightbulbHead";
import Close from "@/assets/Close";
import { useLocation } from "react-router-dom";
import { CustomTooltip } from "../CustomTooltip";
import ArrowRight from "@/assets/ArrowRight";
import { UserContext } from "@/contexts/UserContext";
import texture from '../../assets/bg-texture.png'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, type RootState } from '../../store/store';
import { StopCircle } from "lucide-react";
import { stopConversation } from "./assistantThunk";
import { convertToBullets } from "@/utils/utils";

export function ExpertModeSwitch({ handleCheckedChange, checked, }: { handleCheckedChange: () => void; checked: boolean }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const location = useLocation()

    useEffect(() => {
        const hasShown = localStorage.getItem('expert_mode_tooltip_shown')

        if (hasShown !== 'true') {
            setShowTooltip(true)
        }
    }, [])

    const updateHasShown = () => {
        localStorage.setItem('expert_mode_tooltip_shown', 'true')
    }

    return (
        <>
            <div data-tooltip-id="expert-mode-tooltip" data-tooltip-place="top" className="rounded-md hover:bg-[#F0F4FA] p-2">
                <div className="flex items-center space-x-2">
                    <Switch id="airplane-mode" onCheckedChange={handleCheckedChange} checked={checked} label="Expert mode" containerclassname="text-system-body" labelclassname="!whitespace-nowrap" />
                </div>
            </div>
            {location.pathname === "/assistant/ask" && (
                <CustomTooltip
                    id="expert-mode-tooltip"
                    clickable={true}
                    isOpen={showTooltip}
                    setIsOpen={(v) => setShowTooltip(v)}
                    afterShow={() => updateHasShown()}
                    children={
                        <div className="max-w-[400px] flex flex-col gap-2 px-1">
                            <div className="flex flex-col gap-1">
                                <div className="flex">
                                    <LightbulbHead className="h-10 w-10 shrink-0" />
                                    <div className="ml-auto cursor-pointer" onClick={() => setShowTooltip(false)}>
                                        <Close className="w-6 h-6 shrink-0" />
                                    </div>
                                </div>
                                <TypographyBody isStrong={true} className="text-system-primary">
                                    Try out the new expert mode
                                </TypographyBody>
                            </div>
                            <TypographyBody className="!text-system-body">
                                Activating Expert mode will provide you with more  accurate answers, though you might have to wait a bit longer
                            </TypographyBody>
                        </div>
                    }
                />
            )}
        </>
    )
}

export type ChatBoxProps = {
    expertModeAvailable: boolean;
    handleSubmit: (args: { message: string, mode: AssistantAskMode }) => void;
    initialMessage?: string;
    additionalControls?: boolean;
    isConversation: boolean;
    showBorder: boolean
    dossierDetail?: DossierDetail,
    conversationId?: string
    isFinished?: boolean
    canStop: boolean
    sourceType: SourceType
}
export function ChatBox({ expertModeAvailable, handleSubmit, initialMessage, additionalControls, isConversation, dossierDetail, conversationId, isFinished, canStop, sourceType }: ChatBoxProps) {
    const assistantStore = useSelector((state: RootState) => state.assistant);
    const { settings, updateSettings } = useContext(UserContext);
    const [message, setMessage] = useState(initialMessage || "");
    const [isStopping, setIsStopping] = useState(false);

    const dispatch = useDispatch<AppDispatch>()

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const selectedFiles = settings.assistant.sources[sourceType].files

    const isSourceSelected = settings.assistant.sources[sourceType].internalSearch || settings.assistant.sources[sourceType].webSearch || settings.assistant.sources[sourceType].outlookSearch || settings.assistant.sources[sourceType].teamsSearch

    function sendMessage() {
        if (!isSourceSelected) return
        handleSubmit({ message, mode: settings.assistant.mode });
        setMessage("");
    }

    const toggleExpertMode = () => {
        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    mode: settings.assistant.mode === 'simple' ? 'expert' : 'simple',
                    sources: {
                        ...settings.assistant.sources,
                        [sourceType]: settings.assistant.mode === 'simple' ? {
                            ...settings.assistant.sources[sourceType],
                            internalSearch: true,
                            webSearch: true,
                            focusedAnalysis: settings.assistant.mode === 'simple' ? false : settings.assistant.sources[sourceType].focusedAnalysis
                        } : { ...settings.assistant.sources[sourceType] }
                    },
                }
            }
        })
    }

    const stopMessage = async () => {
        if (!conversationId) return
        setIsStopping(true)
        await dispatch(stopConversation(conversationId))
    }

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "0px";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = scrollHeight + "px";
        }
    }, [message])

    useEffect(() => {
        if (assistantStore.stopConversationStatus === QueryStatus.SUCCEEDED && isFinished) {
            setIsStopping(false)
        }
    }, [assistantStore.stopConversationStatus, isFinished])

    const containerClass = `${isConversation ? `pb-6 bg-system-surface flex` : ""} mx-auto w-full`

    return (
        <div className={containerClass} style={{ backgroundImage: `url(${texture})` }}>
            <div className="chatbox-container flex flex-col gap-3 p-3 pl-4 w-full flex-grow items-center bg-system-secondary border border-system-border-regular rounded-lg">
                <div className="flex flex-1 max-h-[320px] w-full">
                    <textarea className="max-h-[320px] w-full overflow-auto shadow-none outline-none h-fit font-body placeholder:!text-system-placeholder !resize-none"
                        ref={textareaRef}
                        value={convertToBullets(message)}
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.shiftKey !== true) {
                                e.preventDefault();

                                // todo: handle processing states
                                if (assistantStore.state.streamStatus === 'Ready') {
                                    sendMessage();
                                }
                            }
                        }}
                        placeholder="What do you need to know?"
                    />
                </div>
                <div className="flex items-center gap-4 mobile:gap-6 w-full">
                    {additionalControls && (
                        <SourceSelector dossierDetail={dossierDetail} sourceType={sourceType} />
                    )}
                    <div className="flex gap-4 ml-auto">
                        {expertModeAvailable && selectedFiles.length === 0 && (
                            <ExpertModeSwitch checked={settings.assistant.mode === 'expert'} handleCheckedChange={() => toggleExpertMode()} />
                        )}

                        {assistantStore.state.streamStatus === StreamStatus.Ready || isStopping || !canStop ?
                            <Button
                                onClick={sendMessage}
                                disabled={!isSourceSelected || (isStopping && canStop) || assistantStore.state.streamStatus !== StreamStatus.Ready}
                                className="w-[84px]"
                            >
                                <div className="flex gap-2 items-center">
                                    Ask

                                    <ArrowRight className="h-6 w-6 shrink-0 stroke-system-secondary" />
                                </div>
                            </Button>
                            :
                            <Button
                                onClick={stopMessage}
                                className="w-[84px]"
                            >
                                <div className="flex gap-2 items-center">
                                    <StopCircle className="size-6 shrink-0 stroke-[1.5px]" />

                                    Stop
                                </div>
                            </Button>
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}
