import { Socket } from "socket.io-client";
import { retry } from 'ts-retry-promise';
import * as Sentry from "@sentry/react";
import { handleErrorPromise } from "./handleError";

/**
 * This file is to track and debug application events and errors.
 * 
 * Note that naming it something like "eventTracking" leads to adblocks blocking the requests
 * hence the name "monitor".
 */
export async function trackEvent(event: Sentry.Event) {
    return new Promise<void>((resolve, reject) => {
        try {
            Sentry.captureEvent(event)
            console.info(`[desia-web-app] captured event`, event)
            return resolve()
        } catch (error) {
            console.error(`[desia-web-app] failed to capture event`, { event, error })
            return reject(error)
        }
    })
}

export async function trackWebSocketConnection() {
    const event: Sentry.Event = {
        message: 'websocket_connected',
        level: 'debug',
        type: 'transaction',
        transaction: 'websocket',
    }
    try {
        // use retry to handle network issues
        await retry(() => trackEvent(event), { retries: 3, delay: 1000 })
    } catch (error) {
        console.error(`[desia-web-app] failed to track websocket connection`, { event, error })
    }
}

export async function trackWebSocketDisconnection(reason: Socket.DisconnectReason, description?: Record<string, any>) {
    const event: Sentry.Event = {
        message: 'websocket_disconnected',
        level: 'warning',
        type: 'transaction',
        transaction: 'websocket',
        tags: {
            reason,
            ...(description || {}),
        },
    }
    try {
        // use retry to handle network issues
        // unfortunately, we're over Sentry's transaction logging limit so instead of using trackEvent
        // we must handle as an error as opposed to an event
        const sentryErrorEvent: Sentry.ErrorEvent = {
            message: 'websocket_disconnected',
            level: 'debug',
            tags: {
                reason,
                ...(description || {}),
                href: window.location.href,
            },
            type: undefined,
        }
        await retry(() => handleErrorPromise(sentryErrorEvent), { retries: 5, delay: 5_000 })
    } catch (error) {
        console.error(`[desia-web-app] failed to track websocket disconnection`, { event, error })
    }
}
