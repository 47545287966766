import { Loader2 } from "lucide-react";
import { Dialog, DialogBody, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { TypographyBody } from "../ui/Typography";

export const DocGenLoadingTemplateDialog = ({ open, setOpen }: { open: boolean, setOpen: (open: boolean) => void }) => {
    return (
        <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogContent className="w-[493px] rounded-lg">
                <DialogHeader className="!text-center hidden">
                    <DialogTitle asChild className="hidden"><span>Generating template from selected file</span></DialogTitle>
                </DialogHeader>
                <DialogBody className="flex items-center justify-center">
                    <div className="flex gap-4 items-start">
                        <Loader2 className="size-6 shrink-0 stroke-[1.5px] animate-spin mt-0.5" />
                        <div className="flex flex-col gap-1">
                            <TypographyBody isStrong>
                                Generating template from selected file
                            </TypographyBody>

                            <TypographyBody className="text-system-body">
                                This can take up to a minute, depending on the file size
                            </TypographyBody>
                        </div>
                    </div>
                </DialogBody>
            </DialogContent>
        </Dialog>
    )
}