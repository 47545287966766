import { useEffect } from "react"
import { TypographyBody, TypographyH4 } from "../ui/Typography"
import { LOGOUT_PATH, WEB_SERVER_ENDPOINT } from "@/constants";

export const Signout = () => {
    useEffect(() => {
        localStorage.setItem('show_welcome_animation', 'true')
        window.location.href = `${WEB_SERVER_ENDPOINT}${LOGOUT_PATH}`;
    }, [])

    return (
        <div className="flex flex-col gap-2 my-10 mx-auto text-center">
            <TypographyH4>
                You have signed out
            </TypographyH4>

            <TypographyBody className="text-system-body">
                You are now being redirected to the login screen.
            </TypographyBody>
        </div>
    )
}