import { WEB_SERVER_ENDPOINT } from '@/constants'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const documentApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${WEB_SERVER_ENDPOINT}/api`,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    fetchFn: async (url, options) => {
      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error(`error status: ${response.status}`)
      }
      return response
    },
  }),
  endpoints: (builder) => ({
    getResourceData: builder.query({
      query: (fileId: string) => ({
        url: `/document/raw-resource/${fileId}`,
        responseHandler: async (response) =>
          URL.createObjectURL(await response.blob()),
      }),
    }),
  }),
})

export default documentApi
