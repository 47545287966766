const LightbulbHead = ({ className }: { className?: string }) => (
    <svg width="40" height="43" viewBox="0 0 40 43" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
        <path d="M29.4845 35.7654V27.6528C31.2738 25.9952 32.5215 23.8366 33.065 21.4589C33.6085 19.0812 33.4223 16.5948 32.5309 14.3245C31.6393 12.0542 30.084 10.1055 28.0678 8.73285C26.0517 7.36019 23.6686 6.62739 21.2295 6.63013C11.9851 6.63013 9.99486 14.2416 6.69416 22.7905C6.63771 22.9377 6.61787 23.0963 6.63635 23.2529C6.65484 23.4094 6.7111 23.5591 6.80029 23.6891C6.88949 23.819 7.00893 23.9253 7.14835 23.9989C7.28776 24.0724 7.44297 24.111 7.60059 24.1113H10.0609V27.996C10.0609 29.0263 10.4702 30.0143 11.1987 30.7429C11.9272 31.4714 12.9153 31.8807 13.9456 31.8807H15.888V35.7654" stroke="#0F172A" strokeWidth="1.65747" strokeLinecap="round" strokeLinejoin="round" />
        <g clipPath="url(#clip0_1200_722)">
            <path d="M24.5678 16.2513C24.5688 15.6701 24.4223 15.0994 24.144 14.5997C23.8657 14.1 23.4658 13.6899 22.9867 13.4129C22.5077 13.1358 21.9674 13.0021 21.4229 13.0259C20.8785 13.0497 20.3502 13.2301 19.894 13.5481C19.4379 13.866 19.0708 14.3097 18.8318 14.832C18.5927 15.3543 18.4905 15.9359 18.5361 16.5151C18.5816 17.0943 18.7732 17.6496 19.0905 18.1221C19.4077 18.5947 19.8389 18.9669 20.3383 19.1994V20.7628H22.755V19.1994C23.2932 18.9492 23.7513 18.5371 24.0732 18.0135C24.3953 17.4899 24.5672 16.8775 24.5678 16.2513Z" stroke="#0F172A" strokeWidth="1.65747" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.8391 16.8071H29.0474" stroke="#0F172A" strokeWidth="1.65747" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.589 19.7627L28.4428 20.6743" stroke="#0F172A" strokeWidth="1.65747" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M27.589 13.8523L28.4428 12.9407" stroke="#0F172A" strokeWidth="1.65747" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.2542 16.8071H14.0459" stroke="#0F172A" strokeWidth="1.65747" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5043 19.7627L14.6497 20.6743" stroke="#0F172A" strokeWidth="1.65747" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.5043 13.8523L14.6497 12.9407" stroke="#0F172A" strokeWidth="1.65747" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1200_722">
                <rect width="16.5747" height="9.94483" fill="white" transform="translate(13.2595 11.6025)" />
            </clipPath>
        </defs>
    </svg>
)

export default LightbulbHead