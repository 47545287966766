import { LayoutContainer as NewLayout } from "./layout/Layout";

type LayoutProps = {
    component: React.ReactElement
    tall?: boolean
    scrollable?: boolean
}

export function Layout(props: LayoutProps) {
    return (
        <NewLayout component={props.component} tall={props.tall} scrollable={props.scrollable}/>
    )
}