const ImageFile = ({ className }: { className?: string }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <path d="M17.6875 2.5H8.125C7.56141 2.5 7.02091 2.74232 6.6224 3.17365C6.22388 3.60499 6 4.19 6 4.8V23.2C6 23.81 6.22388 24.395 6.6224 24.8263C7.02091 25.2577 7.56141 25.5 8.125 25.5H20.875C21.4386 25.5 21.9791 25.2577 22.3776 24.8263C22.7761 24.395 23 23.81 23 23.2V8.25L17.6875 2.5Z" fill="white" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 17C13.1046 17 14 16.1046 14 15C14 13.8954 13.1046 13 12 13C10.8954 13 10 13.8954 10 15C10 16.1046 10.8954 17 12 17Z" fill="#16BD00" />
            <path d="M20.9997 25.5C20.9997 25.5 21.9997 24.9277 22.4997 24.5C22.9997 24.0723 22.9997 23 22.9997 23V20L21.5854 18.7056C21.0924 18.2538 20.4237 18 19.7265 18C19.0293 18 18.3606 18.2538 17.8676 18.7056C17.8676 18.7056 13.1093 23.1086 10.4999 25.5C10.4263 25.5676 21.3153 25.2108 20.9997 25.5Z" fill="#16BD00" />
            <path d="M13.72 25.4942C13.72 25.4942 14.36 25.1131 14.68 24.8283C15 24.5435 15 23.8295 15 23.8295V21.8318L14.0949 20.9698C13.7793 20.669 13.3514 20.5 12.9052 20.5C12.459 20.5 12.031 20.669 11.7155 20.9698C11.7155 20.9698 8.67023 23.9018 7.00024 25.4942C6.95313 25.5392 13.922 25.3016 13.72 25.4942Z" fill="#16BD00" />
            <path d="M17.6875 2.5H8.125C7.56141 2.5 7.02091 2.74232 6.6224 3.17365C6.22388 3.60499 6 4.19 6 4.8V23.2C6 23.81 6.22388 24.395 6.6224 24.8263C7.02091 25.2577 7.56141 25.5 8.125 25.5H20.875C21.4386 25.5 21.9791 25.2577 22.3776 24.8263C22.7761 24.395 23 23.81 23 23.2V8.25L17.6875 2.5Z" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 2.5V7.16667C16 7.7855 16.2458 8.379 16.6834 8.81658C17.121 9.25417 17.7145 9.5 18.3333 9.5H23" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ImageFile