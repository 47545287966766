import { Plus, Sparkles } from "lucide-react";
import { Button } from "../ui/button";
import { TypographyH3 } from "../ui/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { useEffect, useState } from "react";
import { requestCreateReport, requestReports, resetCreateReportData } from "../DocGen/docGenSlice";
import { getTimestamp } from "@/utils/utils";
import { DocgenSession, DocgenStep, QueryStatus, ResponseDocGenReport } from "@/types/types";
import { ErrorMessage } from "../ErrorMessage";

import { ReportList } from "./ReportList";
import { Input } from "../ui/input";
import { useNavigate } from "react-router-dom";

import DocGenHomeImage from '../../assets/img-home-doc-gen.svg'
import { NotAvailableDialog } from "../Resources/NotAvailableDialog";
import { DocGenWalkthroughDialog } from "../DocGen/DocGenWalkthroughDialog";
import { fetchSessions, fetchTemplates, fetchThemes } from "../DocGen/docGenThunk";
import { SessionCard } from "./SessionCard";

export interface MockReport {
    title: string
    description: string
}

// todo: implement pagination
export function ReportsPage({ reports, sessions, error, loading, createReportLoading, createReportError, handleCreateReport }: {
    reports: ResponseDocGenReport[],
    sessions: DocgenSession[],
    loading: boolean,
    error: any,
    createReportLoading?: boolean,
    createReportError?: string | null,
    handleCreateReport: () => void,
}) {
    const [searchText, setSearchText] = useState('')
    const [showNotAvailableDialog, setShowNotAvailableDialog] = useState(false)
    const [showDocGenWalkthrough, setShowDocGenWalkthrough] = useState(false)

    const navigate = useNavigate()

    const filteredReports = reports.filter((v) => {
        if (!searchText) { return true }
        const splitText = searchText.toLowerCase().split(' ')
        return splitText.some((text) => v.title.toLowerCase().includes(text)) && v.content !== ''
    })

    return (
        <div className="flex flex-col gap-0 mt-10 sm:mt-0 tablet:max-w-[956px] laptop:max-w-[1440px] mx-auto">
            <>
                <div className="text-center mx-auto">
                    <div className="flex items-center h-full mx-auto">
                        <img className="w-[384px] h-[212px]" src={DocGenHomeImage} />
                    </div>

                    <TypographyH3 className="-translate-y-[100%]">Reports</TypographyH3>
                </div>

                <div className="flex flex-col gap-6 w-[360px] mx-auto mb-14">
                    <div className={`flex flex-wrap justify-center gap-2 mx-auto w-full`}>
                        <Input
                            className="w-[360px]"
                            placeholder="Search by report name"
                            isSearch={true}
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value)
                            }}
                            isCloseVisible={!!searchText}
                            onCloseClick={() => setSearchText('')}
                        />
                    </div>

                    <div className={`flex justify-center gap-2 mx-auto w-full`}>
                        <Button
                            className="w-full"
                            onClick={() => {
                                const hideDocGenWalkthrough = localStorage.getItem('hide_docgen_walkthrough')

                                if (hideDocGenWalkthrough !== 'true') {
                                    setShowDocGenWalkthrough(true)
                                } else {
                                    navigate(`/docgen?step=${DocgenStep.TEMPLATE}`)
                                }
                            }}>
                            <div className="flex gap-2">
                                <Sparkles className="size-6 shrink-0 stroke-[1.5px]" />
                                Generate report
                            </div>
                        </Button>

                        <Button disabled={createReportLoading} onClick={handleCreateReport} variant='secondary' className="w-full">
                            <div className="flex gap-2">
                                <Plus className="size-6 shrink-0 stroke-[1.5px]" />
                                Blank report
                            </div>
                        </Button>
                    </div>
                </div>
            </>

            <div className="flex flex-col gap-6">
                {createReportError && (
                    <ErrorMessage message="We failed to create a new report, try again shortly" />
                )}

                {sessions.length > 0 && (
                    <div className="grid grid-cols-2 laptop:grid-cols-3 gap-6 mb-8">
                        {sessions.map((session, index) => {
                            return (
                                <SessionCard key={`session-card-${session.id}-${index}`} sessionId={session.id} />
                            )
                        })}
                    </div>
                )}

                <ReportList reports={filteredReports} loading={loading} error={error} type='report' />
            </div>

            <NotAvailableDialog open={showNotAvailableDialog} setOpen={(v) => setShowNotAvailableDialog(v)} variant='docgen' />
            <DocGenWalkthroughDialog
                open={showDocGenWalkthrough}
                setOpen={(v) => {
                    setShowDocGenWalkthrough(v)
                    navigate(`/docgen?step=${DocgenStep.TEMPLATE}`)
                }} />
        </div>
    )
}

export function ReportsPageContainer() {
    const reports = useSelector((state: RootState) => state.docGen.reports);
    const createReport = useSelector((state: RootState) => state.docGen.createReport);
    const sessions = useSelector((state: RootState) => state.docGen.sessions);
    const templateListStatus = useSelector((state: RootState) => state.docGen.templateListStatus);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const createReportHandler = async () => {
        dispatch(requestCreateReport({
            requestId: "docgen:create_report",
            params: {},
            timestamp: getTimestamp(),
        }))
    }

    useEffect(() => {
        dispatch(requestReports({
            requestId: "docgen:list_reports_20_0",
            params: {
                limit: 20,
                offset: 0,
            },
            timestamp: getTimestamp(),
        }));

        dispatch(fetchSessions())
        dispatch(fetchThemes())
    }, [dispatch])

    useEffect(() => {
        if (createReport.data) {
            navigate(`/report/${createReport.data.id}`)

            dispatch(resetCreateReportData())
        }
    }, [createReport.data, dispatch, navigate])

    useEffect(() => {
        if (templateListStatus === QueryStatus.INITIALISED) {
            dispatch(fetchTemplates())
        }
    }, [templateListStatus, dispatch])

    useEffect(() => {
        document.title = "Desia AI - Reports"
    }, [])

    return (
        <>
            <ReportsPage
                reports={reports.data || []}
                sessions={sessions.data || []}
                loading={reports.loading}
                error={reports.error}
                createReportLoading={createReport.loading}
                createReportError={createReport.error}
                handleCreateReport={createReportHandler}
            />
        </>
    )
}
