const AudioFile = ({ className }: { className?: string }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.8097 2.3335H8.01098C7.43348 2.3335 6.87963 2.57933 6.47127 3.01691C6.06291 3.4545 5.8335 4.04799 5.8335 4.66683V23.3335C5.8335 23.9523 6.06291 24.5458 6.47127 24.9834C6.87963 25.421 7.43348 25.6668 8.01098 25.6668H21.0759C21.6534 25.6668 22.2073 25.421 22.6156 24.9834C23.024 24.5458 23.2534 23.9523 23.2534 23.3335V8.16683L17.8097 2.3335Z" fill="white" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.2854 2.3335V7.00016C16.2854 7.619 16.5301 8.21249 16.9657 8.65008C17.4013 9.08766 17.992 9.3335 18.6081 9.3335H23.2534" fill="white" />
            <path d="M16.2854 2.3335V7.00016C16.2854 7.619 16.5301 8.21249 16.9657 8.65008C17.4013 9.08766 17.992 9.3335 18.6081 9.3335H23.2534" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 19.5C10 19.058 10.158 18.634 10.4393 18.3215C10.7206 18.0089 11.1022 17.8333 11.5 17.8333C11.8978 17.8333 12.2794 18.0089 12.5607 18.3215C12.842 18.634 13 19.058 13 19.5V20.3333C13 20.7754 12.842 21.1993 12.5607 21.5118C12.2794 21.8244 11.8978 22 11.5 22C11.1022 22 10.7206 21.8244 10.4393 21.5118C10.158 21.1993 10 20.7754 10 20.3333V17C10 15.6739 10.4741 14.4021 11.318 13.4645C12.1619 12.5268 13.3065 12 14.5 12C15.6935 12 16.8381 12.5268 17.682 13.4645C18.5259 14.4021 19 15.6739 19 17V20.3333C19 20.7754 18.842 21.1993 18.5607 21.5118C18.2794 21.8244 17.8978 22 17.5 22C17.1022 22 16.7206 21.8244 16.4393 21.5118C16.158 21.1993 16 20.7754 16 20.3333V19.5C16 19.058 16.158 18.634 16.4393 18.3215C16.7206 18.0089 17.1022 17.8333 17.5 17.8333C17.8978 17.8333 18.2794 18.0089 18.5607 18.3215C18.842 18.634 19 19.058 19 19.5" stroke="#323232" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default AudioFile