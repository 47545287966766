import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/shadcn/utils"

const badgeVariants = cva(
  "inline-flex items-center rounded-full font-label px-3 py-px transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 h-fit bg-badge-blue-bg text-badge-blue-graphic",
  {
    variants: {
      variant: {
        blue: "bg-badge-blue-bg text-badge-blue-graphic",
        green: "bg-badge-green-bg text-badge-green-graphic",
        red: "bg-badge-red-bg text-badge-red-graphic",
        orange: "bg-badge-orange-bg text-badge-orange-graphic",
        purple: "bg-badge-purple-bg text-badge-purple-graphic",
        teal: "bg-badge-teal-bg text-badge-teal-graphic",
      },
    },
    defaultVariants: {
      variant: "blue",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof badgeVariants> { }

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
