const Stack = ({ className }: { className?: string }) => {
    const style = `stroke-system-primary ${className}`
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={style} xmlns="http://www.w3.org/2000/svg">
            <path d="M12.83 2.18001C12.5694 2.06115 12.2864 1.99965 12 1.99965C11.7136 1.99965 11.4306 2.06115 11.17 2.18001L2.59999 6.08001C2.42254 6.15825 2.27167 6.2864 2.16576 6.44886C2.05984 6.61132 2.00345 6.80107 2.00345 6.99501C2.00345 7.18894 2.05984 7.37869 2.16576 7.54115C2.27167 7.70361 2.42254 7.83176 2.59999 7.91001L11.18 11.82C11.4406 11.9389 11.7236 12.0004 12.01 12.0004C12.2964 12.0004 12.5794 11.9389 12.84 11.82L21.42 7.92001C21.5974 7.84176 21.7483 7.71361 21.8542 7.55115C21.9601 7.38869 22.0165 7.19894 22.0165 7.00501C22.0165 6.81107 21.9601 6.62132 21.8542 6.45886C21.7483 6.2964 21.5974 6.16825 21.42 6.09001L12.83 2.18001Z" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 17.65L12.83 21.81C12.5694 21.9288 12.2864 21.9904 12 21.9904C11.7136 21.9904 11.4306 21.9288 11.17 21.81L2 17.65" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 12.65L12.83 16.81C12.5694 16.9288 12.2864 16.9904 12 16.9904C11.7136 16.9904 11.4306 16.9288 11.17 16.81L2 12.65" stroke="current" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default Stack