import { FileStructure, ResponseDocument } from "@/types/types";
import { convertUUIDToNumber, getCreatedAt, getUpdatedAt } from "./utils";

export const sortFileStructures = (a: FileStructure, b: FileStructure, resources: ResponseDocument[]) => {
    const aType = a.element_type
    const bType = b.element_type

    const aDocument = resources.find((v) => v.document_id === a.document_id)
    const bDocument = resources.find((v) => v.document_id === b.document_id)

    const aVisibility = aDocument?.document_visibility
    const bVisibility = bDocument?.document_visibility

    const aDesiaLibrary = aDocument?.document_is_part_of_desia_library
    const bDesiaLibrary = bDocument?.document_is_part_of_desia_library

    // prioritize folders
    if (aType === 'file' && bType !== 'file') return 1
    if (aType !== 'file' && bType === 'file') return -1

    // prioritize private
    if (aVisibility === 'private' && bVisibility !== 'private') return -1
    if (aVisibility !== 'private' && bVisibility === 'private') return 1

    // deprioritize desia library
    if (aDesiaLibrary === true && bDesiaLibrary !== true) return 1
    if (aDesiaLibrary !== true && bDesiaLibrary === true) return -1

    // alphabetical sort
    return a.element_name.localeCompare(b.element_name)
}

export const getAllFileChildren = (data: FileStructure[], parentId: number) => {
    const parent = data.find(item => item.internal_element_id === parentId);
    if (!parent) return [];
    let children: FileStructure[] = [];
    parent.children_element_internal_ids?.forEach(childId => {
        const child = data.find(item => item.internal_element_id === childId && item.parent_element_internal_ids.length > 0);

        if (child) {
            children.push(child);
            children = [...children, ...getAllFileChildren(data, childId)];
        }
    });

    return children;
}

export const getFileChildrenLatestDate = (r: ResponseDocument[]): Date | null => {
    const lastUpdated = r.reduce((latest, current) => {
        const currentDate = getUpdatedAt(current) || getCreatedAt(current)
        const latestDate = getUpdatedAt(latest) || getCreatedAt(latest)

        if (!latestDate) return current
        if (!currentDate) return latest

        return currentDate > latestDate ? current : latest;
    }, r[0])

    return getUpdatedAt(lastUpdated) || getCreatedAt(lastUpdated)
}

export const reduceResponseDocuments = (resources: ResponseDocument[]): FileStructure[] => {
    const elements: FileStructure[] = []

    resources.forEach((r) => {
        (r.document_source_path_treeview || []).forEach((e) => {
            if (!elements.find((v) => v.internal_element_id === e.internal_element_id)) {
                elements.push({
                    internal_element_id: e.internal_element_id,
                    document_id: e.element_type === 'file' ? r.document_id : undefined,
                    element_type: e.element_type,
                    element_name: e.element_name,
                    parent_element_internal_ids: e.parent_element_internal_ids,
                    children_element_internal_ids: e.children_element_internal_ids,
                    is_included: false,
                    child_is_included: false,
                    is_excluded: false,
                    child_is_excluded: false
                } as FileStructure)
            }
        })
    })

    return elements
}

export const transformResponseDocuments = (resources: ResponseDocument[]) => {
    const elements = reduceResponseDocuments(resources)

    const mappedResources = resources.filter((v) => (v.document_source_path_treeview || []).length === 0).map((r) => {
        return {
            internal_element_id: convertUUIDToNumber(r.document_id || ''),
            document_id: r.document_id,
            element_type: 'file',
            element_name: r.document_name,
            element_path: r.document_source_path,
            parent_element_internal_ids: [],
            children_element_internal_ids: [],
            is_included: false,
            child_is_included: false,
            is_excluded: false,
            child_is_excluded: false
        } as FileStructure
    })

    const combinedElements = [...elements, ...mappedResources]

    const sortedElements = combinedElements.sort((a: FileStructure, b: FileStructure) => {
        return sortFileStructures(a, b, resources)
    })

    return sortedElements
}