import { secondaryStyle } from "../ui/button"
import { Card } from "../ui/card"
import { TypographyLabel } from "../ui/Typography"
import { ResponseDocGenReport } from "@/types/types"
import { isToday, isYesterday, formatDate } from 'date-fns'
import { useNavigate } from "react-router-dom"
import Divider from "../ui/divider"
import ReportThumbnailImage from '@/assets/img-report-thumbnail.png'
import { ReportDropdownMenu } from "./ReportDropdownMenu"
import { getDossierIcon } from "@/utils/components"
import { Truncate } from "@re-dev/react-truncate"
import { useSelector } from "react-redux"
import { RootState } from "@/store/store"

export const ReportCard = ({ report, type = 'report' }: { report: ResponseDocGenReport, type: 'report' | 'dossier' }) => {
    const dossiers = useSelector((state: RootState) => state.dossier.dossiers)
    const dossier = dossiers.data?.find((v) => v.id === report.dossier_id)

    const navigate = useNavigate();

    return (
        <>
            <Card className={`${secondaryStyle} p-4 shadow-none !rounded-md [&:not(:active)]:border-system-border-light [&:hover:not(:has(.child-hover:hover))]:!bg-system-hover hover:bg-system-secondary`} onClick={() => {
                navigate(dossier ? `/dossier/${dossier.id}/report/${report.id}` : `/report/${report.id}`)
            }}>
                <div className="flex flex-col gap-6 h-full">
                    <div className="flex flex-col gap-3">
                        <div className="flex items-center">
                            <TypographyLabel className="text-system-body">
                                {`Updated ${isToday(report.updated_at) ? 'today' : isYesterday(report.updated_at) ? 'yesterday' : ` on ${formatDate(report.updated_at, 'd MMM')}`}`}
                            </TypographyLabel>
                        </div>

                        <Divider className="bg-system-border-light" />

                        <div className="flex gap-4 items-center">
                            <img className="h-10" src={ReportThumbnailImage} />

                            <div className="flex flex-col gap-0.5 w-[calc(100%-100px)]">
                                <Truncate className="font-body-strong w-fit">
                                    {report.title || 'Untitled'}
                                </Truncate>

                                {dossier && type !== 'dossier' && (
                                    <div className="flex gap-2 items-center" onClick={(e) => {
                                        e.stopPropagation()
                                        navigate(`/dossier/${dossier.id}`)
                                    }}>
                                        <div className="p-1 rounded-[2px] border-[0.3px] border-system-border-regular bg-system-surface-light w-fit h-fit shrink-0">
                                            {getDossierIcon(dossier.icon, '!size-2')}
                                        </div>

                                        <TypographyLabel className="text-system-body hover:underline cursor-pointer decoration-system-body overflow-hidden whitespace-nowrap text-ellipsis">
                                            {dossier.subject}
                                        </TypographyLabel>
                                    </div>
                                )}
                            </div>

                            <ReportDropdownMenu reportId={report.id} reportTitle={report.title} />
                        </div>
                    </div>
                </div>
            </Card>
        </>

    )
}