import { Dialog, DialogBody, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { TypographyBody, TypographyH3, TypographyH4 } from "../ui/Typography";
import { Button } from "../ui/button";
import DocGenWalkthroughImage from '../../assets/img-report-walkthrough-docgen.svg'
import ArrowRight from "@/assets/ArrowRight";
import { useState } from "react";
import { Checkbox } from "../ui/checkbox";
import { useNavigate } from "react-router-dom";
import { ProgressTracker } from "./ProgressTracker";
import { DocgenStep } from "@/types/types";

export function DocGenWalkthroughDialog({ open, setOpen }: { open?: boolean, setOpen: (v: boolean) => void }) {
    const [checked, setChecked] = useState(false)
    const navigate = useNavigate()

    return (
        <Dialog open={open} onOpenChange={(v) => {
            setOpen(v)

            if (checked) {
                localStorage.setItem('hide_docgen_walkthrough', 'true')
            }
        }}>
            <DialogContent className="min-w-[1056px] max-w-[1056px] py-6 px-10">
                <DialogHeader>
                    <DialogTitle className="absolute right-6 top-6"></DialogTitle>
                    <div className="flex items-center mx-auto w-[384px] h-[124px]">
                        <img className="absolute w-[384px] h-[212px] top-6" src={DocGenWalkthroughImage} />
                    </div>

                    <div className="flex flex-col gap-2 text-center z-[1]">
                        <TypographyH3>Generate report</TypographyH3>
                        <TypographyBody className="text-system-body whitespace-pre-wrap">
                            {'You can now generate an investment memo,\nmarket overview or more in a matter of minutes.'}
                        </TypographyBody>
                    </div>
                </DialogHeader>
                <DialogBody>
                    <div className="flex flex-col gap-12 pb-4">
                        <TypographyH4>
                            How does it work?
                        </TypographyH4>

                        <div className="pb-10">
                            <ProgressTracker
                                labels={[
                                    'Select report template',
                                    'Define topic and sources',
                                    'Review outline',
                                    'Review draft'
                                ]}
                                verticalLabels
                                currentLabels={[
                                    'Select report template',
                                    'Define topic and sources',
                                    'Review outline',
                                    'Review draft'
                                ]}
                                completedLabels={[]}
                                largeIcon
                            />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className="pt-0 !mt-0">
                    <div className="flex items-center pr-14">
                        <Checkbox
                            checked={checked}
                            onCheckedChange={() => setChecked(!checked)}
                            label="Don’t show me this again"
                        />
                    </div>
                    <DialogClose asChild>
                        <Button variant='secondary'>
                            Cancel
                        </Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button onClick={() => navigate(`/docgen?step=${DocgenStep.TEMPLATE}`)}>
                            <div className="flex gap-2">
                                Get started
                                <ArrowRight />
                            </div>
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
