import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { Download } from "lucide-react";
import ChevronDown from "@/assets/ChevronDown";
import { Button } from "../ui/button";
import { RefObject, useCallback, useEffect, useState } from "react";
import { TypographyBody } from "../ui/Typography";
import { toPng } from 'html-to-image';
import { ResponseChartData } from "@/types/types";
import { handleError } from "@/utils/handleError";
import { removeDelimiterFromString } from "@/utils/utils";

export const ChartHeader = ({ chartRef, parsedData, compact }: { chartRef: RefObject<HTMLDivElement>, parsedData: ResponseChartData, compact?: boolean }) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const [showButton, setShowButton] = useState(false)

    const exportChartAsPNG = useCallback(() => {
        if (chartRef.current === null) {
            return
        }

        toPng(chartRef.current, { cacheBust: true, backgroundColor: 'white', skipFonts: true })
            .then((dataUrl: string) => {
                const link = document.createElement('a')
                link.download = `${parsedData.title}.png`
                link.href = dataUrl
                link.click()

                setOpenDropdown(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [chartRef, parsedData])

    const exportChartAsCSV = () => {
        const delimiter = ','
        try {
            let csvString = ""

            if (parsedData.chartType === 'Pie') {
                const headerString = ['', parsedData.data.map((v) => removeDelimiterFromString(v.label)).join(delimiter)].join(delimiter)
                const dataString = [
                    parsedData.values.map((v) => removeDelimiterFromString(v)),
                    parsedData.data.map((v) => [v.value]).join(delimiter)
                ]
                    .join(delimiter);

                csvString = [headerString, dataString].join('\n')
            } else {
                const headerString = [parsedData.label, ...parsedData.values].map((v) => removeDelimiterFromString(v)).join(delimiter)
                const dataString = [
                    parsedData.data.map((v) => [removeDelimiterFromString(v.label), v.value.join(delimiter)].join(delimiter))
                ]
                    .map(row => row?.join("\n"))
                    .join(delimiter)

                csvString = [headerString, dataString].join('\n')
            }

            const blob = new Blob([csvString], { type: 'text/csv' })

            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = `${parsedData.title}.csv`
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(url)

            setOpenDropdown(false)
        } catch (e) {
            handleError(e)
        }
    };

    useEffect(() => {
        if (openDropdown) {
            setShowButton(true)
        } else {
            setTimeout(() => {
                setShowButton(false)
            }, 100)
        }
    }, [openDropdown])

    return (
        <div className="flex gap-2">
            <TypographyBody isStrong={true} className={`text-system-primary ${compact && '!font-label-strong'}`}>
                {parsedData.title}
            </TypographyBody>

            <div className="ml-auto h-10">
                <DropdownMenu open={openDropdown} onOpenChange={(v) => setOpenDropdown(v)}>
                    <DropdownMenuTrigger className="items-center" asChild>
                        <Button variant='secondary' className={`px-3 py-2 ${showButton ? 'flex' : 'hidden'} group-hover:!flex`}>
                            <div className="flex gap-1.5 items-center">
                                <Download className="size-6 shrink-0 stroke-[1.5px]" />

                                <ChevronDown />
                            </div>
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[80px]">
                        <DropdownMenuItem
                            onClick={() => exportChartAsPNG()}>
                            PNG
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={() => exportChartAsCSV()}>
                            CSV
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </div>
    )
}