import loadingGif from '@/assets/gif-desia-logo-animation.gif'
import texture from '../../assets/bg-texture.png'
import { useContext, useEffect, useState } from 'react'
import { LayoutContext } from '@/contexts/LayoutContext'

export const LoadingAnimation = () => {
    const { showWelcomeAnimation, toggleShowWelcomeAnimation } = useContext(LayoutContext)

    const [showAnimation, setShowAnimation] = useState(Boolean(localStorage.getItem('show_welcome_animation')))
    const [gifSrc, setGifSrc] = useState<string>(loadingGif)

    useEffect(() => {
        finishAnimation()
    }, [])

    useEffect(() => {
        if (showWelcomeAnimation) {
            setGifSrc('')
            setTimeout(() => {
                setGifSrc(loadingGif)
                setShowAnimation(true)
                finishAnimation()
            }, 0)
        }
    }, [showWelcomeAnimation])

    const finishAnimation = () => {
        setTimeout(() => {
            setShowAnimation(false)
            toggleShowWelcomeAnimation(false)
        }, 3000)

        localStorage.removeItem('show_welcome_animation')
    }

    return (
        <div className={`fixed top-0 left-0 h-screen w-screen bg-system-surface z-[100] justify-center flex items-center ${showAnimation ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity ease-in-out duration-500`} style={{ backgroundImage: `url(${texture})` }}>
            <img src={gifSrc || ''} className="" alt='Loading...' />
        </div>
    )
}