import { ReactNode, useEffect } from "react";
import { pdfjs } from "react-pdf";

export const PDFWorker = ({ children }: { children: ReactNode }) => {
    // fixes error when switching between documents if its not loaded, solution based on https://github.com/Sinaptik-AI/panda-etl/blob/c2d7bed0c02fc5616c53ebb185a51e1d5aead53f/frontend/src/ee/components/HighlightPdfViewer.tsx
    useEffect(() => {
        if (typeof window !== "undefined") {
            // Polyfill for Promise.withResolvers
             // @ts-expect-error
            if (typeof Promise.withResolvers === "undefined") {
                // @ts-expect-error This does not exist outside of polyfill which this is doing
                window.Promise.withResolvers = function () {
                    let resolve, reject;
                    const promise = new Promise((res, rej) => {
                        resolve = res;
                        reject = rej;
                    });
                    return { promise, resolve, reject };
                };
            }

            pdfjs.GlobalWorkerOptions.workerSrc = new URL(
                'pdfjs-dist/build/pdf.worker.min.mjs',
                import.meta.url,
            ).toString()
        }
    }, []);

    return children
}