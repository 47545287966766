import { TypographyBody, TypographyLabel } from "../ui/Typography"
import { EllipsisVertical, GripVertical, X } from "lucide-react"
import { TemplateSection, TemplateSubsection } from "@/types/types"
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DropResult } from "@hello-pangea/dnd"
import { Button } from "../ui/button"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu"
import Checkmark from "@/assets/Checkmark"
import { CustomAlert } from "../CustomAlert"
import { getAllSectionTitles } from "@/utils/utils"

export const TemplateSubsectionRow = ({ section, sections, subsection, indexes, provided, snapshot, onSubsectionUpdate, onRemove }: { section: TemplateSubsection, sections: TemplateSection[], subsection: TemplateSubsection, indexes: number[], provided: DraggableProvided, snapshot: DraggableStateSnapshot, onSubsectionUpdate: (section: TemplateSubsection) => void, onRemove: ({ section, subsection, indexes }: { section?: TemplateSection, subsection?: TemplateSubsection, indexes?: number[] }) => void }) => {
    const isThirdLevel = indexes.length >= 3

    const [hover, setHover] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const [editingTitle, setEditingTitle] = useState(false)
    const [initialLoad, setInitialLoad] = useState(true)
    const [title, setTitle] = useState(subsection.title)
    const [duplicateTitleError, setDuplicateTitleError] = useState(false)
    const [emptyTitleError, setEmptyTitleError] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null)

    const reorder = (list: TemplateSubsection[], startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        if (isThirdLevel) {
            const subsections = subsection.subsections
            if (!subsections) return

            const items = reorder(
                subsections,
                result.source.index,
                result.destination.index
            );

            const copy = [...section.subsections || []]

            if (indexes.at(-1) === undefined) return

            copy[indexes.at(-1) || 0] = {
                ...subsection,
                subsections: items
            }

            onSubsectionUpdate({
                ...section,
                subsections: copy
            })
        } else {
            const subsections = section.subsections
            if (!subsections) return

            const items = reorder(
                subsections,
                result.source.index,
                result.destination.index
            );

            onSubsectionUpdate({
                ...section,
                subsections: items
            })
        }
    }

    const handleAddNewSubsection = () => {
        const id = Math.max(...(section.subsections || []).map((v) => v.id)) + 1
        onSubsectionUpdate({
            ...section,
            subsections: [
                {
                    id: id,
                    title: '',
                },
                ...section.subsections || [],
            ]
        })
    }

    useEffect(() => {
        if (subsection.title === '' && indexes.at(-1) === 0 && initialLoad) {
            setEditingTitle(true)
        }

        setInitialLoad(false)
    }, [subsection])

    useEffect(() => {
        if (editingTitle) {
            setTimeout(() => {
                inputRef.current?.focus()
            }, 200)
        }
    }, [editingTitle])

    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`relative flex gap-3 ${isThirdLevel ? 'px-1 py-1' : 'px-2 py-2'} items-center border border-transparent ${snapshot.isDragging ? `${isThirdLevel ? 'bg-system-hover' : 'bg-system-surface-light'}  !border-system-border-light` : !isThirdLevel ? 'bg-system-secondary' : ''} rounded-lg ${isThirdLevel ? 'hover:bg-system-hover' : 'hover:bg-system-surface-light'} ${editingTitle ? '' : 'hover:!border-system-border-light'} ${hover ? 'section-hover' : ''}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className="flex flex-col gap-2 w-full">
                {duplicateTitleError && (
                    <CustomAlert
                        className="py-1.5 rounded-sm"
                        variant='error'
                        description="This name is already used by another section or subsection. Please enter a different name."
                    />
                )}

                {emptyTitleError && (
                    <CustomAlert
                        className="py-1.5 rounded-sm"
                        variant='error'
                        description="Please enter a title"
                    />
                )}

                <div className="flex gap-2 w-full">
                    <Button variant='tertiary'  {...provided.dragHandleProps} className={`${(hover || snapshot.isDragging) && !editingTitle ? 'opacity-100' : 'opacity-0'} h-fit`}>
                        <GripVertical className="size-6 shrink-0 stroke-[1.5px]" />
                    </Button>

                    <div className={`flex gap-3 items-center w-full ${isThirdLevel ? 'ml-1' : ''}`}>
                        <TypographyLabel className={`font-label-strong text-system-border-regular ${editingTitle ? 'opacity-0' : ''}`}>
                            {`${indexes[0] + 1}.${indexes[1] + 1}${isThirdLevel ? `.${indexes[2] + 1}` : ''}`}
                        </TypographyLabel>

                        {isThirdLevel ?
                            <TypographyLabel className="!font-label-strong">
                                {subsection.title}
                            </TypographyLabel>
                            :
                            <TypographyBody isStrong>
                                {subsection.title}
                            </TypographyBody>
                        }
                    </div>

                    <DropdownMenu onOpenChange={(v) => {
                        if (v) {
                            setDropdownOpen(v)
                        } else {
                            setTimeout(() => {
                                setDropdownOpen(v)
                            }, 100)
                        }
                    }}>
                        <DropdownMenuTrigger className="flex items-center" asChild>
                            <Button variant='tertiary' className={`${(hover || snapshot.isDragging || dropdownOpen) && !editingTitle ? '' : 'hidden'}`}>
                                <EllipsisVertical className={`size-6 shrink-0 stroke-[1.5px]`} />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="w-[193px]">
                            {!isThirdLevel && (
                                <DropdownMenuItem
                                    variant='tertiary'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleAddNewSubsection()
                                    }}>
                                    Add subsection
                                </DropdownMenuItem>
                            )}

                            <DropdownMenuItem
                                variant='tertiary'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setTimeout(() => {
                                        setEditingTitle(true)
                                    }, 100)
                                }}>
                                Rename
                            </DropdownMenuItem>

                            <DropdownMenuItem
                                variant='tertiary-destructive'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setEditingTitle(false)
                                    onRemove({ subsection: subsection, indexes })
                                }}>
                                Remove
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>

                </div>

                {editingTitle && (
                    <div className={`absolute top-0 left-0 flex flex-col gap-2 w-full ${isThirdLevel ? 'px-1 py-1' : 'px-2 py-2'} bg-system-surface inner-border inner-border-system-border-regular rounded-lg`}>
                        {duplicateTitleError && (
                            <CustomAlert
                                className="py-1.5 rounded-sm"
                                variant='error'
                                description="This name is already used by another section or subsection. Please enter a different name."
                            />
                        )}

                        {emptyTitleError && (
                            <CustomAlert
                                className="py-1.5 rounded-sm"
                                variant='error'
                                description="Please enter a title"
                            />
                        )}

                        <div className="flex gap-2">
                            <Button variant='tertiary'  {...provided.dragHandleProps} className={`${(hover || snapshot.isDragging) && !editingTitle ? 'opacity-100' : 'opacity-0'} h-fit`}>
                                <GripVertical className="size-6 shrink-0 stroke-[1.5px]" />
                            </Button>

                            <div className={`flex gap-3 items-center w-full ${isThirdLevel ? 'ml-1' : ''}`}>
                                <TypographyLabel className={`font-label-strong text-system-border-regular ${editingTitle ? 'opacity-0' : ''}`}>
                                    {`${indexes[0] + 1}.${indexes[1] + 1}${isThirdLevel ? `.${indexes[2] + 1}` : ''}`}
                                </TypographyLabel>

                                {isThirdLevel ?
                                    <input
                                        ref={inputRef}
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="font-label-strong text-system-primary focus:outline-none w-full placeholder:text-system-placeholder"
                                        placeholder="Subsection title"
                                    />
                                    :
                                    <input
                                        ref={inputRef}
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="font-body-strong text-system-primary focus:outline-none w-full placeholder:text-system-placeholder"
                                        placeholder="Subsection title"
                                    />
                                }

                                {editingTitle && (
                                    <div className="flex gap-2 items-center ml-auto">
                                        <Button
                                            variant='tertiary'
                                            className="h-fit"
                                            onClick={() => {
                                                if (title === '' && subsection.title === '') {
                                                    onRemove({ subsection: subsection, indexes })
                                                } else {
                                                    setTitle(subsection.title)
                                                }

                                                setEditingTitle(false)
                                                setDuplicateTitleError(false)
                                                setEmptyTitleError(false)
                                            }}
                                        >
                                            <X className="size-6 shrink-0 stroke-[1.5px]" />
                                        </Button>

                                        <Button
                                            variant='tertiary'
                                            className="h-fit"
                                            onClick={() => {
                                                if (title === '') {
                                                    setEmptyTitleError(true)
                                                    return
                                                }

                                                const titles = getAllSectionTitles(sections)

                                                if (titles.includes(title) && title !== subsection.title) {
                                                    setDuplicateTitleError(true)
                                                    return
                                                }

                                                setEditingTitle(false)
                                                setDuplicateTitleError(false)
                                                setEmptyTitleError(false)

                                                if (isThirdLevel) {
                                                    const subsections = section.subsections
                                                    if (!subsections) return

                                                    const copy = [...section.subsections || []]

                                                    if (indexes.at(-1) === undefined) return

                                                    copy[indexes.at(-1) || 0] = {
                                                        ...subsection,
                                                        title: title,
                                                    }

                                                    onSubsectionUpdate({
                                                        ...section,
                                                        subsections: copy
                                                    })
                                                } else {
                                                    onSubsectionUpdate({
                                                        ...section,
                                                        title: title
                                                    })
                                                }
                                            }}
                                        >
                                            <Checkmark className="size-6 shrink-0 stroke-[1.5px]" />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {(subsection.subsections || []).length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={`${snapshot.isDraggingOver ? 'pointer-events-none' : ''}`}
                                >
                                    {subsection.subsections?.map((item, index) => (
                                        <Draggable key={item.id} draggableId={`draggable-${item.id}`} index={index}>
                                            {(provided, snapshot) => (
                                                snapshot.isDragging ?
                                                    createPortal(<TemplateSubsectionRow section={section} sections={sections} subsection={item} indexes={[...indexes, index]} provided={provided} snapshot={snapshot} onSubsectionUpdate={onSubsectionUpdate} onRemove={onRemove} />, document.body)
                                                    :
                                                    <TemplateSubsectionRow section={section} sections={sections} subsection={item} indexes={[...indexes, index]} provided={provided} snapshot={snapshot} onSubsectionUpdate={onSubsectionUpdate} onRemove={onRemove} />
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </div>
        </div>
    )
}