import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ProgressTracker } from "./ProgressTracker"
import { useEffect, useState } from "react"
import { DocGenTemplate } from "./DocGenTemplate"
import { DocGenTopic } from "./DocGenTopic"
import { DocGenOutline } from "./DocGenOutline"
import { DocGenDraft } from "./DocGenDraft"
import { Button } from "../ui/button"
import { ArrowLeft } from "lucide-react"
import { DocgenSession, DocgenStep, DocgenTemplate, QueryStatus } from "@/types/types"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { fetchSessions } from "./docGenThunk"

export const DocGenPage = () => {
    const { session_id } = useParams()

    const docGenStore = useSelector((state: RootState) => state.docGen)

    const [searchParams, setSearchParams] = useSearchParams()
    const page = searchParams.get('step')

    const [template, setTemplate] = useState<DocgenTemplate | null>(null)
    const [session, setSession] = useState<DocgenSession | null>(null)
    const [topic, setTopic] = useState<string | null>(null)

    const pages = [
        DocgenStep.TEMPLATE,
        DocgenStep.TOPIC,
        DocgenStep.OUTLINE,
        DocgenStep.DRAFT,
    ]

    const navigate = useNavigate()

    const handleNextClick = () => {
        switch (page) {
            case DocgenStep.TEMPLATE:
                setSearchParams({ step: DocgenStep.TOPIC })
                break;
            case DocgenStep.TOPIC:
                setSearchParams({ step: DocgenStep.OUTLINE })
                break;
            case DocgenStep.OUTLINE:
                setSearchParams({ step: DocgenStep.DRAFT })
                break;
            case DocgenStep.DRAFT:
                break;
        }
    }

    const handlePrevClick = () => {
        switch (page) {
            case DocgenStep.TEMPLATE:
                navigate(-1)
                break;
            case DocgenStep.TOPIC:
                setSearchParams({ step: DocgenStep.TEMPLATE })
                break;
            case DocgenStep.OUTLINE:
                setSearchParams({ step: DocgenStep.TOPIC })
                break;
            case DocgenStep.DRAFT:
                setSearchParams({ step: DocgenStep.OUTLINE })
                break;
        }
    }

    const handleNavigateToTopic = () => {
        setSearchParams({ step: DocgenStep.TOPIC })
    }

    useEffect(() => {
        if (session_id) {
            if (docGenStore.sessions.status === QueryStatus.SUCCEEDED) {
                const foundSession = docGenStore.sessions.data.find((v) => v.id === session_id) || null;

                if (foundSession) {
                    const foundTemplate = docGenStore.templates.find((v) => v.id === foundSession.template_id) || null;
                    setSession(foundSession);
                    setTopic(foundSession.topic || '');
                    setTemplate(foundTemplate);
                } else {
                    console.error(`Session with ID ${session_id} not found.`);
                }
            } else {
                console.log(`Unexpected session status: ${docGenStore.sessions.status}`);
            }
        }
    }, [session_id, docGenStore.sessions, docGenStore.templates]);

    return (
        <div className="relative flex flex-col gap-6 sm:!-mt-10 !-mb-10 md:!-mb-20 mx-[88px]">
            <div className="py-5">
                <ProgressTracker
                    labels={pages}
                    completedLabels={pages.slice(0, pages.findIndex((v) => v === page))}
                    currentLabels={[
                        page || DocgenStep.TEMPLATE
                    ]}
                    onCancel={() => navigate('/reports')}
                    customCancelButton={(page === DocgenStep.DRAFT || page === DocgenStep.OUTLINE) ? (onCancel) => {
                        return <Button variant='tertiary' onClick={onCancel}>
                            <div className="flex gap-2">
                                <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />
                                Reports
                            </div>
                        </Button>
                    } : undefined}
                />
            </div>
            {page === DocgenStep.TEMPLATE && <DocGenTemplate template={template} onNextClick={handleNextClick} onPrevClick={handlePrevClick} onSubmit={(v) => setTemplate(v)} />}
            {page === DocgenStep.TOPIC && <DocGenTopic
                session={session}
                template={template}
                onNextClick={handleNextClick}
                onPrevClick={handlePrevClick}
                onSessionCreate={(v) => setSession(v)}
                onTopicChange={(v) => setTopic(v)}
                onNavigateToTopic={handleNavigateToTopic}
            />}
            {page === DocgenStep.OUTLINE && <DocGenOutline session={session} template={template} topic={topic} onNextClick={handleNextClick} />}
            {page === DocgenStep.DRAFT && <DocGenDraft session={session} template={template} topic={topic} />}
        </div>
    )
}

export const DocGenContainer = () => {
    const docGenStore = useSelector((state: RootState) => state.docGen)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (docGenStore.sessions.status === QueryStatus.INITIALISED) {
            dispatch(fetchSessions())
        }
    }, [])

    return (
        <DocGenPage />
    )
}