import { Dialog, DialogBody, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { TypographyBody, TypographyLabel } from "../ui/Typography";
import { Button } from "../ui/button";
import ImgReportWalkthroughAsk from '../../assets/img-report-walkthrough-ask.png'
import ImgReportWalkthroughFactCheck from '../../assets/img-report-walkthrough-factcheck.png'
import ImgReportWalkthroughResearch from '../../assets/img-report-walkthrough-research.png'
import ArrowRight from "@/assets/ArrowRight";
import { useState } from "react";
import { Checkbox } from "../ui/checkbox";

export function ReportWalkthroughDialog({ open, setOpen }: { open?: boolean, setOpen: (v: boolean) => void }) {
    const [checked, setChecked] = useState(false)

    return (
        <Dialog open={open} onOpenChange={(v) => {
            setOpen(v)

            if (checked) {
                localStorage.setItem('hide_report_walkthrough', 'true')
            }
        }}>
            <DialogContent className="w-full !p-10 !max-w-[968px] overflow-auto max-h-[calc(100vh-120px)]">
                <DialogHeader>
                    <DialogTitle className="relative">
                        <div className="absolute left-[50%] -translate-x-[50%]">
                            Use Desia in Reports
                        </div>
                    </DialogTitle>
                    <DialogDescription>
                        <TypographyBody className="text-center">
                            Desia can help you write faster and more accurately
                        </TypographyBody>
                    </DialogDescription>
                </DialogHeader>
                <DialogBody>
                    <div className="flex flex-col gap-10 pt-10 pb-4">
                        <div className="flex gap-6">
                            <div className="p-6 bg-system-surface-light rounded-[12px] w-full h-[200px]">
                                <div className="h-[88px] flex items-center">
                                    <img className="w-full max-h-[70px]" src={ImgReportWalkthroughAsk} />
                                </div>

                                <div className="flex flex-col gap-1 mt-auto">
                                    <TypographyBody isStrong={true} className="text-system-primary">
                                        Ask Desia
                                    </TypographyBody>
                                    <TypographyLabel className="text-system-body">
                                        Use Desia's AI capabilities to aid report writing
                                    </TypographyLabel>
                                </div>
                            </div>

                            <div className="p-6 bg-system-surface-light rounded-[12px] w-full h-[200px]">
                                <div className="h-[88px]">
                                    <img className="max-h-[70px]" src={ImgReportWalkthroughFactCheck} />
                                </div>

                                <div className="flex flex-col gap-1 mt-auto">
                                    <TypographyBody isStrong={true} className="text-system-primary">
                                        FactCheck
                                    </TypographyBody>
                                    <TypographyLabel className="text-system-body">
                                        Highlight a statement to verify figures and other information
                                    </TypographyLabel>
                                </div>
                            </div>

                            <div className="p-6 bg-system-surface-light rounded-[12px] w-full h-[200px]">
                                <div className="h-[88px]">
                                    <img className="max-h-[70px]" src={ImgReportWalkthroughResearch} />
                                </div>

                                <div className="flex flex-col gap-1 mt-auto">
                                    <TypographyBody isStrong={true} className="text-system-primary">
                                        Research this
                                    </TypographyBody>
                                    <TypographyLabel className="text-system-body">
                                        Highlight text for Desia to do an in-depth analysis and find insights
                                    </TypographyLabel>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-3">
                            <TypographyBody isStrong={true} className="text-center w-full">
                                See the walkthrough below
                            </TypographyBody>

                            <video className="rounded-[16px] max-w-[640px] [@media(min-height:1100px)]:max-w-full mx-auto border border-system-border-regular" controls autoPlay muted loop src={'https://storage.googleapis.com/desia-public-assets/writing_assistant_demo_v2.mp4'} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className="pt-0 !mt-0">
                    <div className="flex items-center pr-16">
                        <Checkbox
                            checked={checked}
                            onCheckedChange={() => setChecked(!checked)}
                            label="Don’t show me this again"
                        />
                    </div>
                    <DialogClose>
                        <Button className="w-full">
                            <div className="flex gap-2">
                                Get started
                                <ArrowRight />
                            </div>
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
