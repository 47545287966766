export class FactCheckTool {
    public button: HTMLButtonElement | null;
    public state: boolean;
    public handlers: { showPanel: (y: number) => void, setSelectedText: (text: string) => void };
    public api: any;
  
    static get isInline() {
      return true;
    }
  
    constructor({ api, config }: any) {
      this.button = null;
      this.state = false;
      this.api = api;
      this.handlers = config.handlers;
    }
  
    onClick(evt: MouseEvent) {
      this.handlers.showPanel(evt.pageY - 200);
      setTimeout(() => {
        this.api.inlineToolbar.close();
      }, 1000);
    }
  
    surround(range: any) {
      const selectedText = range.extractContents();
  
      // Create MARK element
      const mark = document.createElement('Fragment');
  
      // Append to the MARK element selected TextNode
      mark.appendChild(selectedText);
  
      // Insert new element
      range.insertNode(mark);
      this.handlers.setSelectedText(mark.outerText)
    }
  
  
    checkState(selection: any) {
      const text = selection.anchorNode;
  
      if (!text) {
        return;
      }
  
      const anchorElement = text instanceof Element ? text : text.parentElement;
  
      this.state = !!anchorElement.closest('Fragment');
    }
  
    render() {
      this.button = document.createElement('button');
      this.button.type = 'button';
      this.button.textContent = 'FactCheck';
      this.button.classList.add('font-body-strong');
      this.button.classList.add('text-system-primary');
      this.button.classList.add('px-3');
      this.button.addEventListener('click', this.onClick.bind(this));
  
      return this.button;
    }
  }
  