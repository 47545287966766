import { ReactNode } from "react";

interface ResponsiveContainerProps {
    minWidth?: number;
    children: ReactNode;
}

export function ResponsiveContainer({ minWidth, children }: ResponsiveContainerProps) {
    const width = minWidth || 520;
    const breakpoint = Math.max(width, 620);
    return (
        <div className={`w-[${width}px] max-[${breakpoint}px]:w-[calc(100vw-2rem)]`}>
            {children}
        </div>
    )
}
