import {
  compareDesc,
  differenceInCalendarWeeks,
  getMonth,
  getWeek,
  getYear,
  parseISO,
} from 'date-fns'
import { Dispatch, SetStateAction } from 'react'
import {
  LOGIN_PATH,
  USER_FEATURE_FLAG_PREFIX,
  USER_SETTINGS_KEY,
  WEB_SERVER_ENDPOINT,
} from '../constants'
import {
  ExpertModeDocument,
  HasDateProperty,
  FileStructure,
  ResAppUser,
  ResponseDocument,
  SourceDocument,
  UserFeatureFlag,
  UserSettings,
  SearchQueryExtract,
  TemplateSection,
  TemplateSubsection,
  Citation,
  IntegrationCode,
  DocgenSession,
  DocgenSessionStatus,
  DocgenStep,
} from '../types/types'
import { NavigateFunction } from 'react-router-dom'
import { IFeatureFlagContext } from '@/contexts/FeatureFlagContext'
import { handleError } from './handleError'
import { filterDocumentsByCited, getGlobalUniqueDocuments } from './components'
import { tertiaryStyle } from '@/components/ui/button'

export function getTimestamp(d?: string) {
  if (d) {
    return new Date(d).getTime()
  }
  return new Date().getTime()
}

export function shortString(str: string, maxLen: number) {
  if (str.length > maxLen) {
    return str.substring(0, maxLen - 3) + '...'
  }
  return str
}

export function getMessageId({
  conversationId,
  created_at,
  requestId,
}: {
  conversationId?: string
  created_at: number
  requestId?: string
}) {
  return `${conversationId || requestId}_${created_at}`
}

export function getFileLinkV2(file: ResponseDocument) {
  if (file.document_source === 'user_upload') {
    if (file.document_storage_class === 'gcs') {
      // signed url
      return `${WEB_SERVER_ENDPOINT}/api/document/download/${file.document_id}/`
    }
    if (file.document_storage_class === 'local') {
      // old file links
      // will be deprecated / removed in future
      return `${WEB_SERVER_ENDPOINT}/api/document/content/${file.document_id}/${file.document_name}`
    }
    console.error('Unknown file storage class given user_upload', file)
  }
  if (file.document_source === 'integration') {
    // link to external file e.g. sharepoint
    return file.document_secure_shared_link
  }
  console.error('Unknown file storage class', file)
}

export function timeDifference(start: Date, finish: Date) {
  const t1 = start.getTime()
  const t2 = finish.getTime()
  return t2 - t1
}

export function exceededDuration(
  start: Date,
  finish: Date,
  maxDuration: number
) {
  const td = timeDifference(start, finish)
  return td > maxDuration
}

export function checkWebLink(url: string | undefined) {
  const isWebLink = url?.includes('http')
  return isWebLink
}

export function getFileId(contentId: string) {
  const [fileId] = contentId.split('_')
  return fileId
}

function checkIntegrationLink({
  documentSource,
  documentLink,
}: {
  documentSource?: string
  documentLink?: string
}) {
  // for newer documents, document_source is set at ingestion time
  // for older documents, document_source is not set so we infer from document_link
  if (!documentSource) {
    return (
      documentLink?.includes('sharepoint.com') ||
      documentLink?.includes('office.com')
    )
  }
  return documentSource === 'integration'
}

export function handleOpenLink({
  id,
  url,
  title,
  documentLink,
  documentSource,
  window,
}: {
  id: string
  url: string
  title: string
  documentLink?: string
  documentSource?: string
  window: Window
}) {
  console.log('handleOpenLink', {
    id,
    url,
    title,
    documentLink,
    documentSource,
  })
  const isIntegrationLink = checkIntegrationLink({
    documentLink,
    documentSource,
  })
  if (isIntegrationLink) {
    window.open(documentLink, '_blank')
    return
  }

  const isWebLink = checkWebLink(url)
  // url points to either a web link or a file
  if (isWebLink) {
    window.open(url, '_blank')
    return
  }

  const fileId = getFileId(id)
  const fileUrl = `${WEB_SERVER_ENDPOINT}/api/document/download/${fileId}/`
  window.open(fileUrl, '_blank')
}

export function handleLogin() {
  window.location.href = `${WEB_SERVER_ENDPOINT}${LOGIN_PATH}`
}

export function handleLogout(navigate: NavigateFunction) {
  navigate('/signout')
}

export function plural(text: string, count: number) {
  if (count === 1) return text
  return `${text}s`
}

export function getIdentifierKey(requestId: string) {
  return `request_id__${requestId}`
}

/**
 * Store mapping between requestId <> conversationId
 * to allow transition from new question (page refresh) temp urls
 * to perma urls
 */
export function saveIdentifier(
  requestId: string,
  conversationId: string,
  timestamp: number
) {
  try {
    const identifiers = {
      key: getIdentifierKey(requestId),
      value: JSON.stringify({
        requestId: requestId,
        conversationId: conversationId,
        timestamp: timestamp,
      }),
    }
    localStorage.setItem(identifiers.key, identifiers.value)
  } catch (e) {
    handleError(e)
  }
}

export function clearIdentifier(requestId: string) {
  try {
    const key = getIdentifierKey(requestId)
    localStorage.removeItem(key)
  } catch (e) {
    handleError(e)
  }
}

export function getIdentifier(requestId: string) {
  try {
    const key = getIdentifierKey(requestId)
    const identifiers = localStorage.getItem(key)
    if (identifiers) {
      const data = JSON.parse(identifiers)
      return data
    }
  } catch (e) {
    handleError(e)
  }
}

export function normaliseDocumentId(id: string) {
  if (id.includes('web')) {
    return {
      isWeb: true,
      isFile: false,
      id: id,
    }
  }

  const fileId = getFileId(id)
  return {
    isWeb: false,
    isFile: true,
    id: fileId,
  }
}

function getUserFlagKey(flag: UserFeatureFlag) {
  return `${USER_FEATURE_FLAG_PREFIX}_${flag}`
}

export function checkUserFlag(flag: UserFeatureFlag) {
  try {
    const key = getUserFlagKey(flag)
    const result = localStorage.getItem(key)
    if (result === 'true') {
      return true
    }
    return false
  } catch (e) {
    handleError(e)
    return false
  }
}

export function checkIfUserFlagExists(flag: UserFeatureFlag) {
  try {
    const key = getUserFlagKey(flag)
    const result = localStorage.getItem(key)
    if (result) {
      return true
    }
    return false
  } catch (e) {
    handleError(e)
    return false
  }
}

export function setUserFlag(flag: UserFeatureFlag, isEnabled: boolean) {
  try {
    const key = getUserFlagKey(flag)
    localStorage.setItem(key, `${isEnabled}`)
  } catch (e) {
    handleError(e)
  }
}

// todo: move server side when we want to persist beyond single device
export function saveUserSettings(s: UserSettings) {
  try {
    const str = JSON.stringify(s)
    sessionStorage.setItem(USER_SETTINGS_KEY, str)
  } catch (e) {
    handleError(e)
    return false
  }
}

export function getUserSettings() {
  try {
    const value = sessionStorage.getItem(USER_SETTINGS_KEY)
    if (!value) return null
    return JSON.parse(value)
  } catch (e) {
    handleError(e)
    return false
  }
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined
}

export function getIconSrc(domain: string, size?: number) {
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=${size || 256}`
}

export function mapPlannerDocumentToSourceDocument(
  pd: ExpertModeDocument
): SourceDocument {
  return {
    document_id: pd.id,
    doc_metadata: pd.doc_metadata,
    title: pd.title,
    url: pd.url,
    text: pd.snippet, // todo: snippet or title?
  }
}

export function checkPathMatch(location: string, menuItemPath: string) {
  if (menuItemPath.toLowerCase().includes(location.toLocaleLowerCase())) {
    return true
  }
  return false
}

export function captialise(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function getLocale() {
  return 'en-GB'
}

export function checkDesiaUser(user: ResAppUser | null) {
  if (!user) return false
  try {
    const email = user.email
    if (email.endsWith('@desia.ai')) {
      return true
    }
    return false
  } catch (e) {
    handleError(e)
    return false
  }
}

export function friendlyOrgName(orgId: string | undefined = '') {
  try {
    const [_, name] = orgId.split('__')

    if (name) return captialise(name)
    return orgId
  } catch (e) {
    handleError(e)
    return orgId
  }
}

export function getCaretPosition(node: Node) {
  const selection = window.getSelection()
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0)
    const preCaretRange = range.cloneRange()
    preCaretRange.selectNodeContents(node)
    preCaretRange.setEnd(range.endContainer, range.endOffset)
    return preCaretRange.toString().length
  }
  return 0
}

export function setCaretPosition(position: number, node: Node) {
  const selection = window.getSelection()
  const range = document.createRange()
  range.selectNodeContents(node)

  let currentPos = 0
  let found = false

  const traverseNodes = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textLength = node.textContent?.length ?? 0
      if (currentPos + textLength >= position) {
        range.setStart(node, position - currentPos)
        range.collapse(true)
        found = true
      } else {
        currentPos += textLength
      }
    } else {
      for (let i = 0; i < node.childNodes.length; i++) {
        traverseNodes(node.childNodes[i])
        if (found) break
      }
    }
  }

  traverseNodes(node)
  if (found) {
    selection?.removeAllRanges()
    selection?.addRange(range)
  }
}

// modified from this: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes: number) {
  if (bytes === 0 || bytes < 0) return '0 Bytes'

  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  const formattedValue = parseFloat((bytes / Math.pow(k, i)).toFixed(2))

  return `${formattedValue} ${sizes[i]}`
}

export const getIntegrationName = (integration_code_name: string): string => {
  switch (integration_code_name) {
    case IntegrationCode.ONEDRIVE:
      return 'OneDrive'
    case IntegrationCode.SHAREPOINT:
      return 'SharePoint'
    case IntegrationCode.TEAMS:
      return 'Teams'
    case IntegrationCode.OUTLOOK:
      return 'Outlook'
    default:
      return 'Integration'
  }
}

export const getHostname = (url: string) => {
  try {
    const { hostname } = new URL(url)
    return hostname
  } catch (e) {
    return ''
  }
}

export const getDomain = (hostname: string) => {
  const replacedDomain = hostname.replaceAll('www.', '')
  return replacedDomain
}

export const groupItemsPerWeek = <T extends Record<string, any>>(
  items: T[],
  dateKey: HasDateProperty<T>
): { year: number; weekNumber: number; items: T[] }[] => {
  const datesByWeek: { [key: string]: T[] } = items.reduce(
    (acc, item) => {
      const dateValue = item[dateKey] as string
      const parsedDate = parseISO(dateValue)
      const year = getYear(parsedDate)
      const weekNumber = getWeek(parsedDate)
      const yearWeekKey = `${year}-${weekNumber}`
      if (!acc[yearWeekKey]) {
        acc[yearWeekKey] = []
      }
      acc[yearWeekKey].push(item)
      return acc
    },
    {} as { [key: string]: T[] }
  )

  Object.keys(datesByWeek).forEach((yearWeekKey) => {
    datesByWeek[yearWeekKey].sort((a, b) =>
      compareDesc(
        parseISO(a[dateKey] as string),
        parseISO(b[dateKey] as string)
      )
    )
  })

  const sortedWeeks: { year: number; weekNumber: number; items: T[] }[] =
    Object.keys(datesByWeek)
      .map((yearWeekKey) => {
        const [year, weekNumber] = yearWeekKey.split('-').map(Number)
        return {
          year,
          weekNumber,
          items: datesByWeek[yearWeekKey],
        }
      })
      .sort((a, b) => {
        if (b.year === a.year) {
          return b.weekNumber - a.weekNumber
        }
        return b.year - a.year
      })

  return sortedWeeks
}

export const groupItemsPerMonth = <T extends Record<string, any>>(
  items: T[],
  dateKey: HasDateProperty<T>
): { year: number; monthNumber: number; items: T[] }[] => {
  const datesByMonth: { [key: string]: T[] } = items.reduce(
    (acc, item) => {
      const dateValue = item[dateKey] as string
      const parsedDate = parseISO(dateValue)
      const year = getYear(parsedDate)
      const monthNumber = getMonth(parsedDate) // 0-based month index
      const yearMonthKey = `${year}-${monthNumber}`
      if (!acc[yearMonthKey]) {
        acc[yearMonthKey] = []
      }
      acc[yearMonthKey].push(item)
      return acc
    },
    {} as { [key: string]: T[] }
  )

  Object.keys(datesByMonth).forEach((yearMonthKey) => {
    datesByMonth[yearMonthKey].sort((a, b) =>
      compareDesc(
        parseISO(a[dateKey] as string),
        parseISO(b[dateKey] as string)
      )
    )
  })

  const sortedMonths: { year: number; monthNumber: number; items: T[] }[] =
    Object.keys(datesByMonth)
      .map((yearMonthKey) => {
        const [year, monthNumber] = yearMonthKey.split('-').map(Number)
        return {
          year,
          monthNumber,
          items: datesByMonth[yearMonthKey],
        }
      })
      .sort((a, b) => {
        if (b.year === a.year) {
          return b.monthNumber - a.monthNumber
        }
        return b.year - a.year
      })

  return sortedMonths
}

export const groupItemsPerMonthAndWeek = <T extends Record<string, any>>(
  items: T[],
  dateKey: HasDateProperty<T>
): {
  year?: number
  weekNumber?: number
  monthNumber?: number
  items: T[]
}[] => {
  const sortedByMonth = groupItemsPerMonth(items, dateKey)

  const firstMonth = sortedByMonth[0]

  const firstMonthByWeeks = groupItemsPerWeek(firstMonth?.items || [], dateKey)

  return [...firstMonthByWeeks, ...sortedByMonth.slice(1)]
}

export function getDirectoryFromDocument(
  d: ResponseDocument,
  organizationName: string
) {
  if (d.document_is_part_of_desia_library) {
    return 'Desia library'
  }

  if (d.document_source === 'integration') {
    return getIntegrationName(
      d.document_source_details?.integration_code_name || ''
    )
  }

  if (d.document_visibility === 'private') {
    return 'Private'
  }

  return organizationName
}

export function getCreatedAt(d: ResponseDocument | undefined): Date | null {
  // manual upload: d.created_at_desia
  // uploaded via integration: d?.document_source_details?.integration_created_at
  // fallback (document created at) d.document_created_at
  const date =
    d?.created_at_desia ||
    d?.document_source_details?.integration_created_at ||
    d?.document_created_at
  if (!date) return null
  return new Date(date)
}

export function getUpdatedAt(d: ResponseDocument | undefined): Date | null {
  const date = d?.document_updated_at || d?.updated_at_desia
  if (!date) return null
  return new Date(date)
}

export const convertUUIDToNumber = (uuid: string) => {
  const truncated = uuid.split('-')[0]
  return parseInt(truncated, 16)
}

export const toggleElement = (
  element: FileStructure,
  elements: FileStructure[],
  setElements?: Dispatch<SetStateAction<FileStructure[]>>
) => {
  const allSelected = element.is_included && !element.is_excluded
  const partialSelected = element.child_is_included

  const updatedElements = elements.map((v) => {
    let updatedElement = v
    let updatedChildElement = v

    if (allSelected) {
      updatedElement = {
        ...v,
        is_included: false,
        child_is_included: false,
        is_excluded: true,
        child_is_excluded: false,
      }
      updatedChildElement = {
        ...v,
        is_included: false,
        child_is_included: false,
        is_excluded: true,
        child_is_excluded: false,
      }
    } else if (partialSelected) {
      updatedElement = {
        ...v,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
      updatedChildElement = {
        ...v,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
    } else {
      updatedElement = {
        ...v,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
      updatedChildElement = {
        ...v,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
    }

    if (
      element.children_element_internal_ids?.includes(v.internal_element_id)
    ) {
      return updatedChildElement
    } else if (element.internal_element_id === v.internal_element_id) {
      return updatedElement
    }

    return v
  })

  setElements?.(() => updatedElements)

  updateParentElements(element, elements, setElements)
}

export const updateParentElements = (
  element: FileStructure,
  elements: FileStructure[],
  setElements?: Dispatch<SetStateAction<FileStructure[]>>
) => {
  const parentElement = elements.find(
    (v) => v.internal_element_id === element.parent_element_internal_ids.at(-1)
  )

  if (!parentElement) return

  setElements?.((elements) => {
    const level = elements.filter(
      (v) =>
        v.parent_element_internal_ids.at(-1) ===
        element.parent_element_internal_ids.at(-1)
    )

    let updatedParentElement = elements.find(
      (v) => v.internal_element_id === parentElement?.internal_element_id
    )

    if (!updatedParentElement) return []

    if (level.every((v) => v.is_included)) {
      updatedParentElement = {
        ...updatedParentElement,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
    } else if (level.some((v) => v.is_included || v.child_is_included)) {
      updatedParentElement = {
        ...updatedParentElement,
        is_included: false,
        child_is_included: true,
        is_excluded: false,
        child_is_excluded: true,
      }
    } else {
      updatedParentElement = {
        ...updatedParentElement,
        is_included: false,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
    }

    return elements.map((v) =>
      v.internal_element_id === updatedParentElement.internal_element_id
        ? updatedParentElement
        : v
    )
  })

  updateParentElements(parentElement, elements, setElements)
}

export const checkIntegrationFlag = (
  ff: IFeatureFlagContext,
  user: ResAppUser | null
) => {
  const isAdmin = user?.user_roles.includes('app_admin')
  return (
    (ff.checkFlag('integration: manage') &&
      user?.app_metadata.organization_id === 'org__desia') ||
    isAdmin
  )
}

export const formatNumberString = (v: string) => {
  return v
    .replaceAll(',', '')
    .replaceAll('.', '')
    .replaceAll('£', '')
    .replaceAll('$', '')
    .replaceAll('%', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('€', '')
    .replaceAll('*', '')
    .replaceAll('-', '')
    .replaceAll('+', '')
    .replaceAll('\n', '')
}

export const checkIfStringHasContent = (string: string) => {
  return /\S/.test(string)
}

export const removeDelimiterFromString = (string: string) => {
  if (string.includes(',')) {
    return `"${string}"`
  }

  return string
}

export const highlightWordFromString = (
  string: string,
  word: string,
  matchFullWords: boolean = true
) => {
  if (!word) return string

  const emPattern = /<em>(.*?)<\/em>/g
  const emMatches: string[] = []
  let replacedString = string.replace(emPattern, (match) => {
    emMatches.push(match)
    return `__EM__${emMatches.length - 1}__`
  })

  const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  const regex = matchFullWords
    ? new RegExp(`\\b(${escapedWord})\\b`, 'gi')
    : new RegExp(`(${escapedWord})`, 'gi')

  replacedString = replacedString.replace(regex, (match) => {
    return `<em>${match}</em>`
  })

  replacedString = replacedString.replace(/__EM__(\d+)__/g, (_, index) => {
    return emMatches[parseInt(index, 10)]
  })

  replacedString = replacedString.replaceAll('</em> <em>', '</em>&nbsp;<em>')

  return replacedString
}

export function getUniqueDocumentIds(inputIds: string[]) {
  try {
    const documentIds = inputIds.map((d) => {
      if (d.startsWith('web-search') || d.startsWith('microsoft')) {
        return d
      }
      const [id] = d.split('_')
      return id
    })
    const uniqueDocumentIds = Array.from(new Set(documentIds))
    return uniqueDocumentIds
  } catch (e) {
    handleError(e)
    return []
  }
}

export const mapSourceHighlights = (
  highlights: {
    file_id: string
    highlight: string
    score?: number
    ranking_score?: number
    retrieval_score?: number
    page_number?: number | null
  }[]
) => {
  return highlights.map((v) => {
    return {
      text: '',
      highlight: {
        'highlight-3': v.highlight,
      },
      page_number: v.page_number,
    } as SearchQueryExtract
  })
}

export const filterDuplicateHighlights = (documents: SourceDocument[]) => {
  const text = new Set()

  const filteredDocs = documents.filter((v) => {
    if (text.has(v.text)) {
      return false
    } else {
      text.add(v.text)
      return true
    }
  })

  return filteredDocs
}

export const convertToBullets = (input: string) => {
  return input.replace(/^-\s/gm, '• ')
}

export const convertToHyphens = (input: string) => {
  return input.replace(/^•\s/gm, '- ')
}

export const convertTemplateTitleToName = (text: string) => {
  return text.toLowerCase().replaceAll(' ', '_')
}

export const getAllSectionTitles = (
  sections: TemplateSection[] | TemplateSubsection[]
): string[] => {
  return sections.reduce((acc: string[], item) => {
    const titles: string[] = [item.title]
    if (item.subsections && item.subsections.length > 0) {
      titles.push(...getAllSectionTitles(item.subsections))
    }
    return acc.concat(titles)
  }, [])
}

export const convertThemesToString = (themes: string[]) => {
  return themes.map((v) => `• ${v}`).join('\n')
}

export const formatWeekLabel = (year: number, weekNumber: number): string => {
  const today = new Date()
  const currentYear = getYear(today)
  const currentWeek = getWeek(today)

  const thisWeekStartDate = new Date(currentYear, 0, 1 + (currentWeek - 1) * 7)
  const targetWeekStartDate = new Date(year, 0, 1 + (weekNumber - 1) * 7)

  const weekDifference = differenceInCalendarWeeks(
    thisWeekStartDate,
    targetWeekStartDate
  )

  if (weekDifference === 0) {
    return 'This week'
  } else if (weekDifference === 1) {
    return 'Last week'
  } else if (weekDifference === -1) {
    return 'Next week'
  } else {
    return `${Math.abs(weekDifference)} weeks ago`
  }
}

export const getCitationDocuments = (
  citation: Citation,
  documents: SourceDocument[]
) => {
  return documents.filter((d) => {
    const uniqueIds = getUniqueDocumentIds(citation.document_ids)
    try {
      let id = ''
      if (d.document_id.includes('python')) {
        return false
      }

      if (d.document_id.startsWith('web-search')) {
        id = d.document_id
      } else if (d.document_id.startsWith('microsoft')) {
        id = d.document_id
      } else {
        id = d.document_id.split('_')[0]
      }

      return uniqueIds.includes(id)
    } catch (e) {
      handleError(e)
      return false
    }
  })
}

export const getUniqueCitationDocuments = (
  citation: Citation | null,
  documents: SourceDocument[]
) => {
  const filteredDocuments = filterDocumentsByCited(
    documents,
    citation ? [citation] : []
  )
  const nonChartDocuments = getGlobalUniqueDocuments(
    filteredDocuments,
    false
  ).filter(
    (v) => !v.document_id.includes('chart') && !v.document_id.includes('table')
  )
  const filteredHighlights = filterDuplicateHighlights(nonChartDocuments)
  return filteredHighlights
}

export const getCitationHighlights = (
  citation: Citation | null,
  source: SourceDocument | null
) => {
  const selectedHighlights = citation?.highlights?.filter((v) => {
    if (v.file_id.startsWith('web')) {
      return v.file_id === source?.document_id
    }

    if (v.file_id.startsWith('microsoft')) {
      return v.file_id === source?.document_id
    }
    return getFileId(v.file_id) === getFileId(source?.document_id || '')
  })
  const mappedHighlights = mapSourceHighlights(selectedHighlights || [])
  return mappedHighlights
}

export const getCitationExtractResource = (
  extracts: SearchQueryExtract[],
  source: SourceDocument | null
) => {
  if (!source) return null
  return {
    extracts: extracts,
    document_type_friendly: source.doc_metadata?.document_type_friendly || '',
    id: source.document_id,
    title: source.title,
    text: source.text,
    url: source.url || '',
    document_link: source.doc_metadata?.external_link,
    doc_metadata: source.doc_metadata,
  }
}

export const applyInlineStyles = (styles: string[], element: Element) => {
  if (element.nodeType !== Node.ELEMENT_NODE) return

  const computedStyles = window.getComputedStyle(element)
  const inlineStyles = styles
    .map((prop) => `${prop}: ${computedStyles.getPropertyValue(prop)};`)
    .join(' ')

  element.setAttribute('style', inlineStyles)
  Array.from(element.children).forEach((v) => applyInlineStyles(styles, v))
}

export const matchSearchQuery = (text: string, query: string) => {
  const splitText = query.split(' ')
  return splitText.some((v) => text.toLowerCase().includes(v.toLowerCase()))
}

export const getDocgenSessionUrl = (session: DocgenSession): string => {
  switch (session.status) {
    case DocgenSessionStatus.CREATED:
    case DocgenSessionStatus.CREATING:
      return `/docgen/${session.id}?${new URLSearchParams({ step: DocgenStep.DRAFT })}`
    case DocgenSessionStatus.THEME_GENERATED:
    case DocgenSessionStatus.THEME_GENERATING:
    case DocgenSessionStatus.DRAFT_ERROR:
      return `/docgen/${session.id}?${new URLSearchParams({ step: DocgenStep.OUTLINE })}`
    case DocgenSessionStatus.THEME_ERROR:
      return `/docgen/${session.id}?${new URLSearchParams({ step: DocgenStep.TOPIC })}`
    default:
      return `/docgen/${session.id}`
  }
}

export const replaceHTMLHighlight = (text: string, highlight: string) => {
  if (!highlight) return text
  return highlight
    .trim()
    .replaceAll(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&')
    .split(/\s+/)
    .reduce(
      (acc, _, i, arr) =>
        acc.replace(
          new RegExp(arr.slice(i, i + 5).join(' '), 'gi'),
          (match) =>
            `<span class='bg-[linear-gradient(0deg,_var(--citation-hover),_var(--citation-hover))] bg-no-repeat bg-[length:100%_90%]'>${match}</span>`
        ),
      text
    )
}

export const replaceMicrosoftHighlight = (text: string) => {
  const highlightedText = text
    .replaceAll('<c0>', '<Highlight>')
    .replaceAll('</c0>', '</Highlight>')
  const urlText = highlightedText.replaceAll(
    /(https?:\/\/[^\s]+)/g,
    (url) =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer" className='cursor-pointer underline ${tertiaryStyle} !text-system-body'>${url}</a>`
  )
  return urlText
}
