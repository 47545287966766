const PDFFile = ({ className }: { className?: string }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0563 2.3335H9.25757C8.68006 2.3335 8.12621 2.57933 7.71785 3.01691C7.30949 3.4545 7.08008 4.04799 7.08008 4.66683V23.3335C7.08008 23.9523 7.30949 24.5458 7.71785 24.9834C8.12621 25.421 8.68006 25.6668 9.25757 25.6668H22.3225C22.9 25.6668 23.4539 25.421 23.8622 24.9834C24.2706 24.5458 24.5 23.9523 24.5 23.3335V8.16683L19.0563 2.3335Z" fill="white" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.532 2.3335V7.00016C17.532 7.619 17.7767 8.21249 18.2123 8.65008C18.6479 9.08766 19.2386 9.3335 19.8546 9.3335H24.4999" fill="white" />
            <path d="M17.532 2.3335V7.00016C17.532 7.619 17.7767 8.21249 18.2123 8.65008C18.6479 9.08766 19.2386 9.3335 19.8546 9.3335H24.4999" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="2.43481" y="9.3335" width="13.9359" height="14" rx="2" fill="#D00306" />
            <path d="M4.40283 18.3578V14.3096H5.99998C6.30702 14.3096 6.5686 14.3682 6.78471 14.4855C7.00083 14.6015 7.16554 14.7629 7.27887 14.9698C7.39352 15.1753 7.45084 15.4125 7.45084 15.6814C7.45084 15.9502 7.39286 16.1874 7.2769 16.393C7.16093 16.5985 6.99292 16.7587 6.77285 16.8733C6.5541 16.9879 6.28923 17.0453 5.97824 17.0453H4.96025V16.3594H5.83987C6.00459 16.3594 6.14032 16.331 6.24706 16.2744C6.35512 16.2164 6.4355 16.1367 6.48821 16.0352C6.54224 15.9324 6.56925 15.8145 6.56925 15.6814C6.56925 15.547 6.54224 15.4297 6.48821 15.3295C6.4355 15.2281 6.35512 15.1497 6.24706 15.0943C6.139 15.0376 6.00196 15.0093 5.83592 15.0093H5.25873V18.3578H4.40283Z" fill="white" />
            <path d="M9.27485 18.3578H7.8398V14.3096H9.28671C9.6939 14.3096 10.0444 14.3906 10.3383 14.5527C10.6322 14.7135 10.8582 14.9447 11.0163 15.2465C11.1757 15.5483 11.2555 15.9093 11.2555 16.3297C11.2555 16.7514 11.1757 17.1138 11.0163 17.4169C10.8582 17.72 10.6308 17.9526 10.3343 18.1146C10.0392 18.2767 9.68599 18.3578 9.27485 18.3578ZM8.69569 17.6244H9.23926C9.49228 17.6244 9.7051 17.5796 9.87773 17.49C10.0517 17.3991 10.1821 17.2587 10.2691 17.069C10.3574 16.8779 10.4015 16.6315 10.4015 16.3297C10.4015 16.0306 10.3574 15.7861 10.2691 15.5964C10.1821 15.4066 10.0523 15.2669 9.87971 15.1773C9.70708 15.0877 9.49426 15.0429 9.24125 15.0429H8.69569V17.6244Z" fill="white" />
            <path d="M11.7225 18.3578V14.3096H14.4028V15.0152H12.5784V15.9798H14.2249V16.6855H12.5784V18.3578H11.7225Z" fill="white" />
        </svg>
    )
}

export default PDFFile