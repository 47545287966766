"use client"

import * as React from "react"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { Check, ChevronRight, Circle } from "lucide-react"

import { cn } from "@/shadcn/utils"
import { tertiaryStyle } from "./button"
import Checkmark from "@/assets/Checkmark"

const OptionDropdownMenu = DropdownMenuPrimitive.Root

const OptionDropdownMenuTrigger = DropdownMenuPrimitive.Trigger

const OptionDropdownMenuGroup = DropdownMenuPrimitive.Group

const OptionDropdownMenuPortal = DropdownMenuPrimitive.Portal

const OptionDropdownMenuSub = DropdownMenuPrimitive.Sub

const OptionDropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup

const OptionDropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean
  }
>(({ className, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-body outline-none focus:bg-accent data-[state=open]:bg-accent",
      inset && "pl-8",
      className
    )}
    {...props}
  >
    {children}
    <ChevronRight className="ml-auto h-4 w-4" />
  </DropdownMenuPrimitive.SubTrigger>
))
OptionDropdownMenuSubTrigger.displayName =
  DropdownMenuPrimitive.SubTrigger.displayName

const OptionDropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={cn(
      "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-system-primary shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
))
OptionDropdownMenuSubContent.displayName =
  DropdownMenuPrimitive.SubContent.displayName

const OptionDropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        "z-50 overflow-hidden flex flex-col gap-2 rounded-lg shadow-outline border border-system-border-regular bg-system-secondary px-3 py-4 text-system-primary data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        className
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
))
OptionDropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const OptionDropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean
    selected?: boolean
    selectable?: boolean
  }
>(({ className, inset, selected, selectable = true, children, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      "relative flex cursor-default items-center focus:outline-none",
      inset && "pl-8",
      className
    )}
    {...props}
  >
    <div className={`${tertiaryStyle} justify-start w-full`}>
      <div className={`flex gap-2 ${selected ? '!font-body-strong' : '!font-body !text-system-body'} w-full`}>
        {selectable && (
          <Checkmark className={`size-6 shrink-0 ${selected ? 'opacity-1' : 'opacity-0'}`} />
        )}
        {children}
      </div>
    </div>
  </DropdownMenuPrimitive.Item>
))
OptionDropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

const OptionDropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={cn(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 font-body outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    )}
    checked={checked}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <Check className="h-4 w-4" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
))
OptionDropdownMenuCheckboxItem.displayName =
  DropdownMenuPrimitive.CheckboxItem.displayName

const OptionDropdownMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.RadioItem
    ref={ref}
    className={cn(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 font-body outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <Circle className="h-2 w-2 fill-current" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.RadioItem>
))
OptionDropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName

const OptionDropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn(
      "px-3 py-[1px] font-label text-system-body bg-system-hover rounded-sm",
      inset && "pl-8",
      className
    )}
    {...props}
  />
))
OptionDropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName

const OptionDropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={cn("h-px bg-system-border-light my-1", className)}
    {...props}
  />
))
OptionDropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName

const OptionDropdownMenuShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn("ml-auto text-xs tracking-widest opacity-60", className)}
      {...props}
    />
  )
}
OptionDropdownMenuShortcut.displayName = "DropdownMenuShortcut"

export {
  OptionDropdownMenu,
  OptionDropdownMenuTrigger,
  OptionDropdownMenuContent,
  OptionDropdownMenuItem,
  OptionDropdownMenuCheckboxItem,
  OptionDropdownMenuRadioItem,
  OptionDropdownMenuLabel,
  OptionDropdownMenuSeparator,
  OptionDropdownMenuShortcut,
  OptionDropdownMenuGroup,
  OptionDropdownMenuPortal,
  OptionDropdownMenuSub,
  OptionDropdownMenuSubContent,
  OptionDropdownMenuSubTrigger,
  OptionDropdownMenuRadioGroup,
}
