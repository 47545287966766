import { TypographyBody } from "../ui/Typography"
import { ResponseDocGenReport } from "@/types/types"
import { formatDate, setMonth, setYear } from "date-fns"
import { Skeleton } from "../ui/skeleton"
import { ErrorMessage } from "../ErrorMessage"
import { ReportCard } from "./ReportCard"
import { formatWeekLabel, groupItemsPerMonthAndWeek } from "@/utils/utils"

export interface ReportListProps {
    reports: ResponseDocGenReport[]
    loading: boolean
    error: string | null
    type: 'report' | 'dossier',
}

export const ReportList = ({ reports, loading, error, type }: ReportListProps) => {
    const sortedReportGroups = groupItemsPerMonthAndWeek(reports, 'updated_at')

    return (
        <div className="flex flex-col gap-4">
            {!loading && error && (
                <ErrorMessage message="We could not load reports at this time. Please try again." />
            )}

            <div className="flex flex-col gap-6">
                {loading && reports.length === 0 && (
                    <>
                        <Skeleton className="w-full h-[110px]" />
                        <Skeleton className="w-full h-[110px]" />
                        <Skeleton className="w-full h-[110px]" />
                        <Skeleton className="w-full h-[110px]" />
                    </>
                )}

                {sortedReportGroups.map((group, idx) => {
                    let monthDate = setMonth(new Date(), group.monthNumber || 0);
                    monthDate = setYear(monthDate, group.year || 0)
                    const weekText = formatWeekLabel(group.year || 0, group.weekNumber || 0)

                    return (
                        <div className={`flex flex-col gap-4 ${idx > 0 ? 'mt-8' : ''}`} key={idx}>
                            <TypographyBody isStrong={true} className="mx-3 mt-3">
                                {group.monthNumber ? formatDate(monthDate, 'MMMM yyyy') : weekText}
                            </TypographyBody>

                            <div className={`grid ${type === 'dossier' ? 'grid-cols-1 laptop:grid-cols-2' : 'grid-cols-1 mobile:grid-cols-2 laptop:grid-cols-3'} gap-x-6 gap-y-4`}>
                                {group.items.map((r) => <ReportCard key={r.id} report={r} type={type} />)}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}