import { followUpAsk, newAsk, requestCreateReport, requestDeleteReport, requestEntityExtraction, requestFactCheck, requestFactCheckV2, requestReportById, requestReports, requestResearch, requestSaveReport, responseAsk, responseCreateReport, responseDeleteReport, responseEntityExtraction, responseFactCheck, responseFactCheckV2, responseReportById, responseReports, responseResearch, responseSaveReport } from "@/components/DocGen/docGenSlice";
import { connected, fetchUserProfile } from "@/features/userSlice"
import { DESIA_EVENT, ResponseChatStream, ResponseDocGenReport, WebSocketResponseWrapper } from "@/types/types"
import { SocketConnection } from "@/utils/SocketConnection"

// This pattern of integrating websocket events with redux is from https://www.taniarascia.com/websockets-in-redux/
// it provides a way to emit events and listen for responses from the server
// redux will then handle the actions (see docGenSlice.ts for the reducers) to update store state
// @ts-expect-error
export const socketIOMiddleware = (socket: SocketConnection) => (params) => (next) => action => {
    const { dispatch } = params;
    switch (action.type) {
        case connected.type:
            console.debug("[redux middleware] websocket connected. Setting up event listeners");
            // responses from the server caught by event listeners
            // note: binding on user/connected allows for reconnection / rebinding of event listeners
            socket.on(DESIA_EVENT.FACT_CHECK_RESPONSE, (response) => {
                dispatch({ type: responseFactCheck.type, payload: response });
            })
            socket.on(DESIA_EVENT.FACT_CHECK_V2_RESPONSE, (response) => {
                dispatch({ type: responseFactCheckV2.type, payload: response });
            })
            socket.on(DESIA_EVENT.RESEARCH_RESPONSE, (response) => {
                dispatch({ type: responseResearch.type, payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_ASK_RESPONSE, (response) => {
                dispatch({ type: responseAsk.type, payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_LIST_REPORTS_RESPONSE, (response) => {
                dispatch({ type: responseReports.type, payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_CREATE_REPORT_RESPONSE, (response) => {
                dispatch({ type: responseCreateReport.type, payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_DELETE_REPORT_RESPONSE, (response) => {
                dispatch({ type: responseDeleteReport.type, payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_GET_REPORT_RESPONSE, (response) => {
                dispatch({ type: responseReportById.type, payload: response });
            })
            socket.on(DESIA_EVENT.DOCGEN_UPDATE_REPORT_RESPONSE, (response) => {
                const data = response as WebSocketResponseWrapper<ResponseDocGenReport>;
                dispatch(responseSaveReport(data));
            })
            socket.on(DESIA_EVENT.DOCGEN_EXTRACT_ENTITIES_RESPONSE, (response) => {
                const data = response as WebSocketResponseWrapper<ResponseChatStream>;
                if (data.data?.is_finished == true) {
                    dispatch(responseEntityExtraction(data));
                }
            })
            socket.on(DESIA_EVENT.DOCUMENT_LIST, (response) => {
              dispatch({ type: "document/setList", payload: response})
            })
            socket.on(DESIA_EVENT.DOCUMENT_STATUS_UPDATE, (response) => {
              dispatch({ type: "document/statusUpdate", payload: response})
            })
            dispatch(fetchUserProfile());
            break;
        // requests to the server by way of websocket events
        case requestFactCheck.type:
            socket.emit(DESIA_EVENT.FACT_CHECK_REQUEST, action.payload);
            break;
        case requestFactCheckV2.type:
            socket.emit(DESIA_EVENT.FACT_CHECK_V2_REQUEST, action.payload);
            break;
        case requestResearch.type:
            socket.emit(DESIA_EVENT.RESEARCH_REQUEST, action.payload);
            break;
        case newAsk.type:
            socket.emit(DESIA_EVENT.DOCGEN_ASK_REQUEST, action.payload);
            break;
        case followUpAsk.type:
            socket.emit(DESIA_EVENT.DOCGEN_ASK_REQUEST, action.payload);
            break;
        case requestReports.type:
            socket.emit(DESIA_EVENT.DOCGEN_LIST_REPORTS_REQUEST, action.payload);
            break;
        case requestCreateReport.type:
            socket.emit(DESIA_EVENT.DOCGEN_CREATE_REPORT_REQUEST, action.payload);
            break;
        case requestDeleteReport.type:
            socket.emit(DESIA_EVENT.DOCGEN_DELETE_REPORT_REQUEST, action.payload);
            break;
        case requestReportById.type:
            socket.emit(DESIA_EVENT.DOCGEN_GET_REPORT_REQUEST, action.payload);
            break;
        case requestSaveReport.type:
            socket.emit(DESIA_EVENT.DOCGEN_UPDATE_REPORT_REQUEST, action.payload);
            break;
        case requestEntityExtraction.type:
            socket.emit(DESIA_EVENT.DOCGEN_EXTRACT_ENTITIES_REQUEST, action.payload);
            break;
        default:
            break;
    }

    if (action.type !== "docgen/setToolCursor") {
        console.debug("[redux middleware] action:", action);
    }
    return next(action)
}
