import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseDocument, QueryStatus } from '../../types/types';
import { RootState } from '../../store/store';
import { WEB_SERVER_ENDPOINT } from '../../constants';

export const listDocuments = createAsyncThunk<
  ResponseDocument[],
  void,
  { state: RootState }
>('document/list', async (_, { rejectWithValue }) => {
  try {
    const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/document/list`, {
      method: 'GET',
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to fetch documents');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
}, {
  condition: (_, { getState }) => {
    const { document } = getState();
    const { fetchStatus } = document;
    if (
      fetchStatus === QueryStatus.SUCCEEDED ||
      fetchStatus === QueryStatus.FETCHING
    ) {
      return false;
    }
    return true;
  },
});

export const uploadDocument = createAsyncThunk<
  void,
  File,
  { state: RootState }
>('document/upload', async (file, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/document/upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to upload file');
    }

  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});

export const deleteDocument = createAsyncThunk<
  void,
  ResponseDocument,
  { state: RootState }
>('document/delete', async (file, { rejectWithValue }) => {
  try {
    const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/document/${file.document_id}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: "include",
    })

    if (!response.ok) {
      throw new Error('Failed to delete file');
    }

  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});