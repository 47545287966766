import { Pie, PieChart } from "recharts"
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../ui/chart"
import { TypographyH3, TypographyLabel } from "../ui/Typography"
import { formatChartDate } from "./ResponseChart"
import { ResponseChartData } from "@/types/types"
import { ChartHeader } from "./ChartHeader"
import { useRef } from "react"

export const ResponsePieChart = ({ id, parsedData, parsedDataValues, parsedChartConfig, compact }: {
    id: string
    parsedData: ResponseChartData
    parsedDataValues: {
        [label: string]: string | number;
    }[],
    parsedChartConfig: ChartConfig,
    compact?: boolean
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const total = parsedData.data.map((v) => v.value).flat().reduce((a, b) => a + b, 0)

    const labelSpacing = 4

    return (
        <div className={`flex flex-col gap-4 p-4 ${compact ? 'mt-8' : 'mt-10'} relative group`}>
            <ChartHeader chartRef={ref} parsedData={parsedData} compact={compact} />

            <div className="flex flex-col z-[0] gap-0 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-25%] text-center">
                <TypographyH3 className="text-system-primary">
                    {total.toLocaleString()}
                </TypographyH3>

                <TypographyLabel className="text-system-body max-w-[200px] text-ellipsis overflow-hidden">
                    {parsedData.values[0]}
                </TypographyLabel>
            </div>

            <ChartContainer ref={ref} id={`chart-container-${id}`} config={parsedChartConfig} className="min-h-[200px] w-full p-4">
                <PieChart accessibilityLayer data={parsedDataValues}>
                    <ChartTooltip content={<ChartTooltipContent labelFormatter={(label) => {
                        return formatChartDate(label)
                    }} />} />

                    <Pie
                        data={parsedDataValues}
                        dataKey={parsedData.values[0]}
                        nameKey={parsedData.label}
                        isAnimationActive={false}
                        label={({ payload, ...props }) => {
                            const angle: number = props.midAngle
                            let x = props.x
                            let y = props.y

                            // adjust spacing between label and tick line
                            if (0 < angle && angle < 90) {
                                x += labelSpacing
                                y -= labelSpacing
                            } else if (90 < angle && angle < 180) {
                                x -= labelSpacing
                                y += labelSpacing
                            } else if (180 < angle && angle < 270) {
                                x -= labelSpacing
                                y += labelSpacing
                            } else if (270 < angle && angle < 360) {
                                x += labelSpacing
                                y += labelSpacing
                            }

                            return (
                                <text
                                    cx={props.cx}
                                    cy={props.cy}
                                    x={x}
                                    y={y}
                                    textAnchor={props.textAnchor}
                                    dominantBaseline={props.dominantBaseline}
                                    className="text-system-body"
                                >
                                    {payload[parsedData.label]}
                                </text>
                            )
                        }}
                    >
                    </Pie>
                </PieChart>
            </ChartContainer>
        </div>
    )
}