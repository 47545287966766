const WordFile = ({ className }: { className?: string }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0312 2.3335H9.1875C8.60734 2.3335 8.05094 2.57933 7.6407 3.01691C7.23047 3.4545 7 4.04799 7 4.66683V23.3335C7 23.9523 7.23047 24.5458 7.6407 24.9834C8.05094 25.421 8.60734 25.6668 9.1875 25.6668H22.3125C22.8927 25.6668 23.4491 25.421 23.8593 24.9834C24.2695 24.5458 24.5 23.9523 24.5 23.3335V8.16683L19.0312 2.3335Z" fill="white" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.5 2.3335V7.00016C17.5 7.619 17.7458 8.21249 18.1834 8.65008C18.621 9.08766 19.2145 9.3335 19.8333 9.3335H24.5" fill="white" />
            <path d="M17.5 2.3335V7.00016C17.5 7.619 17.7458 8.21249 18.1834 8.65008C18.621 9.08766 19.2145 9.3335 19.8333 9.3335H24.5" stroke="#C4CDD7" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="2.33325" y="9.3335" width="14" height="14" rx="2" fill="#184ABD" />
            <path d="M7.00099 19.248L5.33325 13.4194H6.67939L7.64417 17.4692H7.69255L8.75694 13.4194H9.90956L10.9711 17.4778H11.0223L11.9871 13.4194H13.3333L11.6655 19.248H10.4645L9.3546 15.4372H9.30906L8.20198 19.248H7.00099Z" fill="white" />
        </svg>
    )
}

export default WordFile