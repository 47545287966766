import {
  DocgenCitation,
  DocgenContentTheme,
  DocgenDiscussion,
  DocumentPreviewType,
  ResponseDocument,
  SourceDocument,
} from '@/types/types'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { TypographyBody, TypographyH4 } from '../ui/Typography'
import texture from '@/assets/bg-texture.png'
import IconExpertGeneralKnowledge from '@/assets/IconExpertGeneralKnowledge.svg'
import IconExpertAnalyst from '@/assets/IconExpertAnalyst.svg'
import IconExpertBanking from '@/assets/IconExpertBanking.svg'
import IconExpertExecutive from '@/assets/IconExpertExecutive.svg'
import IconExpertManager from '@/assets/IconExpertManager.svg'
import IconExpertOther from '@/assets/IconExpertOther.svg'
import { CitationFootnote } from './CitationFootnote'
import { useEffect, useMemo, useState } from 'react'
import { DocumentPreviewContainer } from '../Resources/DocumentPreview'
import { mapSourceHighlights } from '@/utils/utils'
import { Button } from '../ui/button'
import { ArrowLeft } from 'lucide-react'

export const DocGenThemeDialogCard = ({
  discussion,
  documents,
  sourceDocuments,
  openedCitation,
  onCitationOpen,
}: {
  discussion: DocgenDiscussion
  documents: ResponseDocument[]
  sourceDocuments: SourceDocument[]
  openedCitation: DocgenCitation | null
  onCitationOpen: (
    citation: DocgenCitation | null,
    source: SourceDocument | null,
    discussion: DocgenDiscussion | null
  ) => void
}) => {
  const getExpertIcon = (role: string) => {
    if (role.match(/\b(?:general knowledge)\b/i)) {
      return (
        <img src={IconExpertGeneralKnowledge} className="size-8 shrink-0" />
      )
    }

    if (role.match(/\b(?:analyst)\b/i)) {
      return <img src={IconExpertAnalyst} className="size-8 shrink-0" />
    }

    if (role.match(/\b(?:manager)\b/i)) {
      return <img src={IconExpertManager} className="size-8 shrink-0" />
    }

    if (role.match(/\b(?:executive|ceo|partner|representative)\b/i)) {
      return <img src={IconExpertExecutive} className="size-8 shrink-0" />
    }

    if (role.match(/\b(?:banking|bank|banker|finance|financial)\b/i)) {
      return <img src={IconExpertBanking} className="size-8 shrink-0" />
    }

    return <img src={IconExpertOther} className="size-8 shrink-0" />
  }

  return (
    <div className="flex flex-col gap-3 p-6 bg-system-secondary rounded-[24px] border border-system-border-light">
      <div className="flex gap-3 items-center">
        {getExpertIcon(discussion.role)}

        <TypographyBody isStrong className="line-clamp-2 braek-all">
          {discussion.role}
        </TypographyBody>
        <div className="flex gap-1 items-center ml-auto">
          {Object.values(discussion.cited_info || {}).map((citation, index) => {
            // fixme: theme cited_info uses url instead of document_id
            const document =
              documents.find((v) => v.document_id === citation?.url) || null
            const sourceDocument =
              sourceDocuments.find((v) => v.document_id === citation?.url) ||
              null

            return (
              <CitationFootnote
                key={`theme-citation-${index}`}
                id={index + 1}
                document={document}
                selected={openedCitation === citation}
                onClick={() => {
                  if (citation && discussion) {
                    const placeholderDoc: SourceDocument = {
                      document_id: citation.url || '',
                      title: citation.title,
                      text: citation.title,
                      doc_metadata: {
                        document_type_friendly: citation.title
                          .split('.')
                          .at(-1),
                      },
                    }
                    onCitationOpen(
                      citation,
                      sourceDocument || placeholderDoc,
                      discussion
                    )
                  }
                }}
              />
            )
          })}
        </div>
      </div>
      <TypographyBody className="text-system-body">
        {discussion.utterance}
      </TypographyBody>
    </div>
  )
}

export const DocGenThemeDialog = ({
  open,
  setOpen,
  themes,
  documents,
  sourceDocuments,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  themes: DocgenContentTheme[] | null
  documents: ResponseDocument[]
  sourceDocuments: SourceDocument[]
}) => {
  const [openedCitation, setOpenedCitation] = useState<DocgenCitation | null>(
    null
  )
  const [selectedSource, setSelectedSource] = useState<SourceDocument | null>(
    null
  )
  const [selectedDiscussion, setSelectedDiscussion] =
    useState<DocgenDiscussion | null>(null)
  const [selectedExtractIndex, setSelectedExtractIndex] = useState<{
    [id: string]: number
  }>({})
  const [openedAccordion, setOpenedAccordion] = useState(`accordion-theme-0`)

  const hasOnlyOneTheme = (themes || []).length === 1

  const openedCitationHighlights = useMemo(() => {
    const highlights =
      openedCitation?.snippets.map((snippet) => {
        return {
          file_id: openedCitation.document_id,
          highlight: snippet,
          page_number: openedCitation.page_number || undefined,
        }
      }) || []
    const mappedHighlights = mapSourceHighlights(highlights)
    return mappedHighlights
  }, [openedCitation])

  const openedCitationResource = useMemo(() => {
    if (!selectedSource) return null
    return {
      extracts: openedCitationHighlights,
      document_type_friendly:
        selectedSource.doc_metadata?.document_type_friendly || '',
      id: selectedSource.document_id,
      title: selectedSource.title,
      text: selectedSource.text,
      url: selectedSource.url || '',
      document_link: selectedSource.doc_metadata?.external_link,
      doc_metadata: selectedSource.doc_metadata,
    }
  }, [selectedSource, openedCitationHighlights])

  const handleCitationOpen = (
    citation: DocgenCitation | null,
    source: SourceDocument | null,
    discussion: DocgenDiscussion | null
  ) => {
    setOpenedCitation(citation)
    setSelectedSource(source)
    setSelectedDiscussion(discussion)
  }

  const handleCitationClose = () => {
    setOpenedCitation(null)
    setSelectedSource(null)
    setSelectedDiscussion(null)
  }

  useEffect(() => {
    if (!open) {
      setOpenedCitation(null)
      setSelectedSource(null)
    }
  }, [open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="min-w-[calc(100vw-80px)] bg-system-surface"
        style={{ backgroundImage: `url(${texture})` }}
        dynamicHeight
      >
        <DialogHeader>
          <DialogTitle className="absolute right-6 top-6"></DialogTitle>

          <div className="flex flex-col gap-2 text-center">
            <TypographyH4>How did we find this?</TypographyH4>
          </div>

          <DialogDescription className="text-system-body whitespace-pre-wrap text-center">
            {`Our model generates a dialogue between specialists with different areas of expertise, combining their\nperspectives to develop a comprehensive analysis that is then used to generate the report section.`}
          </DialogDescription>
        </DialogHeader>
        <DialogBody className="mt-4">
          {!openedCitation && (
            <Accordion
              type="single"
              collapsible={!hasOnlyOneTheme}
              className="flex flex-col gap-2"
              value={hasOnlyOneTheme ? 'theme-0' : openedAccordion}
              onValueChange={(v) => setOpenedAccordion(v)}
            >
              {themes?.map((theme, index) => {
                const discussions = theme.discussions.filter(
                  (v) => v.role !== 'Guest' && v.role !== 'Moderator'
                )
                const firstColumn = discussions?.filter(
                  (_, index) => index % 3 === 0
                )
                const secondColumn = discussions?.filter(
                  (_, index) => index % 3 === 1
                )
                const thirdColumn = discussions?.filter(
                  (_, index) => index % 3 === 2
                )

                return (
                  <AccordionItem
                    value={`theme-${index}`}
                    className="border-none"
                    key={`accordion-theme-${index}`}
                  >
                    <AccordionTrigger
                      className="text-left p-4 border-t border-system-border-regular"
                      showChevron={!hasOnlyOneTheme}
                    >
                      {theme.question}
                    </AccordionTrigger>
                    <AccordionContent className="p-4">
                      <div className="flex gap-6">
                        <div className="flex flex-col gap-6 w-full">
                          {firstColumn?.map((discussion, index) => {
                            return (
                              <DocGenThemeDialogCard
                                discussion={discussion}
                                key={`theme-1-${index}`}
                                documents={documents}
                                sourceDocuments={sourceDocuments}
                                openedCitation={openedCitation}
                                onCitationOpen={handleCitationOpen}
                              />
                            )
                          })}
                        </div>

                        <div className="flex flex-col gap-6 w-full">
                          {secondColumn?.map((discussion, index) => {
                            return (
                              <DocGenThemeDialogCard
                                discussion={discussion}
                                key={`theme-2-${index}`}
                                documents={documents}
                                sourceDocuments={sourceDocuments}
                                openedCitation={openedCitation}
                                onCitationOpen={handleCitationOpen}
                              />
                            )
                          })}
                        </div>

                        <div className="flex flex-col gap-6 w-full">
                          {thirdColumn?.map((discussion, index) => {
                            return (
                              <DocGenThemeDialogCard
                                discussion={discussion}
                                key={`theme-3-${index}`}
                                documents={documents}
                                sourceDocuments={sourceDocuments}
                                openedCitation={openedCitation}
                                onCitationOpen={handleCitationOpen}
                              />
                            )
                          })}
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                )
              })}
            </Accordion>
          )}

          {openedCitation &&
            selectedSource &&
            openedCitationResource &&
            selectedDiscussion && (
              <div className="flex flex-col gap-12 mx-[120px] monitor:mx-[240px] relative transition-all ease-in-out duration-300">
                <Button variant="tertiary" onClick={handleCitationClose}>
                  <div className="flex gap-2 items-center">
                    <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />
                    Back
                  </div>
                </Button>

                <div className="flex gap-10 max-h-full w-full">
                  <div className="max-w-[520px] h-fit">
                    <DocGenThemeDialogCard
                      discussion={selectedDiscussion}
                      key={`theme-selected`}
                      documents={documents}
                      sourceDocuments={sourceDocuments}
                      openedCitation={openedCitation}
                      onCitationOpen={handleCitationOpen}
                    />
                  </div>
                  <div className="h-fit w-full">
                    <DocumentPreviewContainer
                      key={`document-preview-container-${selectedSource.document_id}`}
                      type={DocumentPreviewType.DOCGEN}
                      resource={openedCitationResource}
                      selectedExtractIndex={
                        selectedExtractIndex[selectedSource.document_id] || 0
                      }
                      setSelectedExtractIndex={(index) => {
                        setSelectedExtractIndex({
                          ...selectedExtractIndex,
                          [selectedSource.document_id]: index,
                        })
                      }}
                      sources={[selectedSource]}
                      selectedSource={selectedSource}
                      initialWidth={window.innerWidth > 1920 ? 600 : 400}
                      containerHeight={window.innerHeight - 375}
                      onBack={handleCitationClose}
                      onClose={handleCitationClose}
                      setSelectedSource={(v) => setSelectedSource(v)}
                    />
                  </div>
                </div>
              </div>
            )}
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}
