import { applyInlineStyles, formatNumberString } from '@/utils/utils'
import { parseHeaderForDates } from './utils'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../ui/table'
import { CustomAlert } from '../CustomAlert'
import { formatChartNumber } from '../Charts/ResponseChart'
import { Citation, SourceDocument } from '@/types/types'
import { MarkdownToJSX } from 'markdown-to-jsx'
import { ResponseMarkdown } from './ResponseMarkdown'
import { embedCitationsV3 } from '@/utils/embedCitations'
import { useMemo, useRef, useState } from 'react'
import { Button } from '../ui/button'
import { Check, Copy } from 'lucide-react'
import { renderToStaticMarkup } from 'react-dom/server'

export const ResponseTable = ({
    id,
    data,
    documents,
    citations,
    openedCitation,
    compact,
    overrides,
    showCopyButton = true,
}: {
    id: string
    data: string
    documents: SourceDocument[]
    citations: Citation[]
    openedCitation: Citation | null
    compact?: boolean
    overrides?: MarkdownToJSX.Overrides
    showCopyButton?: boolean
}) => {
    const [copySuccess, setCopySuccess] = useState(false)
    const tableRef = useRef(null)

    try {
        const parsedData: {
            headers: string[]
            [key: string]: any[]
        } = JSON.parse(data)[0]

        const isDateFirst = parseHeaderForDates(parsedData.headers[0])

        const { headers, ...withoutHeaders } = parsedData

        const transposed: [string, any[]][] = useMemo(() => {
            if (!isDateFirst) return []
            return Object.keys(withoutHeaders).map((key) => [
                key,
                withoutHeaders[key],
            ])
        }, [isDateFirst, data])

        const rowCount = parsedData[parsedData.headers[0]].length
        const tableCitations = citations.filter((v) => v.chart_table_id === id)

        const xHeaders = isDateFirst
            ? [transposed[0][0], ...transposed[0][1]]
            : headers

        const firstRow = isDateFirst
            ? transposed[1][1][0]
            : parsedData[parsedData.headers[1]][0]

        const removedText = formatNumberString(`${firstRow}`)
        const isNumber = /^\d+$/.test(removedText)

        const copyResponse = async () => {
            const compiledText = (
                <>
                    <ResponseTable
                        key={`response-table-container-${id}`}
                        id={id}
                        data={data}
                        documents={[]}
                        citations={[]}
                        openedCitation={null}
                        showCopyButton={false}
                    />
                </>
            )

            const tempDiv = document.createElement('div')
            tempDiv.innerHTML = renderToStaticMarkup(compiledText)
            document.body.appendChild(tempDiv)

            applyInlineStyles(['font-weight'], tempDiv)

            const styledText = tempDiv.innerHTML

            document.body.removeChild(tempDiv)

            await navigator.clipboard.write([
                new ClipboardItem({
                    'text/html': new Blob([styledText], { type: 'text/html' }),
                }),
            ])

            setCopySuccess(true)
            setTimeout(() => {
                setCopySuccess(false)
            }, 3000)
        }
        return (
            <>
                <div className="rounded-lg">
                    <div className="border border-system-border-regular rounded-lg overflow-hidden mt-8 [&>div]:rounded-lg">
                        <Table ref={tableRef}>
                            <TableHeader>
                                <TableRow>
                                    {xHeaders.map((header, index) => {
                                        const isRightAligned =
                                            isNumber && index !== 0
                                        return (
                                            <TableHead
                                                key={`table-${id}-header-${index}`}
                                                className={`bg-system-hover px-6 py-2 text-system-primary [&_p]:!font-body-strong border-b border-system-border-regular ${
                                                    isRightAligned
                                                        ? 'text-right'
                                                        : 'text-left'
                                                }`}
                                            >
                                                <ResponseMarkdown
                                                    text={header}
                                                    documents={documents}
                                                    citations={citations}
                                                    overrides={overrides}
                                                    compact={compact}
                                                    isTable={true}
                                                    openedCitation={
                                                        openedCitation
                                                    }
                                                />
                                            </TableHead>
                                        )
                                    })}
                                </TableRow>
                            </TableHeader>

                            <TableBody>
                                {isDateFirst
                                    ? transposed.map((entry, index) => {
                                          if (index === 0) return null
                                          const yHeader = entry[0]
                                          const data = entry[1]
                                          return (
                                              <TableRow
                                                  key={`table-${id}-row-${index}`}
                                              >
                                                  <TableCell className="px-6 py-2 font-body text-system-body bg-system-secondary text-left font-bold">
                                                      <ResponseMarkdown
                                                          text={yHeader}
                                                          documents={documents}
                                                          citations={citations}
                                                          overrides={overrides}
                                                          compact={compact}
                                                          isTable={true}
                                                          openedCitation={
                                                              openedCitation
                                                          }
                                                      />
                                                  </TableCell>
                                                  {data.map(
                                                      (value, valueIndex) => {
                                                          const isRightAligned =
                                                              isNumber

                                                          const formattedText =
                                                              typeof value ===
                                                              'number'
                                                                  ? formatChartNumber(
                                                                        `${value}`
                                                                    )
                                                                  : value

                                                          const citations =
                                                              tableCitations.filter(
                                                                  (citation) =>
                                                                      citation.index_x ===
                                                                          index &&
                                                                      citation.index_y ===
                                                                          valueIndex +
                                                                              1
                                                              )

                                                          const embeddedText =
                                                              embedCitationsV3({
                                                                  text: formattedText,
                                                                  citations,
                                                                  isComplete:
                                                                      true,
                                                              }).replaceAll(
                                                                  '‎',
                                                                  ''
                                                              )

                                                          return (
                                                              <TableCell
                                                                  key={`table-${id}-cell-${index}-${valueIndex}`}
                                                                  className={`px-6 py-2 font-body text-system-body bg-system-secondary ${
                                                                      isRightAligned
                                                                          ? 'text-right'
                                                                          : 'text-left'
                                                                  }`}
                                                              >
                                                                  <ResponseMarkdown
                                                                      text={
                                                                          embeddedText
                                                                      }
                                                                      documents={
                                                                          documents
                                                                      }
                                                                      citations={
                                                                          citations
                                                                      }
                                                                      overrides={
                                                                          overrides
                                                                      }
                                                                      compact={
                                                                          compact
                                                                      }
                                                                      isTable={
                                                                          true
                                                                      }
                                                                      openedCitation={
                                                                          openedCitation
                                                                      }
                                                                  />
                                                              </TableCell>
                                                          )
                                                      }
                                                  )}
                                              </TableRow>
                                          )
                                      })
                                    : [...Array(rowCount).keys()].map(
                                          (index) => {
                                              return (
                                                  <TableRow
                                                      key={`table-${id}-row-${index}`}
                                                  >
                                                      {parsedData.headers.map(
                                                          (
                                                              header,
                                                              headerIndex
                                                          ) => {
                                                              const isRightAligned =
                                                                  isNumber &&
                                                                  headerIndex !==
                                                                      0

                                                              const text:
                                                                  | string
                                                                  | number =
                                                                  parsedData[
                                                                      header
                                                                  ][index]
                                                              const formattedText =
                                                                  typeof text ===
                                                                  'number'
                                                                      ? formatChartNumber(
                                                                            `${text}`
                                                                        )
                                                                      : text

                                                              const citations =
                                                                  tableCitations.filter(
                                                                      (
                                                                          citation
                                                                      ) =>
                                                                          citation.index_x ===
                                                                              headerIndex &&
                                                                          citation.index_y ===
                                                                              index +
                                                                                  1
                                                                  )
                                                              const embeddedText =
                                                                  embedCitationsV3(
                                                                      {
                                                                          text: formattedText,
                                                                          citations,
                                                                          isComplete:
                                                                              true,
                                                                      }
                                                                  ).replaceAll(
                                                                      '‎',
                                                                      ''
                                                                  )

                                                              return (
                                                                  <TableCell
                                                                      key={`table-${id}-cell-${index}-${headerIndex}`}
                                                                      className={`px-6 py-2 font-body text-system-body bg-system-secondary ${isRightAligned ? 'text-right' : 'text-left'}`}
                                                                  >
                                                                      <ResponseMarkdown
                                                                          text={
                                                                              embeddedText
                                                                          }
                                                                          documents={
                                                                              documents
                                                                          }
                                                                          citations={
                                                                              citations
                                                                          }
                                                                          overrides={
                                                                              overrides
                                                                          }
                                                                          compact={
                                                                              compact
                                                                          }
                                                                          isTable={
                                                                              true
                                                                          }
                                                                          openedCitation={
                                                                              openedCitation
                                                                          }
                                                                      />
                                                                  </TableCell>
                                                              )
                                                          }
                                                      )}
                                                  </TableRow>
                                              )
                                          }
                                      )}
                            </TableBody>
                        </Table>
                    </div>
                </div>

                {showCopyButton && (
                    <div className="ml-auto flex justify-end">
                        <Button
                            variant="tertiary"
                            onClick={copyResponse}
                            className="ml-auto mt-6"
                        >
                            <div className="flex gap-2 items-center">
                                {copySuccess ? (
                                    <Check className="size-6 shrink-0 stroke-[1.5px]" />
                                ) : (
                                    <Copy className="size-6 shrink-0 stroke-[1.5px]" />
                                )}

                                {copySuccess ? 'Copied' : 'Copy table'}
                            </div>
                        </Button>
                    </div>
                )}
            </>
        )
    } catch {
        return <ResponseTableError />
    }
}

export const ResponseTableError = () => {
    return (
        <div className="flex flex-col gap-4 p-4 mt-10">
            <CustomAlert
                variant="error"
                title="We could not load the table at this time"
                description="We have notified the team and will be fixing the issue soon."
            />
        </div>
    )
}
