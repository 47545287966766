import { useDispatch } from "react-redux"
import { Button } from "../ui/button"
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog"
import { TypographyH4 } from "../ui/Typography"
import { AppDispatch } from "@/store/store"
import { disconnectAllMicrosoft } from "./integrationThunk"
import { handleError } from "@/utils/handleError"
import { useNavigate } from "react-router-dom"

export const RemoveIntegrationCard = () => {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const handleRemove = async () => {
        try {
            await dispatch(disconnectAllMicrosoft(true))

            navigate('/integrations')
        } catch (e) {
            handleError(e)
        }
    }

    return (
        <div className="h-[128px] p-6 w-full bg-system-surface-light border border-system-border-light rounded-[16px]">
            <div className="flex flex-col gap-4 w-full">
                <TypographyH4>
                    Remove integration
                </TypographyH4>

                <Dialog>
                    <DialogTrigger asChild>
                        <Button variant='secondary-destructive' className="w-fit">
                            Remove Microsoft 365 integration and all files associated
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="min-w-[580px]">
                        <DialogHeader>
                            <DialogTitle>
                                You are about to remove the Microsoft 365 integration
                            </DialogTitle>
                            <DialogDescription>
                                This will delete all the related files from the Desia platform
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter className="mt-2">
                            <DialogClose asChild>
                                <Button variant='secondary-destructive' onClick={handleRemove}>
                                    Remove integration and all files
                                </Button>
                            </DialogClose>

                            <DialogClose asChild>
                                <Button>
                                    Keep integration
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>

            </div>
        </div>
    )
}