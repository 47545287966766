const Divider = ({ withDot, className }: { withDot?: boolean, className?: string }) => {
    const style = `bg-system-border-light flex-grow h-[1px] ${className}`
    const dot = () => (
        <div className="h-1.5 w-1.5 bg-system-border-light rounded-full" />
    )
    return (
        <div className="flex gap-0 w-full items-center">
            {withDot && (
                dot()
            )}

            <div className={style}></div>

            {withDot && (
                dot()
            )}
        </div>
    )
}

export default Divider