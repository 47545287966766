import { Fragment } from "react/jsx-runtime"
import Divider from "../ui/divider"
import { TypographyBody, TypographyLabel } from "../ui/Typography"
import { Button } from "../ui/button"
import Checkmark from "@/assets/Checkmark"
import { ReactNode } from "react"

export const ProgressTracker = ({ labels, currentLabels, completedLabels, verticalLabels, largeIcon, onCancel, customCancelButton }: { labels: string[], currentLabels: string[], completedLabels: string[], verticalLabels?: boolean, largeIcon?: boolean, onCancel?: () => void, customCancelButton?: (onClick: () => void) => ReactNode }) => {
    return (
        <div className="relative flex flex-col gap-4 mx-auto">
            <div>
                <div className={`flex gap-3 ${verticalLabels ? '' : 'w-fit'} `} style={{ margin: `0px ${verticalLabels ? `calc(100%/${labels.length}/2 - ${largeIcon ? 12 : 10}px)` : 'auto'}` }}>
                    {labels.map((label, index) => {
                        return <Fragment key={`progress-tracker-${label}-${index}`}>
                            {index > 0 && (
                                verticalLabels ?
                                    <Divider />
                                    :
                                    <div className={`h-[1px] min-w-[48px] bg-system-border-regular my-auto`}></div>
                            )}
                            {currentLabels.includes(label) ?
                                <div className="flex gap-2 shrink-0 items-center">
                                    <div className={`flex items-center justify-center ${largeIcon ? 'size-6' : 'size-5'} shrink-0 rounded-sm bg-system-primary`}>
                                        <TypographyLabel className="text-system-secondary">
                                            {index + 1}
                                        </TypographyLabel>
                                    </div>
                                    {!verticalLabels && (
                                        <TypographyLabel className="line-clamp-1 break-all">
                                            {label}
                                        </TypographyLabel>
                                    )}
                                </div>
                                :
                                completedLabels.includes(label) ?
                                    <div className="flex gap-2 shrink-0 items-center">
                                        <div className={`flex items-center justify-center ${largeIcon ? 'size-6' : 'size-5'} shrink-0 rounded-sm border border-system-body bg-system-border-light`}>
                                            <Checkmark className={largeIcon ? 'size-6' : 'size-5'} />
                                        </div>
                                        {!verticalLabels && (
                                            <TypographyLabel className="line-clamp-1 break-all">
                                                {label}
                                            </TypographyLabel>
                                        )}
                                    </div>
                                    :
                                    <div className="flex gap-2 shrink-0 items-center">
                                        <div className={`flex items-center justify-center ${largeIcon ? 'size-6' : 'size-5'} shrink-0 rounded-sm border border-system-body`}>
                                            <TypographyLabel className="text-system-body">
                                                {index + 1}
                                            </TypographyLabel>
                                        </div>
                                        {!verticalLabels && (
                                            <TypographyLabel className="line-clamp-1 break-all">
                                                {label}
                                            </TypographyLabel>
                                        )}
                                    </div>
                            }
                        </Fragment>
                    })}
                </div>
            </div>
            {verticalLabels && (
                <div className="flex gap-2">
                    {labels.map((label, index) => {
                        return <TypographyBody key={`progress-tracker-label-${label}-${index}`} isStrong={true} className="w-full text-center line-clamp-1 break-all">
                            {label}
                        </TypographyBody>
                    })}
                </div>
            )}
            {onCancel && (
                <div className="absolute top-[50%] -translate-y-[50%] left-0">
                    {customCancelButton ?
                        customCancelButton(onCancel)
                        :
                        <Button variant='tertiary' onClick={onCancel}>
                            Cancel
                        </Button>
                    }
                </div>
            )}
        </div>
    )
}