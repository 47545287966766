import { ReactNode, createContext, useEffect, useState } from "react";
import { UserFeatureFlag } from "../types/types";
import { userFeatureFlags } from "../constants";
import { checkUserFlag, setUserFlag } from "../utils/utils";

// todo: considering refactoring this into context user > flags 
type UserFeatureFlags = { flag: UserFeatureFlag, isEnabled: boolean };

export interface IFeatureFlagContext {
    userFlags: UserFeatureFlags[],
    toggleFlag: (flag: UserFeatureFlag) => void,
    checkFlag: (flag: UserFeatureFlag) => boolean,
}
interface IProviderProps {
    children: ReactNode;
}
const initialContextValue = {
    userFlags: [],
    toggleFlag: () => {},
    checkFlag: () => false,
} satisfies IFeatureFlagContext

export const FeatureFlagContext = createContext<IFeatureFlagContext>(initialContextValue);

function FeatureFlagProvider({ children }: IProviderProps) {
    const [userFlags, setUserFlags] = useState<UserFeatureFlags[]>([]);
    
    function toggleFlag(flag: UserFeatureFlag) {
        setUserFlags((prev) => {
            return prev.map(ff => {
                if (ff.flag === flag) {
                    return {
                        flag: ff.flag,
                        isEnabled: !ff.isEnabled
                    }
                }
                return ff
            })
        })
        const isEnabled = checkUserFlag(flag);
        setUserFlag(flag, !isEnabled);
    }

    function checkFlag(flag: UserFeatureFlag) {
        const uf = userFlags.find(ff => ff.flag === flag);
        if (!uf) return false;
        return uf.isEnabled;
    }

    useEffect(() => {
        let holdingFlags: UserFeatureFlags[] = [];
        for (let i = 0; i < userFeatureFlags.length; i++) {
            const flag = userFeatureFlags[i];
            const isEnabled = checkUserFlag(flag);
            holdingFlags.push({ flag, isEnabled })
        }
        setUserFlags(holdingFlags);
    }, [])

    return (
        <FeatureFlagContext.Provider value={{
            userFlags,
            toggleFlag,
            checkFlag
        }}>
            {children}
        </FeatureFlagContext.Provider>
    )
}

export { FeatureFlagProvider };
