import { SourceDocument } from "@/types/types"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import { getGlobalUniqueDocuments } from "@/utils/components";
import { Source } from "../Source";
import { Checkbox } from "../ui/checkbox";
import { Dispatch, SetStateAction, useState } from "react";

interface IProps {
    documents: SourceDocument[];
    showTabs: boolean
    selectable?: boolean
    selectedDocuments?: SourceDocument[]
    previewable?: boolean
    setSelectedDocuments?: Dispatch<SetStateAction<SourceDocument[]>>
    onSourceClick?: (source: SourceDocument) => void
}

// todo: consider unique documents as prop
// todo: support tabbing through links
export function Sources({ documents, showTabs, selectable, selectedDocuments = [], previewable, setSelectedDocuments, onSourceClick }: IProps) {
    const [selectedTab, setSelectedTab] = useState<'all' | 'internal' | 'webpages'>('all')

    const uniqueDocuments = getGlobalUniqueDocuments(documents);
    const webpages = uniqueDocuments.filter(d => d.document_id.toLowerCase().includes("web"));
    const internalDocs = uniqueDocuments.filter(d => !d.document_id.toLowerCase().includes("web"));

    const hasWebpages = webpages.length > 0;
    const hasInternalDocs = internalDocs.length > 0;

    const isAllWebSelected = webpages.every((v) => selectedDocuments?.includes(v))
    const isAllInternalSelected = internalDocs.every((v) => selectedDocuments?.includes(v))

    const isSomeWebSelected = webpages.some((v) => selectedDocuments?.includes(v))
    const isSomeInternalSelected = internalDocs.some((v) => selectedDocuments?.includes(v))

    const toggleSource = (document: SourceDocument) => {
        if (selectedDocuments?.includes(document)) {
            setSelectedDocuments?.(selectedDocuments.filter((v) => v !== document))
        } else {
            setSelectedDocuments?.([...selectedDocuments, document])
        }
    }

    const toggleWebpages = () => {
        if (isAllWebSelected) {
            setSelectedDocuments?.(selectedDocuments.filter((v) => !webpages.includes(v)))
        } else {
            setSelectedDocuments?.([...selectedDocuments.filter((v) => !webpages.includes(v)), ...webpages])
        }
    }

    const toggleInternalDocuments = () => {
        if (isAllInternalSelected) {
            setSelectedDocuments?.(selectedDocuments.filter((v) => !internalDocs.includes(v)))
        } else {
            setSelectedDocuments?.([...selectedDocuments.filter((v) => !internalDocs.includes(v)), ...internalDocs])
        }
    }

    const checkIfPreviewIsAvailable = (source: SourceDocument) => {
        const validTypes = ['pdf']

        const isValidFileType = validTypes.includes(source.doc_metadata?.document_type_friendly?.toLowerCase() || '') || source.title?.endsWith('.pdf')
        const isWeb = (source.doc_metadata?.document_type_friendly?.toLowerCase() || '') === 'web' || source.document_id.slice(0,3) === 'web'
        const isOutlook = source.document_id.startsWith('microsoft_outlook')
        const isTeams = source.document_id.startsWith('microsoft_teams')
        return (isValidFileType || isWeb || isOutlook || isTeams) && previewable
    }

    return (
        <div className="flex flex-col gap-6">
            {showTabs ?
                <Tabs defaultValue='all' value={selectedTab} className="rounded-md">
                    <TabsList className="mb-6">
                        <TabsTrigger value="all" onClick={() => setSelectedTab('all')}>All</TabsTrigger>

                        {hasInternalDocs && <TabsTrigger value="internal" onClick={() => setSelectedTab('internal')}>
                            <div className="flex gap-2">
                                {selectable && (
                                    <Checkbox
                                        checked={isAllInternalSelected || isSomeInternalSelected}
                                        partial={(isSomeInternalSelected && !isAllInternalSelected) || undefined}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            toggleInternalDocuments()
                                        }}
                                    />
                                )}

                                Library
                            </div>
                        </TabsTrigger>}
                        {hasWebpages && <TabsTrigger value="webpages" onClick={() => setSelectedTab('webpages')}>
                            <div className="flex gap-2">
                                {selectable && (
                                    <Checkbox
                                        checked={isAllWebSelected || isSomeWebSelected}
                                        partial={(isSomeWebSelected && !isAllWebSelected) || undefined}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            toggleWebpages()
                                        }}
                                    />
                                )}

                                Web
                            </div>
                        </TabsTrigger>}
                    </TabsList>

                    <TabsContent value="all" className={`flex flex-col gap-4 overflow-y-auto`}>
                        {uniqueDocuments.map((d, index) =>
                            <Source
                                key={`${d.document_id}-${index}`}
                                id={d.document_id}
                                url={d.url || ''}
                                title={d.title}
                                text={d.text}
                                showBorder={true}
                                metadata={d.doc_metadata}
                                showCheckbox={selectable}
                                selected={selectedDocuments.includes(d)}
                                onSelect={() => toggleSource(d)}
                                onClick={() => onSourceClick?.(d)}
                                previewable={checkIfPreviewIsAvailable(d)}
                            />
                        )}
                    </TabsContent>

                    {hasWebpages && (
                        <TabsContent value="webpages" className={`flex flex-col gap-4 overflow-y-auto`}>
                            {webpages.map((d, index) =>
                                <Source
                                    key={`${d.document_id}-${index}`}
                                    id={d.document_id}
                                    url={d.url || ''}
                                    title={d.title}
                                    text={d.text}
                                    showBorder={true}
                                    metadata={d.doc_metadata}
                                    showCheckbox={selectable}
                                    selected={selectedDocuments.includes(d)}
                                    onSelect={() => toggleSource(d)}
                                    onClick={() => onSourceClick?.(d)}
                                    previewable={checkIfPreviewIsAvailable(d)}
                                />
                            )}
                        </TabsContent>
                    )}

                    {hasInternalDocs && (
                        <TabsContent value="internal" className={`flex flex-col gap-4 overflow-y-auto`}>
                            {internalDocs.map((d, index) =>
                                <Source
                                    key={`${d.document_id}-${index}`}
                                    id={d.document_id}
                                    url={d.url || ''}
                                    title={d.title}
                                    text={d.text}
                                    showBorder={true}
                                    metadata={d.doc_metadata}
                                    showCheckbox={selectable}
                                    selected={selectedDocuments.includes(d)}
                                    onSelect={() => toggleSource(d)}
                                    onClick={() => onSourceClick?.(d)}
                                    previewable={checkIfPreviewIsAvailable(d)}
                                />
                            )}
                        </TabsContent>
                    )}
                </Tabs>
                :
                <div className="flex flex-col gap-4">
                    {uniqueDocuments.map((d, index) =>
                        <Source
                            key={`${d.document_id}-${index}`}
                            id={d.document_id}
                            url={d.url || ''}
                            title={d.title}
                            text={d.text}
                            showBorder={true}
                            metadata={d.doc_metadata}
                            showCheckbox={selectable}
                            selected={selectedDocuments.includes(d)}
                            onSelect={() => toggleSource(d)}
                            onClick={() => onSourceClick?.(d)}
                            previewable={checkIfPreviewIsAvailable(d)}
                        />
                    )}
                </div>
            }
        </div>
    )
}
