import { EllipsisVertical } from "lucide-react";
import { Button } from "../ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { useState } from "react";
import { DossierDeleteDialog } from "./DossierDeleteDialog";
import { Dossier } from "@/types/types";

export const DossierDropdownMenu = ({ dossier }: { dossier: Dossier }) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center" asChild>
                    <Button variant='tertiary' className="child-hover" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        <EllipsisVertical className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <DropdownMenuItem
                        variant='tertiary-destructive'
                        onClick={(e) => {
                            e.stopPropagation()
                            setShowDeleteDialog(true)
                        }}>
                        Delete dossier
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>

            <DossierDeleteDialog dossier={dossier} open={showDeleteDialog} setOpen={(v) => setShowDeleteDialog(v)} />
        </>
    )
}