import { ResponseChartData } from "@/types/types"
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts"
import { useEffect, useRef, useState } from "react"
import { ChartHeader } from "./ChartHeader";

export const ResponseLineChart = ({ id, parsedData, series, options, compact }: {
    id: string
    parsedData: ResponseChartData
    series: ApexAxisChartSeries,
    options: ApexOptions,
    compact?: boolean
}) => {

    const [labelsRotated, setLabelsRotated] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    const lineOptions: ApexOptions = {
        ...options,
        chart: {
            ...options.chart,
            type: 'line',
        },
        xaxis: {
            ...options.xaxis,
            labels: {
                ...options.xaxis?.labels,
                offsetY: labelsRotated ? 8 : 0,
                trim: false
            },
            tickAmount: 5,
        }
    }

    useEffect(() => {
        setTimeout(() => {
            const element = document.querySelector(".apexcharts-xaxis-label")
            if (element) {
                const style = window.getComputedStyle(element)
                const hasTransform = style.transform !== "none"
                setLabelsRotated(hasTransform)
            }
        }, 1000)
    }, [])

    return (
        <div className={`flex flex-col gap-0 p-4 ${compact ? 'mt-8' : 'mt-10'} relative group`}>
            <ChartHeader chartRef={ref} parsedData={parsedData} compact={compact} />

            <div ref={ref} id={`chart-container-${id}`} className="[&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary">
                <Chart
                    options={lineOptions}
                    series={series}
                    type='line'
                />
            </div>
        </div>
    )
}