import { Section } from '@/types/types'

export const findThemePosition = (
  sections: Section[],
  title: string,
  level: number = 0,
  path: number[] = []
): {
  level: number
  indexPath: number[]
} | null => {
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i]
    const currentPath = [...path, i]

    if (section.title === title) {
      return { level, indexPath: currentPath }
    }

    if (section.subsections && section.subsections.length > 0) {
      const result = findThemePosition(
        section.subsections,
        title,
        level + 1,
        currentPath
      )
      if (result) {
        return result
      }
    }
  }

  return null
}
