import { APIResponse, ASYNC_STATUS, Citation, Conversation, DocgenSession, DocgenTemplate, DocgenTheme, EditReport, QueryStatus, RequestAssistantAsk, RequestDocGenDeleteReport, RequestDocGenGetReport, RequestDocGenUpdateReport, RequestEntityExtraction, ResponseChatStream, ResponseDocGenDeleteReport, ResponseDocGenReport, SourceDocument, SystemMessage, UserMessage, WebSocketRequest, WebSocketRequestWrapper, WebSocketResponseWrapper } from '@/types/types';
import { handleError } from '@/utils/handleError';
import { OutputData } from '@editorjs/editorjs';
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createTemplate, createTheme, deleteTemplate, fetchSessions, fetchTemplates, fetchThemes, updateTemplate } from './docGenThunk';

type DocGenInlineRequest = {
  requestId: string | null;
  panelOpen: boolean,
  panelPosition: {
    y: number
  },
  status: 'idle' | 'loading' | 'error' | 'success';
  data: {
    request: {
      text: string;
    },
    response: {
      text: string;
      citations: Citation[];
      documents: SourceDocument[];
    }
  } | null;
}

type DocGenAsk = {
  panelPosition: {
    y: number;
  },
  panelOpen: boolean;
  byReportId: {
    [reportId: string]: {
      request: {
        requestIds: string[];
      },
      response: {
        conversationId: string;
        conversation: Conversation;
      }
    }
  }
}

export interface DocGenState {
  selectedText: string;
  factCheck: DocGenInlineRequest;
  research: DocGenInlineRequest;
  ask: DocGenAsk;
  // generic panelTool state
  cursorTool: {
    componentType: 'ask' | null;
  },
  panelTool: {
    cursor: {
      x: number;
      y: number;
    },
    componentType: 'factCheck' | 'research' | 'ask' | null;
  },
  reports: APIResponse<ResponseDocGenReport[]>,
  createReport: APIResponse<ResponseDocGenReport>,
  deleteReport: APIResponse<ResponseDocGenDeleteReport>,
  editReportById: {
    [reportId: string]: EditReport;
  },
  templates: DocgenTemplate[]
  templateListStatus: QueryStatus
  templateStatus: {
    [id: string]: QueryStatus
  }
  createTemplateStatus: QueryStatus
  updateTemplateStatus: {
    [id: string]: QueryStatus
  }
  deleteTemplateStatus: {
    [id: string]: QueryStatus
  }
  sessions: {
    data: DocgenSession[],
    status: QueryStatus,
  }
  themes: {
    data: DocgenTheme[]
    status: QueryStatus
  }
  createThemeStatus: QueryStatus
  openedCitation: Citation | null
}

const initialState: DocGenState = {
  selectedText: "",
  factCheck: {
    panelOpen: false,
    panelPosition: {
      y: 0
    },
    requestId: null,
    status: 'idle',
    data: null,
  },
  research: {
    panelOpen: false,
    panelPosition: {
      y: 0
    },
    requestId: null,
    status: 'idle',
    data: null,
  },
  ask: {
    panelOpen: false,
    panelPosition: {
      y: 0
    },
    // data: null,
    byReportId: {},
  },
  cursorTool: {
    componentType: null,
  },
  panelTool: {
    cursor: {
      x: -Infinity,
      y: -Infinity,
    },
    componentType: null,
  },
  reports: {
    loading: false,
    error: null,
    data: null,
  },
  createReport: {
    loading: false,
    error: null,
    data: null,
  },
  deleteReport: {
    loading: false,
    error: null,
    data: null,
  },
  editReportById: {},
  templates: [],
  templateListStatus: QueryStatus.INITIALISED,
  templateStatus: {},
  createTemplateStatus: QueryStatus.INITIALISED,
  updateTemplateStatus: {},
  deleteTemplateStatus: {},
  sessions: {
    data: [],
    status: QueryStatus.INITIALISED
  },
  themes: {
    data: [],
    status: QueryStatus.INITIALISED
  },
  createThemeStatus: QueryStatus.INITIALISED,
  openedCitation: null
}

export const docGenSlice = createSlice({
  name: 'docGen',
  initialState,
  reducers: {
    // reports
    requestReports: (state, _: PayloadAction<WebSocketRequest>) => {
      state.reports.loading = true;
      state.reports.error = null;
    },
    responseReports: (state, action: PayloadAction<WebSocketResponseWrapper<ResponseDocGenReport[]>>) => {
      state.reports.loading = false;
      state.reports.error = action.payload.error;
      state.reports.data = action.payload.data;
    },
    // create report
    requestCreateReport: (state, _: PayloadAction<WebSocketRequest>) => {
      state.createReport.loading = true;
      state.createReport.error = null;
    },
    responseCreateReport: (state, action: PayloadAction<WebSocketResponseWrapper<ResponseDocGenReport>>) => {
      state.createReport.loading = false;
      state.createReport.error = action.payload.error;
      state.createReport.data = action.payload.data;

      if (!action.payload.error && action.payload.data) {
        if (!state.reports.data) {
          state.reports.data = [action.payload.data];
        } else {
          state.reports.data.unshift(action.payload.data);
        }
      }
    },
    resetCreateReportData: (state) => {
      state.createReport.data = null
    },
    requestDeleteReport: (state, __: PayloadAction<WebSocketRequestWrapper<RequestDocGenDeleteReport>>) => {
      state.deleteReport.loading = true;
      state.deleteReport.error = null;
    },
    responseDeleteReport: (state, action: PayloadAction<WebSocketResponseWrapper<ResponseDocGenDeleteReport>>) => {
      state.deleteReport.loading = false;
      state.deleteReport.error = action.payload.error;
      state.deleteReport.data = action.payload.data;

      if (!state.reports.data) return;
      if (action.payload.error) return; // todo: handle error
      state.reports.data = state.reports.data.filter(r => r.id !== action.payload.data?.report_id);
    },
    requestReportById: (state, action: PayloadAction<WebSocketRequestWrapper<RequestDocGenGetReport>>) => {
      state.editReportById[action.payload.params.report_id] = {
        server: {
          loading: true,
          error: null,
          data: null,
        },
        client: {
          title: "",
          report: { blocks: [] },
        },
        lastSave: null,
        entityExtraction: {
          status: ASYNC_STATUS.idle,
        }
      }
    },
    responseReportById: (state, action: PayloadAction<WebSocketResponseWrapper<ResponseDocGenReport>>) => {
      if (!state.editReportById[action.payload.requestId]) return;

      state.editReportById[action.payload.requestId].server.loading = false;

      if (action.payload.error) {
        state.editReportById[action.payload.requestId].server.error = action.payload.error;
        return;
      }

      if (!action.payload.data) {
        console.error("responseReportById: missing data", action.payload);
      }

      state.editReportById[action.payload.requestId].server.data = action.payload.data;

      const extractedEntities = localStorage.getItem(`entities_${action.payload.requestId}`);
      state.editReportById[action.payload.requestId].client = {
        title: action.payload.data!.title,
        extractedEntities: extractedEntities ? JSON.parse(extractedEntities) : undefined,
        report: action.payload.data?.content ? JSON.parse(action.payload.data.content) : { blocks: [] },
      }
    },
    requestEntityExtraction: (state, action: PayloadAction<WebSocketRequestWrapper<RequestEntityExtraction>>) => {
      const reportId = action.payload.params.reportId;
      if (state.editReportById[reportId]) {
        state.editReportById[reportId].entityExtraction.status = ASYNC_STATUS.loading;
      }
    },
    responseEntityExtraction: (state, action: PayloadAction<WebSocketResponseWrapper<ResponseChatStream>>) => {
      /**
       * example responses:
       * "{\n    \"entities\": [\n        {\n            \"entity\": \"Amazon\",\n            \"type\": \"company\",\n            \"isMainEntity\": true,\n            \"accuracy\": 1\n        },\n        {\n            \"entity\": \"Walmart\",\n            \"type\": \"company\",\n            \"isMainEntity\": false,\n            \"accuracy\": 1\n        },\n        {\n            \"entity\": \"Shein\",\n            \"type\": \"company\",\n            \"isMainEntity\": false,\n            \"accuracy\": 1\n        },\n        {\n            \"entity\": \"Temu\",\n            \"type\": \"company\",\n            \"isMainEntity\": false,\n            \"accuracy\": 1\n        }\n    ],\n    \"extractionDateTime\": \"2024-09-09T11:02:58Z\"\n}"
       * "```json\n{\n  \"entities\": [\n    {\n      \"entity\": \"Amazon\",\n      \"type\": \"company\",\n      \"isMainEntity\": true,\n      \"accuracy\": 1\n    },\n    {\n      \"entity\": \"Walmart\",\n      \"type\": \"company\",\n      \"isMainEntity\": false,\n      \"accuracy\": 1\n    },\n    {\n      \"entity\": \"Shein\",\n      \"type\": \"company\",\n      \"isMainEntity\": false,\n      \"accuracy\": 1\n    },\n    {\n      \"entity\": \"Temu\",\n      \"type\": \"company\",\n      \"isMainEntity\": false,\n      \"accuracy\": 1\n    },\n    {\n      \"entity\": \"Target\",\n      \"type\": \"company\",\n      \"isMainEntity\": false,\n      \"accuracy\": 1\n    }\n  ],\n  \"extractionDateTime\": \"2024-10-09T09:09:16Z\"\n}\n```"
       */
      const parseEntities = (text: string) => {
        try {
          let parsed = text;
          parsed = parsed.replaceAll("\\n", "");
          parsed = parsed.replaceAll("```", "");
          parsed = parsed.replaceAll("```json", "");
          parsed = parsed.replaceAll("\\", "");

          return JSON.parse(parsed);
        } catch (e) {
          handleError(e)
        }
      }
      if (action.payload.error) {
        console.error("responseEntityExctraction: error", action.payload);
        return;
      }

      if (action.payload.data && action.payload.data.is_finished === true && action.payload.data.reportId) {
        if (state.editReportById[action.payload.data.reportId]) {
          const parsedEntities = {
            ...parseEntities(action.payload.data.body.text || "{}"),
            extractionDateTime: (new Date()).toISOString(),
          }
          // todo: store in db
          localStorage.setItem(`entities_${action.payload.data.reportId}`, JSON.stringify(parsedEntities));
          state.editReportById[action.payload.data.reportId].client.extractedEntities = parsedEntities;
          state.editReportById[action.payload.data.reportId].entityExtraction.status = ASYNC_STATUS.success;
        }
      }
    },
    updateLocalReportTitle: (state, action: PayloadAction<{ reportId: string, title: string }>) => {
      if (state.editReportById[action.payload.reportId]) {
        state.editReportById[action.payload.reportId].client.title = action.payload.title;
      }
    },
    updateLocalReportData: (state, action: PayloadAction<{ reportId: string, data: OutputData }>) => {
      if (state.editReportById[action.payload.reportId]) {
        state.editReportById[action.payload.reportId].client.report = action.payload.data;
      }
    },
    requestSaveReport(state, action: PayloadAction<WebSocketRequestWrapper<RequestDocGenUpdateReport>>) {
      if (state.editReportById[action.payload.params.report_id]) {
        state.editReportById[action.payload.params.report_id].lastSave = {
          requestId: action.payload.requestId,
          loading: true,
          error: null,
          timestamp: action.payload.timestamp,
        }
      }
    },
    responseSaveReport(state, action: PayloadAction<WebSocketResponseWrapper<ResponseDocGenReport>>) {
      if (state.editReportById[action.payload.requestId]) {
        state.editReportById[action.payload.requestId].lastSave = {
          requestId: action.payload.requestId,
          loading: false,
          error: action.payload.error,
          timestamp: action.payload.timestamp,
        }

        state.editReportById[action.payload.requestId].server.data = action.payload.data;
      }
    },
    // factcheck
    setSelectedText: (state, action: PayloadAction<string>) => {
      state.selectedText = action.payload;
    },
    requestFactCheck: (state, action: PayloadAction<WebSocketRequestWrapper<RequestAssistantAsk>>) => {
      state.factCheck.status = 'loading';
      state.factCheck.requestId = action.payload.requestId,
        state.factCheck.data = {
          request: {
            text: action.payload.params.message
          },
          response: {
            text: "",
            citations: [],
            documents: []
          }
        }
    },
    responseFactCheck: (state, action: PayloadAction<WebSocketResponseWrapper<ResponseChatStream>>) => {
      if (state.factCheck.data && state.factCheck.requestId === action.payload.requestId) {
        if (action.payload.error) {
          state.factCheck.status = 'error';
          return;
        }
        if (action.payload.data?.is_finished === true) {
          state.factCheck.status = 'success';
        }
        if (action.payload.data?.body.documents?.length) {
          state.factCheck.data.response.documents = action.payload.data.body.documents;
        }
        if (action.payload.data?.body.citations?.length) {
          state.factCheck.data.response.citations = [...(state.factCheck.data.response.citations || []), ...action.payload.data.body.citations];
        }
        if (action.payload.data?.body.text) {
          state.factCheck.data.response.text = action.payload.data.body.text;
        }
      }
    },
    // state is maintained in the same way as factCheck
    requestFactCheckV2: (state, action: PayloadAction<WebSocketRequestWrapper<RequestAssistantAsk>>) => {
      state.factCheck.status = 'loading';
      state.factCheck.requestId = action.payload.requestId,
        state.factCheck.data = {
          request: {
            text: action.payload.params.message
          },
          response: {
            text: "",
            citations: [],
            documents: []
          }
        }
    },
    responseFactCheckV2: (state, action: PayloadAction<WebSocketResponseWrapper<ResponseChatStream>>) => {
      if (state.factCheck.data && state.factCheck.requestId === action.payload.requestId) {
        if (action.payload.error) {
          state.factCheck.status = 'error';
          return;
        }
        if (action.payload.data?.is_finished === true) {
          state.factCheck.status = 'success';
        }
        if (action.payload.data?.body.documents?.length) {
          state.factCheck.data.response.documents = action.payload.data.body.documents;
        }
        if (action.payload.data?.body.citations?.length) {
          state.factCheck.data.response.citations = [...(state.factCheck.data.response.citations || []), ...action.payload.data.body.citations];
        }
        if (action.payload.data?.body.text) {
          state.factCheck.data.response.text = action.payload.data.body.text;
        }
      }
    },
    clearFactCheck: (state) => {
      state.factCheck.status = 'idle';
      state.factCheck.data = null;
      state.factCheck.requestId = null;
    },
    // research
    requestResearch: (state, action: PayloadAction<WebSocketRequestWrapper<RequestAssistantAsk>>) => {
      state.research.status = 'loading';
      state.research.requestId = action.payload.requestId,
        state.research.data = {
          request: {
            text: action.payload.params.message
          },
          response: {
            text: "",
            citations: [],
            documents: []
          }
        }
    },
    responseResearch: (state, action: PayloadAction<WebSocketResponseWrapper<ResponseChatStream>>) => {
      if (state.research.data && state.research.requestId === action.payload.requestId) {
        if (action.payload.error) {
          state.research.status = 'error';
          return;
        }
        if (action.payload.data?.is_finished === true) {
          state.research.status = 'success';
        }
        if (action.payload.data?.body.documents?.length) {
          state.research.data.response.documents = action.payload.data.body.documents;
        }
        if (action.payload.data?.body.citations?.length) {
          state.research.data.response.citations = [...(state.research.data.response.citations || []), ...action.payload.data.body.citations];
        }
        if (action.payload.data?.body.text) {
          state.research.data.response.text = action.payload.data.body.text;
        }
      }
    },
    clearResearch: (state) => {
      state.research.status = 'idle';
      state.research.data = null;
      state.research.requestId = null;
    },
    // ask
    newAsk: (state, action: PayloadAction<WebSocketRequestWrapper<{ message: string, context?: string, reportId?: string }>>) => {
      let data = state.ask.byReportId[action.payload.params.reportId!];
      const userMessage: UserMessage = {
        conversationId: "",
        requestId: action.payload.requestId,
        query: action.payload.params.message,
        timestamp: action.payload.timestamp,
        mode: 'simple',
        role: "user",
      };
      const placeholderSystemMessage: SystemMessage = {
        conversationId: "",
        requestId: action.payload.requestId,
        timestamp: action.payload.timestamp,
        data: {
          isFinished: false,
        },
        role: 'system',
      };
      data?.request.requestIds.push(action.payload.requestId);
      data = {
        request: {
          requestIds: [action.payload.requestId],
        },
        response: {
          conversationId: "",
          conversation: [
            userMessage,
            placeholderSystemMessage,
          ]
        }
      }
      state.ask.byReportId[action.payload.params.reportId!] = data;
    },
    followUpAsk: (state, action: PayloadAction<WebSocketRequestWrapper<{ conversationId: string, message: string, context?: string, reportId?: string }>>) => {
      if (!action.payload.params.reportId) {
        console.error("missing reportId in followUpAsk", action.payload);
      }

      const data = state.ask.byReportId[action.payload.params.reportId!];

      const isCurrentConversation = (data.response.conversationId === action.payload.params.conversationId) || (data.request.requestIds.includes(action.payload.requestId));
      if (!isCurrentConversation) return;

      data.request.requestIds.concat(action.payload.requestId);
      data.response.conversationId = action.payload.params.conversationId;
      const userMessage: UserMessage = {
        query: action.payload.params.message,
        conversationId: action.payload.params.conversationId,
        role: "user",
        timestamp: action.payload.timestamp,
      }
      const systemMessage: SystemMessage = {
        conversationId: action.payload.params.conversationId,
        requestId: action.payload.requestId,
        timestamp: action.payload.timestamp,
        role: 'system',
        data: {
          isFinished: false,
        }
      }
      data.response.conversation.push(userMessage, systemMessage)
    },
    responseAsk: (state, action: PayloadAction<WebSocketResponseWrapper<ResponseChatStream>>) => {
      if (!action.payload.data?.reportId) {
        console.error("missing reportId in responseAsk", action.payload);
        return;
      }

      const data = state.ask.byReportId[action.payload.data.reportId];

      if (!data) return;
      if (!action.payload.data) return;

      const isActiveChat = data?.request.requestIds.includes(action.payload.requestId!) || (data?.response.conversationId === action.payload.data.conversation_id);
      if (!isActiveChat) return;

      const messageToUpdate = data?.response.conversation.find(c => c.requestId === action.payload.requestId && c.role === 'system' && c.data.isFinished !== true);
      if (!messageToUpdate) {
        console.warn("received response for unknown requestId", action.payload.requestId);
        return;
      }
      const m = messageToUpdate as SystemMessage;
      switch (action.payload.data!.event_type) {
        case 'stream-start': {
          data!.response.conversationId = action.payload.data!.conversation_id;
          m.conversationId = action.payload.data!.conversation_id;
          break;
        }
        case 'search-results': {
          m.data.documents = action.payload.data!.body.documents;
          break;
        }
        case 'text-generation': {
          m.data.text = action.payload.data!.body.text;
          break;
        }
        case 'citation-generation': {
          m.data.citations = [
            ...(m.data.citations || []),
            ...(action.payload.data!.body.citations || [])
          ]
          break;
        }
        case 'stream-end': {
          // todo: consider only setting finished flag
          m.data.text = action.payload.data!.body.text;
          m.data.documents = action.payload.data!.body.documents;
          m.data.citations = action.payload.data!.body.citations;
          m.data.isFinished = true;
          break;
        }
        case 'followup-question': {
          m.data.followUpQuestions = action.payload.data.body.questions
          break
        }

        default:
          break;
      }
    },
    setToolCursor: (state, action: PayloadAction<{ x: number, y: number }>) => {
      state.panelTool.cursor = action.payload;
    },
    setToolComponent: (state, action: PayloadAction<'factCheck' | 'research' | 'ask' | null>) => {
      state.panelTool.componentType = action.payload;
      if (action.payload) {
        state.openedCitation = null
      }
      if (action.payload === null) {
        // reset all tool state
        state.factCheck = initialState.factCheck;
        state.research = initialState.research;
      }
    },
    setCursorTool: (state, action: PayloadAction<'ask' | null>) => {
      state.cursorTool.componentType = action.payload;
    },
    addTemplate: (state, action: PayloadAction<DocgenTemplate>) => {
      state.templates = [...state.templates, action.payload];
    },
    removeTemplate: (state, action: PayloadAction<DocgenTemplate>) => {
      state.templates = state.templates.filter((v) => v.id !== action.payload.id)
    },
    replaceTemplate: (state, action: PayloadAction<DocgenTemplate>) => {
      const copy = [...state.templates]
      const index = copy.findIndex((v) => v.id === action.payload.id)
      copy[index] = action.payload
      state.templates = copy
    },
    removeSession: (state, action: PayloadAction<string>) => {
      state.sessions = {
        ...state.sessions,
        data: [...state.sessions.data.filter((v) => v.id !== action.payload)],
      }
    },
    updateOpenedCitation: (state, action: PayloadAction<Citation | null>) => {
      state.openedCitation = action.payload
      if (action.payload) {
        state.panelTool.componentType = null
      }
    },
    replaceSession: (state, action: PayloadAction<DocgenSession>) => {
      const copy = [...state.sessions.data]
      const index = copy.findIndex((v) => v.id === action.payload.id)
      copy[index] = action.payload
      state.sessions.data = copy
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplates.pending, (state) => {
        state.templateListStatus = QueryStatus.FETCHING
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.templateListStatus = QueryStatus.SUCCEEDED
        state.templates = action.payload
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.templateListStatus = QueryStatus.ERROR_FETCHING
        handleError(action.error)
      })
      .addCase(createTemplate.pending, (state) => {
        state.createTemplateStatus = QueryStatus.FETCHING
      })
      .addCase(createTemplate.fulfilled, (state) => {
        state.createTemplateStatus = QueryStatus.SUCCEEDED
      })
      .addCase(createTemplate.rejected, (state, action) => {
        state.createTemplateStatus = QueryStatus.ERROR_FETCHING
        handleError(action.error)
      })
      .addCase(updateTemplate.pending, (state, action) => {
        state.updateTemplateStatus[action.meta.arg.id] = QueryStatus.FETCHING
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.updateTemplateStatus[action.meta.arg.id] = QueryStatus.SUCCEEDED
      })
      .addCase(updateTemplate.rejected, (state, action) => {
        state.updateTemplateStatus[action.meta.arg.id] = QueryStatus.ERROR_FETCHING
        handleError(action.error)
      })
      .addCase(deleteTemplate.pending, (state, action) => {
        state.deleteTemplateStatus[action.meta.arg] = QueryStatus.FETCHING
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.deleteTemplateStatus[action.meta.arg] = QueryStatus.SUCCEEDED
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.deleteTemplateStatus[action.meta.arg] = QueryStatus.ERROR_FETCHING
        handleError(action.error)
      })
      .addCase(fetchSessions.pending, (state) => {
        state.sessions = {
          ...state.sessions,
          status: QueryStatus.FETCHING
        }
      })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        state.sessions = {
          data: action.payload,
          status: QueryStatus.SUCCEEDED
        }
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.sessions = {
          ...state.sessions,
          status: QueryStatus.ERROR_FETCHING
        }
        handleError(action.error)
      })
      .addCase(fetchThemes.pending, (state) => {
        state.themes = {
          ...state.themes,
          status: QueryStatus.FETCHING
        }
      })
      .addCase(fetchThemes.fulfilled, (state, action) => {
        state.themes = {
          data: action.payload,
          status: QueryStatus.SUCCEEDED
        }
      })
      .addCase(fetchThemes.rejected, (state, action) => {
        state.themes = {
          ...state.themes,
          status: QueryStatus.ERROR_FETCHING
        }
        handleError(action.error)
      })
      .addCase(createTheme.pending, (state) => {
        state.createThemeStatus = QueryStatus.FETCHING
      })
      .addCase(createTheme.fulfilled, (state) => {
        state.createThemeStatus = QueryStatus.SUCCEEDED
      })
      .addCase(createTheme.rejected, (state, action) => {
        state.createThemeStatus = QueryStatus.ERROR_FETCHING
        handleError(action.error)
      })
  },
})

export const {
  requestReports,
  responseReports,

  requestCreateReport,
  responseCreateReport,
  resetCreateReportData,

  requestDeleteReport,
  responseDeleteReport,

  requestReportById,
  responseReportById,

  updateLocalReportTitle,
  updateLocalReportData,

  requestSaveReport,
  responseSaveReport,

  setSelectedText,
  requestFactCheck,
  responseFactCheck,
  requestFactCheckV2,
  responseFactCheckV2,
  clearFactCheck,

  requestResearch,
  responseResearch,
  clearResearch,

  newAsk,
  followUpAsk,
  responseAsk,

  setToolCursor,
  setToolComponent,
  setCursorTool,

  requestEntityExtraction,
  responseEntityExtraction,

  addTemplate,
  removeTemplate,
  replaceTemplate,
  removeSession,
  updateOpenedCitation
} = docGenSlice.actions

export default docGenSlice.reducer
