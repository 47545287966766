import { ArrowRight, CircleHelpIcon, Sparkles } from 'lucide-react'
import { Button, tertiaryStyle } from '../ui/button'
import {
  TypographyBody,
  TypographyH3,
  TypographyH4,
  TypographyLabel,
} from '../ui/Typography'
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Badge } from '../ui/badge'
import { LoadingAnimation } from '../LoadingAnimation/LoadingAnimation'
import { LayoutContext } from '@/contexts/LayoutContext'
import { getFileIcon } from '@/utils/components'
import {
  DocgenCitation,
  DocgenContentTheme,
  DocgenSession,
  DocgenSessionStatus,
  DocgenTemplate,
  DocumentPreviewType,
  QueryStatus,
  RequestDocGenUpdateReport,
  ResponseDocGenReport,
  SourceDocument,
  WebSocketRequestWrapper,
} from '@/types/types'
import { CustomAlert } from '../CustomAlert'
import { ResponseMarkdown } from '../Assistant/ResponseMarkdown'
import { embedCitationsV4, getCitationIds } from '@/utils/embedCitations'
import { CitationFootnote } from './CitationFootnote'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { fetchSession, fetchTemplates } from './docGenThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { ViewSources } from '../Assistant/PreviewSources'
import { getTimestamp, mapSourceHighlights } from '@/utils/utils'
import { DocumentPreviewContainer } from '../Resources/DocumentPreview'
import { listDocuments } from '../Document/documentThunk'
import { OutputBlockData, OutputData } from '@editorjs/editorjs'
import * as editorjs from '@editorjs/editorjs'
import {
  requestCreateReport,
  requestSaveReport,
  resetCreateReportData,
} from './docGenSlice'
import { useNavigate } from 'react-router-dom'
import { DocGenThemeDialog } from './DocGenThemeDialog'

export const DocGenDraft = ({
  session,
  template,
  topic,
}: {
  session: DocgenSession | null
  template: DocgenTemplate | null
  topic: string | null
}) => {
  const { showWelcomeAnimation, toggleShowWelcomeAnimation } =
    useContext(LayoutContext)

  const documentStore = useSelector((state: RootState) => state.document)
  const createReport = useSelector(
    (state: RootState) => state.docGen.createReport
  )

  const [loading, setLoading] = useState(
    session?.status !== DocgenSessionStatus.CREATED
  )
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(false)
  const [currentSession, setCurrentSession] = useState<DocgenSession | null>(
    null
  )
  const [selectedSectionIndexes, setSelectedSectionIndexes] = useState<
    number[]
  >([])
  const [openedCitation, setOpenedCitation] = useState<DocgenCitation | null>(
    null
  )
  const [selectedSource, setSelectedSource] = useState<SourceDocument | null>(
    null
  )
  const [selectedExtractIndex, setSelectedExtractIndex] = useState<{
    [id: string]: number
  }>({})
  const [selectedSectionTheme, setSelectedSectionTheme] = useState<
    DocgenContentTheme[] | null
  >(null)

  const draftContainerRef = useRef<HTMLDivElement>(null)
  const interval = useRef<NodeJS.Timeout | null>(null)
  const sourceContainerRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const draftContainerHeight =
    draftContainerRef.current?.getBoundingClientRect().height || 0

  const allCitations = Object.values(
    currentSession?.content?.knowledge_base.citations || []
  ).flatMap((v) => v)

  const documents = useMemo(() => {
    return documentStore.files.filter((v) =>
      allCitations.find((c) => c.document_id === v.document_id)
    )
  }, [allCitations, documentStore.files])

  const sourceDocuments = useMemo(() => {
    return documents.map((doc) => {
      return {
        document_id: doc.document_id,
        doc_metadata: { ...doc },
        title: doc.document_name,
        url: doc.document_secure_shared_link,
        text: doc.document_name,
      }
    })
  }, [documents])

  const openedCitationHighlights = useMemo(() => {
    const highlights =
      openedCitation?.snippets.map((snippet) => {
        return {
          file_id: openedCitation.document_id,
          highlight: snippet,
          page_number: openedCitation.page_number || undefined,
        }
      }) || []
    const mappedHighlights = mapSourceHighlights(highlights)
    return mappedHighlights
  }, [openedCitation])

  const openedCitationResource = useMemo(() => {
    if (!selectedSource) return null
    return {
      extracts: openedCitationHighlights,
      document_type_friendly:
        selectedSource.doc_metadata?.document_type_friendly || '',
      id: selectedSource.document_id,
      title: selectedSource.title,
      text: selectedSource.text,
      url: selectedSource.url || '',
      document_link: selectedSource.doc_metadata?.external_link,
      doc_metadata: selectedSource.doc_metadata,
    }
  }, [selectedSource, openedCitationHighlights])

  const refetchSession = useCallback(async () => {
    if (!session?.id) return
    const result = await dispatch(fetchSession(session.id))

    if (isFulfilled(fetchSession)(result)) {
      const payload = result.payload
      setCurrentSession(payload)
      if (
        payload?.status === DocgenSessionStatus.CREATED ||
        payload.status === DocgenSessionStatus.DRAFT_ERROR
      ) {
        if (session.status !== payload.status) {
          setReady(true)
        } else {
          setLoading(false)
        }

        if (payload.status === DocgenSessionStatus.DRAFT_ERROR) {
          setError(true)
        }

        if (interval.current) {
          clearInterval(interval.current)
        }
      }
    }
  }, [dispatch, session?.id])

  const handleSectionSelection = (indexes: number[]) => {
    setSelectedSectionIndexes(indexes)

    const element = document.getElementById(
      `draft-content-${indexes.join('-')}`
    )

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const convertDocgenCitationsToCitations = (citations: DocgenCitation[]) => {
    return citations.map((citation) => {
      return {
        text: '',
        start: 0,
        end: 0,
        document_ids: [citation.document_id],
        highlights: [
          {
            file_id: citation.document_id,
            highlight: citation.snippets,
            page_number: citation.page_number,
          },
        ],
        citation_uuid: citation.citation_uuid,
      }
    })
  }

  const getBlockData = (text: string) => {
    const citationIds = getCitationIds(text)
    const embeddedText = embedCitationsV4(text, allCitations)
    const sectionCitations = allCitations.filter((v) =>
      citationIds.includes(v.citation_uuid)
    )
    const citationDocuments = sourceDocuments.filter((v) =>
      sectionCitations.some((c) => c.document_id === v.document_id)
    )

    return {
      text: embeddedText,
      citations: convertDocgenCitationsToCitations(sectionCitations),
      documents: citationDocuments,
    }
  }

  const convertTreeToBlocks = () => {
    const blocks: OutputBlockData[] = []

    currentSession?.content?.knowledge_base.tree.children.forEach((section) => {
      blocks.push({
        id: crypto.randomUUID(),
        type: 'FinalAnswer',
        data: getBlockData(`<h3>${section.name}</h3>`),
      })

      blocks.push({
        id: crypto.randomUUID(),
        type: 'FinalAnswer',
        data: getBlockData(section.synthesize_output || ''),
      })

      section.children.forEach((section) => {
        blocks.push({
          id: crypto.randomUUID(),
          type: 'FinalAnswer',
          data: getBlockData(`<h4>${section.name}</h4>`),
        })

        blocks.push({
          id: crypto.randomUUID(),
          type: 'FinalAnswer',
          data: getBlockData(section.synthesize_output || ''),
        })

        section.children.forEach((section) => {
          blocks.push({
            id: crypto.randomUUID(),
            type: 'FinalAnswer',
            data: getBlockData(`<strong>${section.name}</strong>`),
          })

          blocks.push({
            id: crypto.randomUUID(),
            type: 'FinalAnswer',
            data: getBlockData(section.synthesize_output || ''),
          })
        })
      })
    })

    return blocks
  }

  const handleCreateReport = async () => {
    if (createReport.loading) return
    dispatch(
      requestCreateReport({
        requestId: 'docgen:create_report',
        params: {},
        timestamp: getTimestamp(),
      })
    )
  }

  const handleUpdateReport = (report: ResponseDocGenReport) => {
    const outputData: OutputData = {
      version: editorjs.default.version,
      time: getTimestamp(),
      blocks: convertTreeToBlocks(),
    }

    const request: WebSocketRequestWrapper<RequestDocGenUpdateReport> = {
      requestId: report.id,
      timestamp: getTimestamp(),
      params: {
        report_id: report.id,
        title: topic || '',
        content: JSON.stringify(outputData),
      },
    }

    dispatch(requestSaveReport(request))
    toggleShowWelcomeAnimation(true)

    setTimeout(() => {
      navigate(`/report/${report.id}?docgen=true`)
    }, 3000)

    dispatch(resetCreateReportData())
  }

  useEffect(() => {
    refetchSession()

    interval.current = setInterval(() => refetchSession(), 5000)

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [session, refetchSession])

  useEffect(() => {
    if (!template) {
      dispatch(fetchTemplates())
    }
  }, [template, dispatch])

  useEffect(() => {
    if (documentStore.fetchStatus === QueryStatus.INITIALISED) {
      dispatch(listDocuments())
    }
  }, [documentStore.fetchStatus, dispatch])

  useEffect(() => {
    if (createReport.data) {
      handleUpdateReport(createReport.data)
    }
  }, [createReport.data])

  useEffect(() => {
    if (documentStore.fetchStatus === QueryStatus.INITIALISED) {
      dispatch(listDocuments())
    }
  }, [documentStore.fetchStatus, dispatch])

  return (
    <>
      <div
        className={`flex flex-col ${loading ? 'gap-[54px]' : 'gap-6'} w-full ${!loading && !error ? 'min-h-screen' : ''} mx-auto`}
      >
        {loading && (
          <div className="flex flex-col gap-1 text-center mx-auto">
            <div className="flex gap-2 mx-auto items-center">
              <TypographyH4>{`Generating ${topic || 'topic'}`}</TypographyH4>

              <Badge>
                <div className="flex gap-2 items-center">
                  <Sparkles className="size-4 shrink-0 stroke-[1.5px] stroke-badge-blue-graphic" />
                  <span className="line-clamp-1 break-all">
                    {template?.title || ''}
                  </span>
                </div>
              </Badge>
            </div>
            <TypographyBody className="text-system-body whitespace-pre-wrap">
              {
                'We are cross-checking multiple data sources to ensure all data is\naccurate and up-to-date, hence this step takes a while'
              }
            </TypographyBody>
          </div>
        )}

        {loading && !ready && (
          <div className="flex flex-col gap-6 w-[474px] mx-auto p-6 bg-system-secondary border border-system-border-regular rounded-[12px]">
            <div className="flex gap-4 items-center">
              <img
                className="size-7 animate-logo-animation"
                src={
                  'https://cdn.prod.website-files.com/66bccd21862b191477dc8806/66bce0be1491cc6deb168141_symbol.svg'
                }
              />

              <div className="flex flex-col gap-1">
                <div className="flex gap-2 items-center">
                  <TypographyBody isStrong>
                    {`Generating draft of ${session?.topic || topic || 'topic'}`}
                  </TypographyBody>
                </div>

                <TypographyBody className="text-system-body">
                  This will take 5-10 minutes
                </TypographyBody>
              </div>
            </div>
          </div>
        )}

        {loading && ready && (
          <div className="flex gap-3 items-center w-[474px] mx-auto p-6 bg-system-secondary border border-system-border-regular rounded-[12px]">
            <Sparkles className="size-6 shrink-0 stroke-[1.5px]" />
            <div className="flex flex-col gap-1 w-full">
              <TypographyBody isStrong>Your report is ready</TypographyBody>

              <TypographyLabel>Have a look!</TypographyLabel>
            </div>

            <Button
              onClick={() => {
                setTimeout(() => {
                  setLoading(false)
                  setReady(false)
                }, 500)
                toggleShowWelcomeAnimation(true)
              }}
            >
              <div className="flex gap-2 items-center">
                View
                <ArrowRight className="size-6 shrink-0 stroke-[1.5px]" />
              </div>
            </Button>
          </div>
        )}

        {error && (
          <div className="w-fit mx-auto">
            <CustomAlert
              variant="error"
              description="We were unable to generate or display a draft."
            />
          </div>
        )}

        {!loading && !error && (
          <div className="flex flex-col">
            <div className="sticky top-0 flex flex-col gap-1 py-4 w-full">
              <div
                className={`flex flex-col gap-1 w-[320px] max-w-[calc((100%-710px)/2-24px)] ${selectedSource ? 'opacity-0' : 'opacity-100'} `}
              >
                {currentSession?.content?.knowledge_base.tree.children.map(
                  (section, firstIndex) => {
                    const isSelected =
                      JSON.stringify(selectedSectionIndexes) ===
                      JSON.stringify([firstIndex])
                    return (
                      <Fragment key={`draft-tree-${firstIndex}`}>
                        <div
                          className={`${tertiaryStyle} flex gap-2 px-3 py-1 w-full cursor-pointer !border-0 !border-l-[3px] ${isSelected ? '!border-l-system-primary bg-system-surface-light' : '!border-l-transparent'}`}
                          onClick={() => handleSectionSelection([firstIndex])}
                        >
                          <TypographyLabel className="text-system-placeholder">
                            {firstIndex + 1}
                          </TypographyLabel>
                          <TypographyLabel className="text-system-primary !font-label-strong">
                            {section.name}
                          </TypographyLabel>
                        </div>
                        {section.children.map((section, secondIndex) => {
                          const isSelected =
                            JSON.stringify(selectedSectionIndexes) ===
                            JSON.stringify([firstIndex, secondIndex])
                          return (
                            <Fragment
                              key={`draft-tree-${firstIndex}-${secondIndex}`}
                            >
                              <div
                                className={`${tertiaryStyle} flex gap-2 px-3 py-1 pl-[32px] w-full cursor-pointer !border-0 !border-l-[3px] ${isSelected ? '!border-l-system-primary bg-system-surface-light' : '!border-l-transparent'}`}
                                onClick={() =>
                                  handleSectionSelection([
                                    firstIndex,
                                    secondIndex,
                                  ])
                                }
                              >
                                <TypographyLabel className="text-system-placeholder">
                                  {`${firstIndex + 1}.${secondIndex + 1}`}
                                </TypographyLabel>
                                <TypographyLabel className="text-system-primary">
                                  {section.name}
                                </TypographyLabel>
                              </div>

                              {section.children.map((section, thirdIndex) => {
                                const isSelected =
                                  JSON.stringify(selectedSectionIndexes) ===
                                  JSON.stringify([
                                    firstIndex,
                                    secondIndex,
                                    thirdIndex,
                                  ])
                                return (
                                  <Fragment
                                    key={`draft-tree-${firstIndex}-${thirdIndex}`}
                                  >
                                    <div
                                      className={`${tertiaryStyle} flex gap-2 px-3 py-1 pl-[52px] w-full cursor-pointer !border-0 !border-l-[3px] ${isSelected ? '!border-l-system-primary bg-system-surface-light' : '!border-l-transparent'}`}
                                      onClick={() =>
                                        handleSectionSelection([
                                          firstIndex,
                                          secondIndex,
                                          thirdIndex,
                                        ])
                                      }
                                    >
                                      <TypographyLabel className="text-system-placeholder">
                                        {`${firstIndex + 1}.${secondIndex + 1}.${thirdIndex + 1}`}
                                      </TypographyLabel>
                                      <TypographyLabel className="text-system-primary">
                                        {section.name}
                                      </TypographyLabel>
                                    </div>
                                  </Fragment>
                                )
                              })}
                            </Fragment>
                          )
                        })}
                      </Fragment>
                    )
                  }
                )}
              </div>
              <div className="w-[710px]"></div>
              <div className="w-[calc((100%-710px)/2-12px)]"></div>
            </div>

            <div
              ref={draftContainerRef}
              className={`absolute top-[82px] left-[50%] -translate-x-[50%] flex flex-col gap-0 mx-auto z-[2]`}
            >
              {/* Empty div needed for sticky behaviour */}
              <div className="h-0"></div>
              <div className="flex gap-4">
                <div className="flex flex-col gap-3 w-[710px]">
                  <div className="flex gap-2 items-center mx-auto">
                    <TypographyH4 className="line-clamp-1 break-all shrink-0">
                      {topic || ''}
                    </TypographyH4>

                    <Badge>
                      <div className="flex gap-2 items-center">
                        <Sparkles className="size-4 shrink-0 stroke-[1.5px] stroke-badge-blue-graphic" />
                        <span className="line-clamp-1 break-all">
                          {template?.title || ''}
                        </span>
                      </div>
                    </Badge>
                  </div>

                  <div className="flex gap-0 items-center">
                    {documents.slice(0, 2).map((doc) => {
                      return (
                        <div
                          key={doc.document_id}
                          className="flex gap-2 items-center py-1 px-3"
                        >
                          {getFileIcon(doc.document_type_friendly, '!size-7')}

                          <TypographyLabel className="text-system-body line-clamp-1 break-all">
                            {doc.document_name}
                          </TypographyLabel>
                        </div>
                      )
                    })}

                    {documents.length > 2 && (
                      <div className="flex gap-2 items-center py-1 px-3">
                        <TypographyLabel className="text-system-body whitespace-nowrap">
                          {`+${documents.length - 2} more`}
                        </TypographyLabel>
                      </div>
                    )}
                    <div className="ml-auto [&_svg]:!stroke-system-primary">
                      <ViewSources
                        documents={sourceDocuments}
                        compact={true}
                        loading={loading}
                        sourceType={'ask'}
                        canRetry={false}
                      />
                    </div>
                  </div>

                  {currentSession?.content?.knowledge_base.tree.children.map(
                    (section, firstIndex) => {
                      return (
                        <div
                          key={`draft-content-${firstIndex}`}
                          className="p-6 bg-system-secondary border border-system-border-light rounded-lg mb-4"
                        >
                          <div
                            className="flex flex-col gap-4 px-3"
                            id={`draft-content-${firstIndex}`}
                          >
                            <div className="flex gap-2 items-center">
                              <TypographyH3 className="text-system-primary">
                                {section.name}
                              </TypographyH3>
                              {(section.themes?.length || 0) > 0 && (
                                <Button
                                  variant="tertiary"
                                  className="ml-auto h-fit"
                                  onClick={() =>
                                    setSelectedSectionTheme(
                                      section.themes || []
                                    )
                                  }
                                >
                                  <CircleHelpIcon className="size-6 shrink-0 stroke-[1.5px]" />
                                </Button>
                              )}
                            </div>
                            {section.children && section.children.length > 0 ? (
                              section.children.map((child, secondIndex) => (
                                <Fragment
                                  key={`draft-content-${firstIndex}-${secondIndex}`}
                                >
                                  <div
                                    className="flex flex-col gap-3 py-1.5"
                                    id={`draft-content-${firstIndex}-${secondIndex}`}
                                  >
                                    <TypographyH4 className="text-system-primary">
                                      {child.name}
                                    </TypographyH4>

                                    <div className="!text-system-body [&_p]:text-justify">
                                      <ResponseMarkdown
                                        text={embedCitationsV4(
                                          child.synthesize_output || '',
                                          allCitations
                                        )}
                                        documents={[]}
                                        citations={[]}
                                        isTable={false}
                                        openedCitation={null}
                                        overrides={{
                                          Citation: {
                                            component: ({
                                              id,
                                            }: {
                                              id: number
                                            }) => {
                                              const citation =
                                                currentSession.content
                                                  ?.knowledge_base.citations[
                                                  id
                                                ] || null
                                              const document =
                                                documents.find(
                                                  (v) =>
                                                    v.document_id ===
                                                    citation?.document_id
                                                ) || null
                                              const sourceDocument =
                                                sourceDocuments.find(
                                                  (v) =>
                                                    v.document_id ===
                                                    citation?.document_id
                                                ) || null
                                              return (
                                                <CitationFootnote
                                                  id={id}
                                                  document={document}
                                                  selected={
                                                    openedCitation === citation
                                                  }
                                                  onClick={() => {
                                                    setOpenedCitation(citation)
                                                    setSelectedSource(
                                                      sourceDocument
                                                    )
                                                  }}
                                                />
                                              )
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {/* render third level children if any */}
                                  {child.children &&
                                    child.children.length > 0 &&
                                    child.children.map(
                                      (grandchild, thirdIndex) => (
                                        <Fragment
                                          key={`draft-content-${firstIndex}-${secondIndex}-${thirdIndex}`}
                                        >
                                          <div
                                            className="flex flex-col gap-2"
                                            id={`draft-content-${firstIndex}-${secondIndex}-${thirdIndex}`}
                                          >
                                            <TypographyBody
                                              isStrong
                                              className="text-system-primary"
                                            >
                                              {grandchild.name}
                                            </TypographyBody>

                                            <div className="!text-system-body">
                                              <ResponseMarkdown
                                                text={embedCitationsV4(
                                                  grandchild.synthesize_output ||
                                                    '',
                                                  allCitations
                                                )}
                                                documents={[]}
                                                citations={[]}
                                                isTable={false}
                                                openedCitation={null}
                                                overrides={{
                                                  Citation: {
                                                    component: ({
                                                      id,
                                                    }: {
                                                      id: number
                                                    }) => {
                                                      const citation =
                                                        currentSession.content
                                                          ?.knowledge_base
                                                          .citations[id] || null
                                                      const document =
                                                        documents.find(
                                                          (v) =>
                                                            v.document_id ===
                                                            citation?.document_id
                                                        ) || null
                                                      const sourceDocument =
                                                        sourceDocuments.find(
                                                          (v) =>
                                                            v.document_id ===
                                                            citation?.document_id
                                                        ) || null
                                                      return (
                                                        <CitationFootnote
                                                          id={id}
                                                          document={document}
                                                          selected={
                                                            openedCitation ===
                                                            citation
                                                          }
                                                          onClick={() => {
                                                            setOpenedCitation(
                                                              citation
                                                            )
                                                            setSelectedSource(
                                                              sourceDocument
                                                            )
                                                          }}
                                                        />
                                                      )
                                                    },
                                                  },
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Fragment>
                                      )
                                    )}
                                </Fragment>
                              ))
                            ) : (
                              // section has no children, render only its content
                              <div className="!text-system-body">
                                <ResponseMarkdown
                                  text={embedCitationsV4(
                                    section.synthesize_output || '',
                                    allCitations
                                  )}
                                  documents={[]}
                                  citations={[]}
                                  isTable={false}
                                  openedCitation={null}
                                  overrides={{
                                    Citation: {
                                      component: ({ id }: { id: number }) => {
                                        const citation =
                                          currentSession.content?.knowledge_base
                                            .citations[id] || null
                                        const document =
                                          documents.find(
                                            (v) =>
                                              v.document_id ===
                                              citation?.document_id
                                          ) || null
                                        const sourceDocument =
                                          sourceDocuments.find(
                                            (v) =>
                                              v.document_id ===
                                              citation?.document_id
                                          ) || null
                                        return (
                                          <CitationFootnote
                                            id={id}
                                            document={document}
                                            selected={
                                              openedCitation === citation
                                            }
                                            onClick={() => {
                                              setOpenedCitation(citation)
                                              setSelectedSource(sourceDocument)
                                            }}
                                          />
                                        )
                                      },
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>

                <div
                  ref={sourceContainerRef}
                  className={`${selectedSource ? 'w-[500px] laptop:w-[640px]' : 'w-0'} transition-width ease-in-out duration-300`}
                >
                  {selectedSource && openedCitationResource && (
                    <div className="sticky right-0 top-0 w-[500px] laptop:w-[640px]">
                      <DocumentPreviewContainer
                        key={`document-preview-container-${selectedSource.document_id}`}
                        type={DocumentPreviewType.ASK}
                        resource={openedCitationResource}
                        selectedExtractIndex={
                          selectedExtractIndex[selectedSource.document_id] || 0
                        }
                        setSelectedExtractIndex={(index) => {
                          setSelectedExtractIndex({
                            ...selectedExtractIndex,
                            [selectedSource.document_id]: index,
                          })
                        }}
                        sources={sourceDocuments}
                        selectedSource={selectedSource}
                        initialWidth={window.innerWidth > 1920 ? 600 : 400}
                        onBack={() => setSelectedSource(null)}
                        onClose={() => {
                          setSelectedSource(null)
                          setOpenedCitation(null)
                        }}
                        setSelectedSource={(v) => setSelectedSource(v)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className=""
              style={{ height: `${draftContainerHeight - 400}px` }}
            ></div>
          </div>
        )}
      </div>

      {showWelcomeAnimation && <LoadingAnimation />}
      {!loading && !error && (
        <div className="fixed flex flex-col gap-2 max-w-[320px] [@media(min-width:1720px)]:max-w-[460px] p-4 bottom-7 right-7 rounded-lg border border-system-border-regular bg-system-surface-light">
          <div className="flex flex-col gap-1">
            <TypographyBody isStrong>
              Switch to custom editing mode to freely edit the document
            </TypographyBody>

            <TypographyBody className="text-system-body">
              You will be able to view this draft later, even after making
              changes.
            </TypographyBody>
          </div>

          <Button className="w-fit ml-auto" onClick={handleCreateReport}>
            <div className="flex gap-2 items-center">
              Switch to edit mode
              <ArrowRight className="size-6 shrink stroke-[1.5px]" />
            </div>
          </Button>
        </div>
      )}

      <DocGenThemeDialog
        open={Boolean(selectedSectionTheme)}
        setOpen={() => {
          setSelectedSectionTheme(null)
        }}
        themes={selectedSectionTheme}
        documents={documents}
        sourceDocuments={sourceDocuments}
      />
    </>
  )
}
