import { Dossier } from "@/types/types"
import { getDossierIcon } from "@/utils/components"
import { TypographyBody } from "../ui/Typography"
import { ChevronRight } from "lucide-react"
import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"

export const DossierBreadcrumb = ({ dossier, component }: { dossier: Dossier, component: ReactNode }) => {
    const navigate = useNavigate()

    return (
        <div className="flex gap-2 items-center px-2 py-3 mx-auto w-fit">
            <div className="flex gap-2 items-center mx-auto w-fit">
                <div className="p-1 rounded-[2px] border border-system-border-regular bg-system-surface-light w-fit h-fit shrink-0">
                    {getDossierIcon(dossier.icon, '!size-3')}
                </div>

                <TypographyBody className="text-system-body hover:underline decoration-system-body cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis" onClick={() => navigate(`/dossier/${dossier.id}`)}>
                    {dossier.subject}
                </TypographyBody>
            </div>

            <ChevronRight className="size-6 stroke-[1.5px] shrink-0" />

            {component}
        </div>
    )
}