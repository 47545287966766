export function getTextItemWithNeighbors(textItems: any, itemIndex: number, span = 100) {
    const items = textItems.slice(
        Math.max(0, itemIndex - span),
        itemIndex + 1 + span
    )
        .filter(Boolean)

    const joinedItems: string = items
        .map((item: any) => item.str)
        .filter(Boolean)
        .map((v: string) => v === ' ' || v === '' ? '<|||>' : v)
        .join('')
        .replaceAll('<|||>', ' ')
    return joinedItems
}

export function getIndexRange(string: string, substring: string) {
    const indexStart = string.indexOf(substring);
    const indexEnd = indexStart + substring.length;

    return [indexStart, indexEnd];
}

export function getNormalizedIndexRange(original: string, substring: string, normalizedOriginal: string, normalizedSubstring: string) {
    const regex = /[^a-z0-9]+/gi

    const indexStart = normalizedOriginal.indexOf(normalizedSubstring)

    const normalizedIndexStart = indexStart + getRemovedCharacterCountBeforeIndex(original, indexStart + 1, regex)

    const indexEnd = normalizedIndexStart + substring.length

    const originalSubstring = original.slice(normalizedIndexStart, indexEnd)

    const normalizedSubstringWithSpaces = substring.replaceAll(/[^a-zA-Z0-9 ]/g, '')
    const normalizedOriginalSubstringWithSpaces = originalSubstring.replaceAll(/[^a-zA-Z0-9 ]/g, '')

    if (normalizedSubstringWithSpaces !== normalizedOriginalSubstringWithSpaces) {
        const indexEndDifference = indexEnd - countDifferentCharacters(normalizedSubstringWithSpaces, normalizedOriginalSubstringWithSpaces)
        return [normalizedIndexStart, indexEndDifference]
    }

    return [normalizedIndexStart, indexEnd]
}

function countDifferentCharacters(s1: string, s2: string) {
    const minLength = s1.length
    const string1 = s1
    let string2 = s2
    for (let i = 0; i < minLength; i++) {
        if (string1[i] !== string2[i]) {
            string2 = string2.slice(0, i) +
                string1[i] +
                string2.slice(i)
        }
    }

    return string2.length - string1.length;
}

export function highlightPattern(text: string, pattern: string) {
    // if (pattern.length === 1 && text.length > 1) return
    return text.replace(pattern, (value) => `<mark>${value}</mark>`);
}
// modified from here: https://github.com/wojtekmaj/react-pdf/issues/614
export const highlightPartialText = (text: string, neighbourText: string, highlightedStrings: string[]): string => {
    const regex = /[^a-z0-9]+/gi
    const normalizedNeighbourText = neighbourText.replace(regex, '').toLowerCase()
    const normalizedText = text.replace(regex, '').toLowerCase()

    if (normalizedText.length === 0) return text

    const matchedStringsInNeighbors = highlightedStrings.map((v) => {
        return {
            originalHighlight: v,
            normalizedHighlight: v.replace(regex, '').toLowerCase()
        }
    }).filter((str) => {
        const included = normalizedNeighbourText.includes(str.normalizedHighlight)
        return included
    })

    if (matchedStringsInNeighbors.length === 0) {
        return text
    }

    let highlightedText = text

    matchedStringsInNeighbors.forEach(({ originalHighlight, normalizedHighlight }) => {
        const [normalizedMatchIndexStart, normalizedMatchIndexEnd] = getNormalizedIndexRange(neighbourText, originalHighlight, normalizedNeighbourText, normalizedHighlight)
        const [normalizedTextItemIndexStart, normalizedTextItemIndexEnd] = getNormalizedIndexRange(neighbourText, text, normalizedNeighbourText, normalizedText)

        if (
            // Match entirely in the previous line
            normalizedMatchIndexEnd < normalizedTextItemIndexStart ||
            // Match entirely in the next line
            normalizedMatchIndexStart > normalizedTextItemIndexEnd
        ) {
            return
        }
        // Match found was partially in the line we're currently rendering
        const matchIndexStartInTextItem = Math.max(0, normalizedMatchIndexStart - normalizedTextItemIndexStart)
        const matchIndexEndInTextItem = normalizedMatchIndexEnd - normalizedTextItemIndexStart

        const partialStringToHighlight = text.slice(matchIndexStartInTextItem, matchIndexEndInTextItem)

        highlightedText = highlightPattern(highlightedText, partialStringToHighlight)
    })

    return highlightedText
}

export const getRemovedCharacterCountBeforeIndex = (original: string, index: number, regex: RegExp): number => {
    let removedCount = 0
    let originalIndex = 0
    let maxIndex = index

    while (originalIndex < original.length) {
        if (original[originalIndex].match(regex)) {
            removedCount++
            maxIndex++
        }

        originalIndex++

        if (originalIndex >= maxIndex) {
            break;
        }
    }

    return removedCount;
};