import ArrowRight from "@/assets/ArrowRight"
import { Button } from "../ui/button"
import { Card, CardContent } from "../ui/card"
import { TypographyLabel } from "../ui/Typography"
import Markdown from "markdown-to-jsx"
import { AssistantAskMode } from "@/types/types"
import { ReactNode, useContext } from "react"
import { UserContext } from "@/contexts/UserContext"

export const SuggestionCard = ({ text, highlights, bgColor, textColor, icon, handleSubmit }: { text: string, highlights: string[], bgColor: string, textColor: string, icon: ReactNode, handleSubmit: ({ message, mode }: { message: string, mode: AssistantAskMode }) => void }) => {
    const { settings } = useContext(UserContext)

    const replaceHighlights = (text: string, highlights: string[], bgColor: string, textColor: string): string => {
        let editedText = text
        highlights.forEach((highlight) => {
            editedText = editedText.replaceAll(highlight, `<span className="bg-[linear-gradient(0deg,_${bgColor},_${bgColor})] text-[${textColor}] font-medium bg-no-repeat bg-[length:100%_90%] bg-blend-multiply">${highlight}</span>`)
        })
        return editedText
    }

    const handleClick = () => {
        handleSubmit({
            message: text,
            mode: settings.assistant.mode
        })
    }

    return (
        <Card className="rounded-[12px]" onClick={() => handleClick()}>
            <CardContent className="flex sm:flex-col items-center gap-2 p-4 h-full">
                <div className="flex gap-3 h-full">
                    {icon}

                    <TypographyLabel>
                        <Markdown>
                            {replaceHighlights(text, highlights, bgColor, textColor)}
                        </Markdown>
                    </TypographyLabel>
                </div>

                <Button
                    variant='tertiary'
                    className="ml-auto sm:mt-auto"
                    onClick={() => handleClick()}
                >
                    <div className="gap-2 hidden sm:!flex">
                        Ask
                    </div>

                    <ArrowRight className="w-6 h-6 shrink-0" />
                </Button>
            </CardContent>
        </Card>
    )
}