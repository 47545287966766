import { ReactNode, createContext, useState } from "react";

interface ILayoutContext {
    showSidebar: boolean,
    toggleShowSidebar: (value: boolean) => void,
    showWelcomeAnimation: boolean,
    toggleShowWelcomeAnimation: (value: boolean) => void
}
interface IProviderProps {
    children: ReactNode;
}
const initialContextValue = {
    showSidebar: false,
    toggleShowSidebar: () => { },
    showWelcomeAnimation: false,
    toggleShowWelcomeAnimation: () => { },
} satisfies ILayoutContext

export const LayoutContext = createContext<ILayoutContext>(initialContextValue);

function LayoutProvider({ children }: IProviderProps) {
    const [showSidebar, setShowSidebar] = useState<boolean>(false);
    const [showWelcomeAnimation, setShowWelcomeAnimation] = useState<boolean>(false);

    function toggleShowSidebar(value: boolean) {
        setShowSidebar(value)
    }

    function toggleShowWelcomeAnimation(value: boolean) {
        setShowWelcomeAnimation(value)
    }

    return (
        <LayoutContext.Provider value={{
            showSidebar,
            toggleShowSidebar,
            showWelcomeAnimation,
            toggleShowWelcomeAnimation
        }}>
            {children}
        </LayoutContext.Provider>
    )
}

export { LayoutProvider };
