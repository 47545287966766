import ChevronDown from "@/assets/ChevronDown"
import { TypographyH4, TypographyLabel } from "../ui/Typography"
import { ChevronUp, EllipsisVertical, GripVertical, X } from "lucide-react"
import { TemplateSection, TemplateSubsection } from "@/types/types"
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from "@hello-pangea/dnd"
import { Button } from "../ui/button"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { TemplateSubsectionRow } from "./TemplateSubsectionRow"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu"
import Checkmark from "@/assets/Checkmark"
import { CustomAlert } from "../CustomAlert"
import { getAllSectionTitles } from "@/utils/utils"

export const TemplateSectionRow = ({ section, sections, indexes, provided, onSectionUpdate, onRemove }: { section: TemplateSection, sections: TemplateSection[], indexes: number[], provided: DraggableProvided, onSectionUpdate: (section: TemplateSection) => void, onRemove: ({ section, subsection, indexes }: { section?: TemplateSection, subsection?: TemplateSubsection, indexes?: number[] }) => void }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [editingDescription, setEditingDescription] = useState(false)
    const [editingTitle, setEditingTitle] = useState(false)
    const [description, setDescription] = useState(section.description)
    const [title, setTitle] = useState(section.title)
    const [initialLoad, setInitialLoad] = useState(true)
    const [duplicateTitleError, setDuplicateTitleError] = useState(false)
    const [emptyTitleError, setEmptyTitleError] = useState(false)

    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const reorder = (list: TemplateSubsection[], startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        if (result.destination.index === result.source.index) {
            return
        }

        const subsections = section.subsections
        if (!subsections) return

        const items = reorder(
            subsections,
            result.source.index,
            result.destination.index
        );

        onSectionUpdate({
            ...section,
            subsections: items
        })
    }

    const handleSubsectionUpdate = (subsection: TemplateSubsection) => {
        const subsections = section.subsections
        const index = subsections?.findIndex((v) => subsection.id === v.id)
        if (index === undefined || !subsections) return
        const copy = [...subsections]

        copy[index] = subsection
        onSectionUpdate({
            ...section,
            subsections: copy
        })
    }

    const handleAddNewSubsection = () => {
        const id = (section.subsections || []).length > 0 ? Math.max(...(section.subsections || []).map((v) => v.id)) + 1 : 0
        onSectionUpdate({
            ...section,
            expanded: true,
            subsections: [
                {
                    id: id,
                    title: '',
                },
                ...section.subsections || [],
            ]
        })
    }

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "0px";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = Math.max(20, scrollHeight) + "px";
        }
    }, [description, editingDescription])

    useEffect(() => {
        if (editingTitle) {
            setTimeout(() => {
                inputRef.current?.focus()
            }, 200)
        }
    }, [editingTitle])

    useEffect(() => {
        if (editingDescription) {
            setTimeout(() => {
                textareaRef.current?.focus()
            }, 200)
        }
    }, [editingDescription])

    useEffect(() => {
        if (section.title === '' && indexes.at(-1) === 0 && initialLoad) {
            setEditingTitle(true)
        }

        setInitialLoad(false)
    }, [section])

    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`group relative flex gap-3 px-4 py-4 items-center bg-system-secondary border border-system-border-light rounded-[12px] mt-3 shadow-section`}
        >
            <div className="flex flex-col gap-2 w-full">
                {duplicateTitleError && (
                    <CustomAlert
                        className="py-1.5 rounded-sm"
                        variant='error'
                        description="This name is already used by another section or subsection. Please enter a different name."
                    />
                )}

                {emptyTitleError && (
                    <CustomAlert
                        className="py-1.5 rounded-sm"
                        variant='error'
                        description="Please enter a title"
                    />
                )}

                <div className="flex gap-3 w-full px-1 pb-px">
                    <Button variant='tertiary'  {...provided.dragHandleProps} className="h-fit">
                        <GripVertical className="size-6 shrink-0 stroke-[1.5px]" />
                    </Button>

                    <div className="flex gap-3 w-full">
                        <TypographyH4 className="text-system-border-regular min-w-3">
                            {indexes[0] + 1}
                        </TypographyH4>

                        <div className="flex gap-6 w-full items-center">
                            <div className="flex flex-col gap-2 w-full max-w-[85%] mr-auto">
                                <TypographyH4 className="w-full">
                                    {section.title}
                                </TypographyH4>

                                {(section.description || editingDescription) && (
                                    <>
                                        {editingDescription ?
                                            <div className="flex gap-4 items-center pl-4 pr-2 py-2 bg-system-surface border border-system-border-regular rounded-lg">
                                                <textarea
                                                    ref={textareaRef}
                                                    className="text-system-body font-label w-full bg-transparent focus:outline-none"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                />

                                                <div className="flex gap-2 items-center">
                                                    <Button
                                                        variant='tertiary'
                                                        className="h-fit"
                                                        onClick={() => {
                                                            setDescription(section.description)
                                                            setEditingDescription(false)
                                                        }}
                                                    >
                                                        <X className="size-6 shrink-0 stroke-[1.5px]" />
                                                    </Button>

                                                    <Button
                                                        variant='tertiary'
                                                        className="h-fit"
                                                        onClick={() => {
                                                            onSectionUpdate({ ...section, description: description })
                                                            setEditingDescription(false)
                                                        }}
                                                    >
                                                        <Checkmark className="size-6 shrink-0 stroke-[1.5px]" />
                                                    </Button>
                                                </div>
                                            </div>
                                            :
                                            <TypographyLabel className="text-system-body text-left whitespace-pre-wrap">
                                                {section.description}
                                            </TypographyLabel>
                                        }
                                    </>
                                )}
                            </div>
                            <DropdownMenu onOpenChange={(v) => {
                                if (v) {
                                    setDropdownOpen(v)
                                } else {
                                    setTimeout(() => {
                                        setDropdownOpen(v)
                                    }, 100)
                                }
                            }}>
                                <DropdownMenuTrigger className="flex items-center" asChild>
                                    <Button variant='tertiary' className={`${section.expanded || dropdownOpen ? '' : 'hidden'} group-hover:!block h-fit`}>
                                        <EllipsisVertical className={`size-6 shrink-0 stroke-[1.5px]`} />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end" className="w-[193px]">
                                    <DropdownMenuItem
                                        variant='tertiary'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleAddNewSubsection()
                                        }}>
                                        Add subsection
                                    </DropdownMenuItem>

                                    <DropdownMenuItem
                                        variant='tertiary'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setTimeout(() => {
                                                setEditingTitle(true)
                                            }, 100)
                                        }}>
                                        Rename
                                    </DropdownMenuItem>

                                    <DropdownMenuItem
                                        variant='tertiary'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setEditingDescription(true)
                                            onSectionUpdate({
                                                ...section,
                                                expanded: true,
                                            })
                                        }}>
                                        Edit description
                                    </DropdownMenuItem>

                                    <DropdownMenuItem
                                        variant='tertiary-destructive'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setTitle(section.title)
                                            setEditingTitle(false)
                                            setEditingDescription(false)
                                            onRemove({ section: section, indexes })
                                        }}>
                                        Remove
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>

                            {(section.subsections?.length || 0) > 0 && (
                                <Button variant='tertiary' className="h-fit" onClick={() => onSectionUpdate({
                                    ...section,
                                    expanded: !section.expanded
                                })}>
                                    {section.expanded ?
                                        <ChevronUp className="size-6 shrink-0 stroke-[1.5px]" />
                                        :
                                        <ChevronDown />
                                    }
                                </Button>
                            )}
                        </div>
                    </div>

                </div>

                {editingTitle && (
                    <div className="absolute top-[7px] left-2 flex flex-col gap-2 w-[calc(100%-16px)] px-1 py-2 bg-system-surface border border-system-border-regular rounded-lg">
                        {duplicateTitleError && (
                            <div className="px-1">
                                <CustomAlert
                                    className="py-1.5 rounded-sm"
                                    variant='error'
                                    description="This name is already used by another section or subsection. Please enter a different name."
                                />
                            </div>
                        )}

                        {emptyTitleError && (
                            <div className="px-1">
                                <CustomAlert
                                    className="py-1.5 rounded-sm"
                                    variant='error'
                                    description="Please enter a title"
                                />
                            </div>
                        )}

                        <div className="flex gap-3">
                            <Button variant='tertiary'  {...provided.dragHandleProps} className="h-fit opacity-0">
                                <GripVertical className="size-6 shrink-0 stroke-[1.5px]" />
                            </Button>

                            <div className="flex gap-3 w-full">
                                <TypographyH4 className="text-system-border-regular min-w-3 opacity-0">
                                    {indexes[0] + 1}
                                </TypographyH4>

                                <div className="flex flex-col gap-2 w-full">
                                    <div className="flex gap-6 w-full">
                                        <input
                                            id={`title-input-${section.id}`}
                                            ref={inputRef}
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            className="font-h4 text-system-primary focus:outline-none w-full placeholder:text-system-placeholder"
                                            placeholder="Section title"
                                        />
                                    </div>
                                </div>

                                <div className="flex gap-2 items-center">
                                    <Button
                                        variant='tertiary'
                                        className="h-fit"
                                        onClick={() => {
                                            if (title === '' && section.title === '') {
                                                onRemove({ section: section, indexes })
                                            } else {
                                                setTitle(section.title)
                                            }

                                            setEditingTitle(false)
                                            setDuplicateTitleError(false)
                                            setEmptyTitleError(false)
                                        }}
                                    >
                                        <X className="size-6 shrink-0 stroke-[1.5px]" />
                                    </Button>

                                    <Button
                                        variant='tertiary'
                                        className="h-fit"
                                        onClick={() => {
                                            const titles = getAllSectionTitles(sections)
                                            if (title === '') {
                                                setEmptyTitleError(true)
                                                return
                                            }

                                            if (titles.includes(title) && title !== section.title) {
                                                setDuplicateTitleError(true)
                                                return
                                            }
                                            onSectionUpdate({ ...section, title: title })
                                            setEditingTitle(false)
                                            setDuplicateTitleError(false)
                                            setEmptyTitleError(false)
                                        }}
                                    >
                                        <Checkmark className="size-6 shrink-0 stroke-[1.5px]" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {section.expanded && (section.subsections || []).length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={`${snapshot.isDraggingOver ? 'pointer-events-none' : ''}`}
                                >
                                    {section.subsections?.map((item, index) => (
                                        <Draggable key={`${item.id}-${index}`} draggableId={`draggable-${item.id}`} index={index}>
                                            {(provided, snapshot) => (
                                                snapshot.isDragging ?
                                                    createPortal(<TemplateSubsectionRow section={item} sections={sections} subsection={item} indexes={[...indexes, index]} provided={provided} snapshot={snapshot} onSubsectionUpdate={handleSubsectionUpdate} onRemove={onRemove} />, document.body)
                                                    :
                                                    <TemplateSubsectionRow section={item} sections={sections} subsection={item} indexes={[...indexes, index]} provided={provided} snapshot={snapshot} onSubsectionUpdate={handleSubsectionUpdate} onRemove={onRemove} />
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}

            </div>
        </div>
    )
}