import { UserMessage } from "@/types/types";
import { Question } from "../Assistant/Question";
import { memo, ReactNode } from "react";

const UserChatMessage = memo((props: { message: UserMessage, compact?: boolean, component?: ReactNode }) => {
    return (
        <div className="max-w-[710px] flex">
            <Question message={props.message.query} compact={props.compact} component={props.component} />
        </div>
    )
})

export default UserChatMessage