import type { AssistantStore, Conversation } from "@/types/types";

export function getLiveConversationById({
  requestId,
  conversationId,
  store,
}: {
  requestId: string;
  conversationId: string;
  store: AssistantStore;
}): Conversation {
  const conversation = store.liveConversations?.find(
    (lc) =>
      lc?.conversationId === conversationId ||
      lc?.requestIds?.includes(requestId),
  );
  return conversation?.conversation || [];
}

export const parseHeaderForDates = (header: string): boolean => {
  const trimmedHeader = header.trim().toLowerCase();

  const isYear = /^(year|years|yr|yrs|annually|annual|per\s?year)$/.test(
    trimmedHeader,
  );
  const isQuarter = /^(quarter|qtr|q|quarters|quarterly)$/.test(trimmedHeader);

  const isSemester = /^(semester|sem|half-year|h1|h2|half)$/.test(
    trimmedHeader,
  );
  const isWeek = /^(week|weeks|wk|wks|weekly|per\s?week)$/.test(trimmedHeader);

  const isMonth = /^(month|months|mo|mos|monthly)$/.test(trimmedHeader);

  const isDay = /^(day|days|daily|date|dates)$/.test(trimmedHeader);

  return isYear || isQuarter || isSemester || isWeek || isMonth || isDay;
};
