import { WEB_SERVER_ENDPOINT } from '@/constants';
import { ResAppUser } from '@/types/types';
import { handleError } from '@/utils/handleError';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store/store';


export interface UserState {
    user: {
        profile: ResAppUser | null,
        status: 'idle' | 'loading' | 'error' | 'success',
    }
}

const initialState: UserState = {
    user: {
        profile: null,
        status: 'idle',
    },
}

export const fetchUserProfile = createAsyncThunk<
  ResAppUser,
  void,
  { state: RootState }
  >(
    'user/fetchUserProfile',
    async (_, { rejectWithValue }) => {
        const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/user/profile`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        const data = await response.json()
        if (response.status < 200 || response.status >= 300) {
            return rejectWithValue(data)
        }
        return data
    },
    {
      condition: (_, { getState }) => {
        const { user } = getState();
        if (
          user.user.status === 'idle' ||
          user.user.status === 'error'
        ) {
          return true;
        }
        return false;
      },
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        connected: () => {
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserProfile.pending, (state) => {
            state.user.status = 'loading'
            state.user.profile = null
        })

        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.user.status = 'success'
            state.user.profile = action.payload
        })

        builder.addCase(fetchUserProfile.rejected, (state, action) => {
            state.user.status = 'error'
            state.user.profile = null
            handleError(action.error)
        })
    },
})

export const { connected } = userSlice.actions

export default userSlice.reducer
