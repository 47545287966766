export class AskDesiaTool {
  public api: any
  public container: HTMLDivElement | null = null;
  public onCreate: () => void

  static get toolbox() {
    return {
      title: 'Ask Desia',
      icon: `<svg class="ask-desia-button" width="20" height="20" viewBox="0 0 24 24" stroke="current" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.9 20C9.80858 20.9791 12.0041 21.2442 14.0909 20.7478C16.1777 20.2513 18.0186 19.0258 19.2818 17.2922C20.545 15.5585 21.1474 13.4307 20.9806 11.2922C20.8137 9.15361 19.8886 7.14497 18.3718 5.62819C16.855 4.11142 14.8464 3.18625 12.7078 3.01942C10.5693 2.85258 8.44147 3.45505 6.70782 4.71825C4.97417 5.98145 3.74869 7.82231 3.25222 9.90911C2.75575 11.9959 3.02094 14.1914 4 16.1L2 22L7.9 20Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.09 9.00001C9.3251 8.33167 9.78915 7.76811 10.4 7.40914C11.0108 7.05016 11.7289 6.91894 12.4272 7.03872C13.1255 7.15849 13.7588 7.52153 14.2151 8.06353C14.6713 8.60554 14.9211 9.29153 14.92 10C14.92 12 11.92 13 11.92 13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 17H12.01" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
    };
  }


  constructor({ api, config }: any) {
    this.api = api
    this.onCreate = config.onCreate
  }

  render() {
    this.onCreate()
    this.api.toolbar.close()
    return null;
  }

  save() {
    return ""
  }
}