import { Button } from "../ui/button";
import { Dialog, DialogBody, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Input } from "../ui/input";
import { DossierDetail, FileStructure, QueryStatus, SourceSettings, SourceType } from "@/types/types";
import { ReactNode, useContext, useEffect, useState } from "react";
import { TypographyBody } from "../ui/Typography";
import { UserContext } from "@/contexts/UserContext";
import { ChevronLeft } from "lucide-react";
import { plural, toggleElement } from "@/utils/utils";
import { FileTable } from "../Integration/FileTable";
import { transformResponseDocuments } from "@/utils/transformResponseDocuments";
import { CustomAlert } from "../CustomAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { listDocuments } from "../Document/documentThunk";

const DocumentSelectorDialog = ({ trigger, dossierDetail, initialSelection, sourceType, onSave, open, setOpen, isDossierFileSelection, type }: { trigger?: ReactNode, dossierDetail?: DossierDetail, initialSelection?: string[], sourceType: SourceType, onSave?: (ids: string[]) => void, open: boolean, setOpen: (open: boolean) => void, isDossierFileSelection?: boolean, type: 'settings' | 'docgen' }) => {
    const documentStore = useSelector((state: RootState) => state.document);
    const dispatch = useDispatch<AppDispatch>()
    const { settings, updateSettings } = useContext(UserContext);

    const [searchText, setSearchText] = useState('')
    const [showSuggestions, setShowSuggestions] = useState(true)
    const [isInitial, setIsInitial] = useState(true)

    const loading = documentStore.fetchStatus === QueryStatus.FETCHING;
    const errorFetching = documentStore.fetchStatus === QueryStatus.ERROR_FETCHING;
    const resources = documentStore.files;

    const [elements, setElements] = useState<FileStructure[]>([])
    const [filteredElements, setFilteredElements] = useState<FileStructure[]>([])
    const [selectedElements, setSelectedElements] = useState<FileStructure[]>([])
    const [tempSelectedElements, setTempSelectedElements] = useState<FileStructure[]>([])

    const fileLimit = 15
    const fileSizeLimit = 30 * 10 ** 6
    const fileExceedsSizeLimit = tempSelectedElements.some((e) => {
        const document = documentStore.files.find((v) => v.document_id === e.document_id)

        return (document?.document_size_bytes || 0) > fileSizeLimit
    })

    const areFilesSelected = (selectedElements.length > 0 || tempSelectedElements.length > 0)

    useEffect(() => {
        if (documentStore.fetchStatus !== QueryStatus.SUCCEEDED) {
            dispatch(listDocuments())
        }
    }, [dispatch, settings.assistant.sources, documentStore.fetchStatus])

    useEffect(() => {
        if (!open) return

        const filteredDocuments = resources.filter((doc) => {
            const isPdf = doc.document_type_friendly === 'PDF'
            if (!searchText) {
                if (type === 'docgen') {
                    return isPdf
                }
                return true
            }
            const splitText = searchText.toLowerCase().split(' ')
            const parents = doc.document_source_path_treeview || []

            const result = splitText.some((text) => doc.document_name.toLowerCase().includes(text)) || splitText.some((text) => parents.some((v) => v.element_name.toLowerCase().includes(text)))
            if (type === 'docgen') {
                return result && isPdf
            }
            return result
        })

        const elements = transformResponseDocuments(filteredDocuments)
        setFilteredElements(elements)
    }, [resources, searchText, open, type])

    useEffect(() => {
        if (!open) return

        const selected = getSelectedElements(elements)

        setTempSelectedElements(selected)
    }, [elements, open])

    useEffect(() => {
        if (!open) return

        const readyResources = resources.filter((v) => v.document_is_ready_to_use)

        setElements(transformResponseDocuments(readyResources))
    }, [resources, open])

    useEffect(() => {
        if (!open) return
        if (!isInitial) return

        const selectedFiles = settings.assistant.sources[sourceType].files

        const selected = !isDossierFileSelection ? elements.filter((v) => selectedFiles.some((f: { id: string, title: string }) => f.id === v.document_id)) : elements.filter((v) => initialSelection?.includes(v.document_id || ''))

        if (tempSelectedElements.length === 0) {
            setTempSelectedElements(() => selected)
        }

        selected.forEach((v) => {
            if (!v.is_included) {
                toggleElement(v, elements, setElements)
            }
        })

        setSelectedElements(() => selected)
    }, [elements, open, isInitial, initialSelection, isDossierFileSelection, settings.assistant.sources, sourceType, tempSelectedElements.length])

    const getSelectedElements = (elements: FileStructure[]): FileStructure[] => {
        let includedElements = []

        includedElements = elements.filter((v) => {
            return v.is_included && v.element_type === 'file'
        })

        return includedElements
    }

    const saveSettings = () => {
        if (type === 'settings') {
            const newSource: SourceSettings = {
                ...settings.assistant.sources[sourceType],
                internalSearch: true,
                focusedAnalysis: true,
                files: tempSelectedElements.map((v) => {
                    return {
                        title: v.element_name,
                        id: v.document_id || '',
                    }
                })
            }

            if (!isDossierFileSelection) {
                updateSettings({
                    settings: {
                        ...settings,
                        assistant: {
                            ...settings.assistant,
                            sources: {
                                ...settings.assistant.sources,
                                [sourceType]: newSource
                            },
                        }
                    }
                })
            }
        }

        const ids = tempSelectedElements.map((v) => v.document_id).filter((v): v is string => Boolean(v))
        onSave?.(ids)
    }

    return (
        <>
            <Dialog open={open} onOpenChange={(v) => {
                setOpen(v)

                if (!v) {
                    setIsInitial(true)
                }
            }}>
                {trigger && (
                    <DialogTrigger asChild>
                        {trigger}
                    </DialogTrigger>
                )}
                <DialogContent className="!max-w-[calc(100%-48px)] tablet:!max-w-[951px]" dynamicHeight>
                    <DialogHeader>
                        {type === 'docgen' ?
                            <>
                                <DialogTitle className="text-left">
                                    Select file to use as template
                                </DialogTitle>
                                <DialogDescription className="font-body text-left whitespace-pre-wrap">
                                    {'You can only select pdf files for now but we are working on adding support for other file types soon'}
                                </DialogDescription>
                            </>
                            :
                            <>
                                <DialogTitle className="text-left">
                                    {sourceType === 'ask' ? 'Select files and folders to analyse' : `Select which documents to use in ${dossierDetail?.subject}`}
                                </DialogTitle>
                                <DialogDescription className="font-body text-left whitespace-pre-wrap">
                                    {sourceType === 'ask' ? 'You can select up to 15 files (max 30 MB each) to be used as sources for focused analysis.\nSupported file types include .pdf, .csv, .txt, .docx, .pptx, .xlsx.' : 'Search for the files you want to add or use the suggestions below.'}
                                </DialogDescription>
                            </>
                        }
                    </DialogHeader>
                    <DialogBody className={`flex flex-col gap-0 ${areFilesSelected ? 'border-b border-system-border-light ' : ''} pb-2`}>
                        {searchText && tempSelectedElements.length > 0 && (
                            <Button variant="tertiary" onClick={() => setSearchText('')} className="mt-4">
                                <div className="flex gap-2">
                                    <ChevronLeft className="w-6 h-6 stroke-[1.5px]" />
                                    <TypographyBody isStrong={true}>
                                        {`${tempSelectedElements.length} ${plural('file', tempSelectedElements.length)} selected`}
                                    </TypographyBody>
                                </div>
                            </Button>
                        )}

                        {!searchText && tempSelectedElements.length > 0 && (
                            <div className="flex flex-col gap-6 p-4 border border-system-border-light bg-system-surface-light rounded-lg mb-4">
                                <TypographyBody className="text-system-body">
                                    Selected files
                                </TypographyBody>

                                <div className="">
                                    <FileTable
                                        elements={elements}
                                        shownElements={tempSelectedElements}
                                        resources={resources}
                                        setElements={(v) => {
                                            setElements(v)
                                            setIsInitial(false)
                                        }}
                                        showCheckbox={true}
                                        showHeader={true}
                                        type={type === 'settings' ? 'ask' : 'docgen'}
                                        loading={loading}
                                        error={errorFetching}
                                        openFileOnClick={true}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="mobile:mx-auto mt-12 mb-8">
                            <Input
                                className="w-full mobile:w-[320px]"
                                placeholder="Search by file name"
                                isSearch={true}
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value)

                                    if (showSuggestions) {
                                        setShowSuggestions(false)
                                    }
                                }}
                                isCloseVisible={!!searchText}
                                onCloseClick={() => setSearchText('')}
                            />
                        </div>

                        {tempSelectedElements.length > fileLimit && sourceType === 'ask' && (
                            <CustomAlert
                                variant='error'
                                title="You cannot select more than 15 files"
                                description="Please remove the extra file to continue."
                            />
                        )}

                        {fileExceedsSizeLimit && sourceType === 'ask' && (
                            <CustomAlert
                                variant='error'
                                title="You cannot select a file larger than 30 MB"
                                description="Please remove the file to continue."
                            />
                        )}

                        <div className="flex flex-col gap-4">
                            <TypographyBody className="text-system-body">
                                All library files
                            </TypographyBody>
                            <FileTable
                                elements={elements}
                                shownElements={filteredElements}
                                resources={resources}
                                setElements={(v) => {
                                    setElements(v)
                                    setIsInitial(false)
                                }}
                                showCheckbox={true}
                                showHeader={true}
                                type={type === 'settings' ? 'ask' : 'docgen'}
                                loading={loading}
                                error={errorFetching}
                            />
                        </div>
                    </DialogBody>
                    {areFilesSelected && (
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary" className="w-fit">Cancel</Button>
                            </DialogClose>
                            {((tempSelectedElements.length <= fileLimit && !fileExceedsSizeLimit) || selectedElements.length > 0 || sourceType !== 'ask') && (
                                <DialogClose asChild>
                                    <Button className="w-fit" onClick={() => saveSettings()}>
                                        Confirm selection
                                    </Button>
                                </DialogClose>
                            )}
                        </DialogFooter>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DocumentSelectorDialog