import { useContext, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";

export function AssistantConfig() {
    const { settings, updateSettings } = useContext(UserContext);
    const [resetCounter, setResetCounter] = useState(0);
    
    return (
        <div className="" key={`assistant-config-counter-${resetCounter}`}>
            <Table className="">
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">Parameter</TableHead>
                        <TableHead>Value</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <TableRow>
                        <TableCell className="font-medium">model</TableCell>
                        <TableCell>
                            <Input className="" type="text" placeholder="default (assistant-service)"
                                value={settings.assistant.parameters?.model}
                                onChange={(e) => {
                                    updateSettings({
                                        settings: {
                                            ...settings,
                                            assistant: {
                                                ...settings.assistant,
                                                parameters: {
                                                    ...settings.assistant.parameters,
                                                    model: e.target.value
                                                }
                                            }
                                        }
                                    })
                                }}
                            ></Input>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className="font-medium">temperature</TableCell>
                        <TableCell>
                            <Input className="" type="number" placeholder="default (assistant-service)"
                                value={settings.assistant.parameters?.temperature}
                                onChange={(e) => {
                                    updateSettings({
                                        settings: {
                                            ...settings,
                                            assistant: {
                                                ...settings.assistant,
                                                parameters: {
                                                    ...settings.assistant.parameters,
                                                    temperature: Number(e.target.value)
                                                }
                                            }
                                        }
                                    })
                                }}
                            ></Input>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className="font-medium">seed</TableCell>
                        <TableCell>
                            <Input className="" type="text" placeholder="default (assistant-service)"
                                value={settings.assistant.parameters?.seed}
                                onChange={(e) => {
                                    updateSettings({
                                        settings: {
                                            ...settings,
                                            assistant: {
                                                ...settings.assistant,
                                                parameters: {
                                                    ...settings.assistant.parameters,
                                                    seed: Number(e.target.value)
                                                }
                                            }
                                        }
                                    })
                                }}
                            ></Input>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className="font-medium">system prompt</TableCell>
                        <TableCell>
                            <Textarea className="" rows={4} placeholder={`default (assistant-service)`}
                                value={settings.assistant.parameters?.systemPrompt}
                                onChange={(e) => {
                                    updateSettings({
                                        settings: {
                                            ...settings,
                                            assistant: {
                                                ...settings.assistant,
                                                parameters: {
                                                    ...settings.assistant.parameters,
                                                    systemPrompt: e.target.value
                                                }
                                            }
                                        }
                                    })
                                }}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Button variant={"secondary"} size={"sm"} onClick={() => {
                updateSettings({
                    settings: {
                        ...settings,
                        assistant: {
                            ...settings.assistant,
                            parameters: {}
                        }
                    }
                })
                setResetCounter(resetCounter + 1);
            }}>Reset</Button>
        </div>
    )
}
