import { Dossier } from "@/types/types"
import { MessageCircleMore, NotebookText } from "lucide-react"
import { TypographyLabel } from "../ui/Typography"
import { formatDate, isToday, isYesterday } from "date-fns"
import { plural } from "@/utils/utils"
import { secondaryStyle } from "../ui/button"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getDossierIcon } from "@/utils/components"
import { Truncate } from "@re-dev/react-truncate"
import { DossierDropdownMenu } from "./DossierDropdownMenu"

const breakpoints = [
    {
        width: 0, style: {
            detailContainer: '',
            detailCount: 'hidden'
        }
    },
    {
        width: 483, style: {
            detailContainer: '',
            detailCount: 'flex-col gap-3'
        }
    },
    {
        width: 586, style: {
            detailContainer: '',
            detailCount: 'flex-col gap-3',
        }
    },
    {
        width: 693, style: {
            detailContainer: 'w-[50%]',
            detailCount: 'flex-row items-center gap-8',
        }
    },
]

export const DossierCard = ({ dossier, compact }: { dossier: Dossier, compact?: boolean }) => {
    const [activeBreakpoint, setActiveBreakpoint] = useState(breakpoints[0].style)

    const ref = useRef(null);

    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/dossier/${dossier.id}`)
    }

    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width } = entry.contentRect;

                const activeBreakpoint = breakpoints.reduce((prev, curr) => {
                    return width >= curr.width ? curr : prev;
                }, breakpoints[0]);

                setActiveBreakpoint(activeBreakpoint.style);
            }
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect()
    }, []);

    return (
        <>
            <div ref={ref} onClick={handleClick} className={`${compact ? '!p-3' : '!p-4'} cursor-pointer [&:not(:active)]:!border-system-border-light flex gap-4 !rounded-lg ${secondaryStyle} [&:hover:not(:has(.child-hover:hover))]:!bg-system-hover hover:bg-system-secondary`}>
                <div className="p-4 rounded-sm border border-system-border-regular bg-system-surface-light w-fit h-fit">
                    {getDossierIcon(dossier.icon, 'min-h-6 min-w-6')}
                </div>

                <div className="flex flex-col gap-0.5 my-auto w-full">
                    <Truncate className="font-label text-system-body w-fit">
                        {`Updated ${isToday(dossier.updated_at) ? 'today' : isYesterday(dossier.updated_at) ? 'yesterday' : ` on ${formatDate(dossier.updated_at, 'd MMM')}`}`}
                    </Truncate>
                    <Truncate className="font-h4 max-w-[480px] line-clamp-1">
                        {dossier.subject}
                    </Truncate>
                </div>

                <div className={`flex gap-6 ${!compact &&( activeBreakpoint.detailContainer || '')}`}>
                    {compact !== true && (
                        <div className={`flex flex-grow ${activeBreakpoint.detailCount || ''}`}>
                            <div className="flex gap-1 items-center">
                                <MessageCircleMore className="size-4 shrink-0 stroke-[1.5px]" />

                                <TypographyLabel className="text-system-body whitespace-nowrap">
                                    {`${dossier.conversations || 0} ${plural('chat', dossier.conversations || 0)}`}
                                </TypographyLabel>
                            </div>

                            <div className="flex gap-1 items-center">
                                <NotebookText className="size-4 shrink-0 stroke-[1.5px]" />

                                <TypographyLabel className="text-system-body whitespace-nowrap">
                                    {`${dossier.reports || 0} ${plural('report', dossier.reports || 0)}`}
                                </TypographyLabel>
                            </div>
                        </div>
                    )}


                    <DossierDropdownMenu dossier={dossier} />
                </div>
            </div>
        </>
    )
}