import { DotIcon } from "lucide-react";
import Markdown, { MarkdownToJSX } from "markdown-to-jsx"
import { ReactNode, useMemo } from "react";
import { TypographyP } from "../ui/Typography";
import { StyledH4 } from "./StyledH4";
import { tertiaryStyle } from "../ui/button";
import { Citation, SourceDocument } from "@/types/types";
import { ResponseChartContainer, ResponseChartError } from "../Charts/ResponseChart";
import { ResponseTable, ResponseTableError } from "./StyledTable";

interface CursorProps {
    show: boolean;
}

function Cursor({ show }: CursorProps) {
    if (!show) return null;
    return <DotIcon className="w-6 h-6 text-system-body inline" />;
}

export const ResponseMarkdown = ({ text, documents, citations, openedCitation, compact = false, overrides = {}, isTable }: { text: string, documents: SourceDocument[], citations: Citation[], openedCitation: Citation | null, compact?: boolean, overrides?: MarkdownToJSX.Overrides, isTable: boolean }) => {
    const chartDocuments = documents.filter((v) => v.document_id.includes('chart')).length
    const tableDocuments = documents.filter((v) => v.document_id.includes('table')).length

    const Chart = useMemo(() => ({ id }: { id: string }) => {
        try {
            const document = documents.find((v) => v.document_id === id)
            if (!document) {
                throw new Error('no chart document found')
            }

            return (
                <ResponseChartContainer key={`response-chart-container-${id}`} id={id} data={document.text} compact={compact} />
            )
        } catch {
            return <ResponseChartError />
        }
    }, [chartDocuments])

    const Table = useMemo(() => ({ id }: { id: string }) => {
        try {
            const document = documents.find((v) => v.document_id === id)
            if (!document) {
                throw new Error('no table document found')
            }

            return (
                <ResponseTable key={`response-table-container-${id}`} id={id} data={document.text} citations={citations} documents={documents} compact={compact} overrides={overrides} openedCitation={openedCitation} />
            )
        } catch {
            return <ResponseTableError />
        }
    }, [tableDocuments, openedCitation, citations])

    return (
        <Markdown options={{
            overrides: {
                ...overrides,
                Cursor: {
                    component: Cursor
                },
                p: {
                    component: ({ children }: { children: ReactNode }) =>
                        <>
                            <TypographyP className={`mb-2 mt-6 ${isTable ? '!mb-0' : ''} first:!mt-0 ${compact ? 'font-label' : 'font-body'}`}>{children}</TypographyP>
                        </>
                },
                h1: {
                    component: StyledH4
                },
                h2: {
                    component: StyledH4
                },
                h3: {
                    component: StyledH4
                },
                h4: {
                    component: StyledH4
                },
                h5: {
                    component: StyledH4
                },
                h6: {
                    component: StyledH4
                },
                li: {
                    component: ({ children }: { children: ReactNode }) => <li className={`${compact ? 'font-label' : 'font-body'} ${isTable ? '' : 'ml-10'} my-3 `}>{children}</li>
                },
                strong: {
                    component: ({ children }: { children: ReactNode }) => <span className={`text-system-primary font-medium`}>{children}</span>
                },
                pre: {
                    component: ({ children }: { children: ReactNode }) => <pre>{children}</pre>
                },
                Chart: {
                    component: Chart
                },
                a: {
                    component: ({ href, children }: { href: string, children: ReactNode }) => {
                        return (
                            <a href={href} className={`cursor-pointer underline ${tertiaryStyle} !text-system-body`} target="_blank" rel="noopener noreferrer">
                                {children}
                            </a>
                        )
                    }
                },
                Table: {
                    component: Table
                },
            },
            wrapper: 'span',
            forceBlock: true,
        }}
        >
            {text}
        </Markdown>
    )
}