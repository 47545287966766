import { z } from "zod"
import { Dialog, DialogBody, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog"
import { Input } from "../ui/input"
import { TypographyBody, TypographyLabel } from "../ui/Typography"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, FormControl, FormField, FormItem, FormMessage } from "../ui/form"
import { Textarea } from "../ui/textarea"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select"
import { Button } from "../ui/button"
import Checkmark from "@/assets/Checkmark"
import Divider from "../ui/divider"
import { Loader2, Plus } from "lucide-react"
import { useEffect, useRef, useState } from "react"

import { DocgenTemplate, QueryStatus, Section, TemplateSection } from "@/types/types"
import texture from '../../assets/bg-texture.png'
import { TemplateSectionList } from "./TemplateSectionList"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { createTemplate, fetchTemplate, updateTemplate } from "./docGenThunk"
import { addTemplate, replaceTemplate } from "./docGenSlice"
import { convertTemplateTitleToName } from "@/utils/utils"
import { isFulfilled } from "@reduxjs/toolkit"

export const DocGenCreateTemplateDialog = ({ selectedTemplate, templates, open, setOpen, onSave }: { selectedTemplate: DocgenTemplate | null, templates: DocgenTemplate[], open: boolean, setOpen: (open: boolean) => void, onSave: (template: DocgenTemplate) => void }) => {
    const templateFormSchema = z.object({
        name: z.string().min(1, 'Please enter a name').refine((name) => !templates.find((v) => v.title === name && !selectedTemplate?.id), (name) => ({
            message: `${name} already exists, please enter a different name.`,
        })),
        description: z.string().min(1, 'Please enter a description'),
        writing_style: z.string()
    })

    const form = useForm<z.infer<typeof templateFormSchema>>({
        resolver: zodResolver(templateFormSchema),
        defaultValues: {
            name: selectedTemplate?.title || '',
            description: selectedTemplate?.description || '',
            writing_style: selectedTemplate?.writing_style || 'analytical'
        },
    })

    const docGenStore = useSelector((state: RootState) => state.docGen)

    const [sections, setSections] = useState<TemplateSection[]>([])

    const scrollRef = useRef<HTMLDivElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const dispatch = useDispatch<AppDispatch>()

    const createTemplateStatus = docGenStore.createTemplateStatus
    const updateTemplateStatus = docGenStore.updateTemplateStatus[selectedTemplate?.id || '']

    const onSubmit = async (values: z.infer<typeof templateFormSchema>) => {
        if (selectedTemplate?.id) {
            const updatedTemplate: DocgenTemplate = {
                ...selectedTemplate,
                name: convertTemplateTitleToName(values.name),
                title: values.name,
                description: values.description,
                writing_style: values.writing_style,
                sections: mapTemplateSectionsToSections(sections)
            }

            await dispatch(updateTemplate(updatedTemplate))

            if (selectedTemplate) {
                dispatch(replaceTemplate(updatedTemplate))
            } else {
                dispatch(addTemplate(updatedTemplate))
            }
            onSave(updatedTemplate)
            setOpen(false)
        } else {
            const newTemplate = {
                name: convertTemplateTitleToName(values.name),
                title: values.name,
                description: values.description,
                writing_style: values.writing_style,
                sections: mapTemplateSectionsToSections(sections)
            }

            const result = await dispatch(createTemplate(newTemplate))

            if (isFulfilled(createTemplate)(result)) {
                const payload = result.payload as DocgenTemplate
                dispatch(addTemplate(payload))
                onSave(payload)
            }

            setOpen(false)
        }
    }

    const mapSectionsToTemplateSections = (sections: Section[]) => {
        const mappedSections: TemplateSection[] = sections.map((section, index) => {
            return {
                id: index,
                title: section.title,
                description: section.description,
                expanded: false,
                subsections: (section.subsections || []).map((subsection, index) => {
                    return {
                        id: index,
                        title: subsection.title,
                        subsections: (subsection.subsections || []).map((subsection, index) => {
                            return {
                                id: index,
                                title: subsection.title,
                                subsections: [],
                            }
                        }),
                    }
                }),
            } as TemplateSection
        })

        return mappedSections
    }

    const mapTemplateSectionsToSections = (sections: TemplateSection[]) => {
        const mappedSections: Section[] = sections.map((section) => {
            return {
                name: convertTemplateTitleToName(section.title),
                title: section.title,
                description: section.description || '',
                subsections: (section.subsections || []).map((subsection) => {
                    return {
                        name: convertTemplateTitleToName(subsection.title),
                        title: subsection.title,
                        subsections: (subsection.subsections || []).map((subsection) => {
                            return {
                                name: convertTemplateTitleToName(subsection.title),
                                title: subsection.title
                            }
                        })
                    }
                })
            } as Section
        })

        return mappedSections
    }

    const fetchSelectedTemplate = async () => {
        if (selectedTemplate) {
            if (selectedTemplate.id) {
                const result = await dispatch(fetchTemplate(selectedTemplate.id))
                if (isFulfilled(fetchTemplate)(result)) {
                    const payload = result.payload as DocgenTemplate

                    const mappedSections: TemplateSection[] = mapSectionsToTemplateSections(payload.sections || [])

                    setSections(mappedSections)
                }
            } else {
                const mappedSections: TemplateSection[] = mapSectionsToTemplateSections(selectedTemplate.sections || [])

                setSections(mappedSections)
            }
        }
    }

    useEffect(() => {
        if (open) {
            if (selectedTemplate) {
                form.setValue('name', selectedTemplate.title || '')
                form.setValue('description', selectedTemplate.description || '')
                form.setValue('writing_style', selectedTemplate.writing_style || '')
                fetchSelectedTemplate()
            }
        } else {
            setSections([])
            form.reset()
        }
    }, [open])

    return (
        <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogContent className="min-w-[1008px] max-w-[1008px] bg-system-surface" style={{ backgroundImage: `url(${texture})` }}>
                <DialogHeader className="!text-center">
                    <DialogTitle asChild>
                        <span className="absolute left-[50%] -translate-x-[50%] line-clamp-1 break-all">
                            {selectedTemplate?.id ? `Edit ${selectedTemplate.title}` : 'Create new template'}
                        </span>
                    </DialogTitle>
                    <DialogDescription className="text-center">
                        Define the section and subsections in your new template.
                    </DialogDescription>
                </DialogHeader>

                <DialogBody className="relative">
                    <div ref={scrollRef} className="px-20 my-10 flex flex-col gap-10 h-[calc(100vh-262px-64px)] overflow-y-auto overflow-x-visible">
                        <Form {...form}>
                            <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
                                <div className="mx-auto space-y-8 overflow-x-visible ">
                                    <FormField
                                        control={form.control}
                                        name="name"
                                        render={({ field }) => (
                                            <FormItem className="space-y-2">
                                                <div className="flex flex-col gap-0">
                                                    <TypographyBody isStrong>
                                                        Template name
                                                    </TypographyBody>
                                                    <TypographyLabel className="text-system-body">
                                                        The name will not affect generated content
                                                    </TypographyLabel>
                                                </div>
                                                <FormMessage className="w-fit min-w-[360px]" />
                                                <FormControl >
                                                    <Input {...field} className="w-[360px]" placeholder="e.g. TechCorp" />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="description"
                                        render={({ field }) => (
                                            <FormItem className="space-y-2">
                                                <div className="flex flex-col gap-0">
                                                    <TypographyBody isStrong>
                                                        Template description
                                                    </TypographyBody>
                                                    <TypographyLabel className="text-system-body">
                                                        Briefly describe the type of document you want or any specific requirements (max 100 words)
                                                    </TypographyLabel>
                                                </div>
                                                <FormMessage />
                                                <FormControl>
                                                    <Textarea {...field} placeholder="e.g. A proposal outline with sections for client needs, solution, and pricing, aim at an investment committee." />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="writing_style"
                                        render={({ field }) => (
                                            <FormItem className="w-[360px] space-y-2">
                                                <div className="flex flex-col gap-0">
                                                    <TypographyBody isStrong>
                                                        Tone of voice
                                                    </TypographyBody>
                                                    <TypographyLabel className="text-system-body">
                                                        Desired writing style and vocabulary
                                                    </TypographyLabel>
                                                </div>
                                                <FormMessage />
                                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                    <FormControl>
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select a tone of voice" />
                                                        </SelectTrigger>
                                                    </FormControl>
                                                    <SelectContent>
                                                        <SelectItem value="analytical">Analytical</SelectItem>
                                                        <SelectItem value="decisive">Decisive</SelectItem>
                                                        <SelectItem value="formal">Formal</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </form>
                        </Form>

                        <Divider />

                        <div className="flex flex-col gap-6">
                            <div className="flex flex-col gap-0">
                                <TypographyBody isStrong>
                                    Template sections
                                </TypographyBody>
                                <TypographyLabel className="text-system-body">
                                    Generated content will be based on the section guidelines you set below.
                                </TypographyLabel>
                            </div>

                            <Button variant='secondary' className="w-fit" onClick={() => {
                                const id = sections.length > 0 ? Math.max(...(sections || []).map((v) => v.id)) + 1 : 0

                                setSections([{
                                    id: id,
                                    title: '',
                                    description: '',
                                    expanded: false
                                }, ...sections])
                            }
                            }>
                                <div className="flex gap-2 items-center w-fit">
                                    <Plus className="size-6 shrink-0 stroke-[1.5px]" />

                                    Add section
                                </div>
                            </Button>

                            <TemplateSectionList sections={sections} setSections={(v) => setSections(v)} />
                        </div>
                    </div>
                </DialogBody>

                <DialogFooter>
                    <DialogClose asChild>
                        <Button variant='secondary'>
                            {selectedTemplate?.id ? 'Discard changes' : 'Discard'}
                        </Button>
                    </DialogClose>
                    <Button onClick={(e) => {
                        e.stopPropagation()
                        formRef.current?.requestSubmit()
                    }}>
                        <div className="flex gap-2 items-center">
                            {(createTemplateStatus === QueryStatus.FETCHING || updateTemplateStatus === QueryStatus.FETCHING) ?
                                <Loader2 className="size-6 animate-spin shrink-0 stroke-[1.5px]" />
                                :
                                <Checkmark className="size-6 shrink-0 stroke-[1.5px]" />
                            }
                            {selectedTemplate?.id ? 'Save changes' : 'Save and use template'}
                        </div>
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}