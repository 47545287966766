import { DotIcon } from "lucide-react"
import { TypographyLabel } from "../ui/Typography"
import { ResponseDocument } from "@/types/types"
import { CustomTooltip } from "../CustomTooltip"
import { Source } from "../Source"
import { createPortal } from "react-dom"

export const CitationFootnote = ({ id, document: doc, selected, onClick }: { id: number, document: ResponseDocument | null, selected: boolean, onClick: () => void }) => {
    const uuid = crypto.randomUUID()

    return (
        <>
            <span className="relative mr-1.5 cursor-pointer" data-tooltip-id={`citation-footnote-tooltip-${uuid}-${id}`} onClick={onClick}>
                <DotIcon className="size-6 text-system-body inline opacity-0" />
                <div className={`absolute -top-0.5 left-0 size-6 flex items-center justify-center ${selected ? 'bg-system-body' : 'bg-system-border-light'} rounded-full`}>
                    <TypographyLabel className={selected ? 'text-system-secondary' : "text-system-body"}>
                        {id}
                    </TypographyLabel>
                </div>
            </span>

            {createPortal(<CustomTooltip
                id={`citation-footnote-tooltip-${uuid}-${id}`}
                largeArrow={false}
            >
                <Source
                    id={doc?.document_id || ''}
                    url={doc?.document_secure_shared_link || ''}
                    title={doc?.document_name}
                    text={doc?.document_name}
                    showBorder={true}
                    metadata={{ ...doc }}
                />
            </CustomTooltip>, document.body)}
        </>
    )
}